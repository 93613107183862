/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.calculator {
  width: 310px !important;
  margin-left: 20px !important;
  border: 1px solid !important;
  height: 220px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  background: #fff !important; }
  @media (max-width: 900px) {
    .calculator {
      margin-left: 0 !important;
      width: 300px !important; } }
  .calculator .logo {
    background: url("../../assets/img/stock-co-logo.svg") no-repeat;
    width: 180px;
    height: 38px;
    margin-top: 10px; }
  .calculator .text {
    width: 180px;
    text-align: justify;
    font: 400 14px Lato; }
  .calculator .btn {
    font: 400 14px Lato;
    height: 30px !important;
    width: 160px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 !important;
    background: #fff !important;
    border: 1px solid !important;
    text-decoration: none !important;
    color: black !important; }
    .calculator .btn:hover {
      cursor: pointer; }
    .calculator .btn .button-txt {
      width: 100% !important;
      text-align: center !important; }
    .calculator .btn .icon {
      background-color: #fac917;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      border-top: 1px solid;
      border-bottom: 1px solid;
      font-size: 19px; }
  .calculator .btn:hover {
    cursor: pointer; }
