@import '../general/variables.scss';

.auctions-wrapper {
  position: relative;
  background-color: #ededed;

  .red {
    color: red;
  }

  .auctions-list {
    margin-top: 20px;
    .auctions-group {
      .group-title {
        @include black-title;
        font-weight: normal;
        font-size: 25px;
        text-align: center;
        margin-bottom: 25px;
        color: #5d5d5d;
      }
    }
    .auctions-empty {
      @include gray-title;
      text-align: center;
      margin-top: 60px;
      color: #ADB5BD;
      svg {
        font-size: 60px;
      }
      p {
        font-size: 30px;
        margin-top: 10px;
        font-weight: 400;
      }
    }
    .locked-item {
      position: relative;
      overflow: hidden;
      pointer-events: none;
      cursor: none;
      margin-bottom: 25px;
      .result {
        position: absolute;
        width: 117%;
        left: -8%;
        height: 125px;
        font-size: 40px;
        font-weight: 300;
        z-index: 10;
        top: calc(50% - 65px);
        transform: rotate(-16deg);
        background: rgba(22, 22, 22, 0.4);
        color: #ffffff;
        text-transform: uppercase;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato;
      }
      .auction-item {
        margin: 0;
        filter: brightness(80%);
      }
    }
    .auction-item {
      display: flex;
      position: relative;
      border: 1px solid $gray-border;
      margin-bottom: 25px;
      border-radius: 5px;
      background-color: #ffffff;
      overflow: hidden;

      .auction-img-container {
        min-width: 320px;
        max-width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-right: 1px solid $gray-border;

        .enter-auction-wrapper {
          height: 100%;
          width: 100%;
          display: flex;
          padding: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #fac917;

          & > img {
            filter: brightness(0.01) invert(100%);;
          }

          .enter-auction-btn {
            color: #fff;
            font-family: Lato;
            font-size: 24px;
            font-weight: 400;
            border-color: #fff;
            margin-bottom: 0;
            background-color: transparent;
            padding: 0;
            span:first-child {
              margin: 0 auto;
            }
            .icon-wrapper {
              border-color: #fff;
            }
      
            &:hover {
              box-shadow: 0 0 5px rgba(255,255,255,0.16), 0 0 5px rgba(255,255,255,0.3);
            }
          }

          &-open {
            background-color: #535353;
            .enter-auction-btn {
              font-size: 20px;
            }
          }
        }

        .auction-img {
          max-width:100%;
          max-height:100%;
          user-select: none;
        }

        .no-image {
          min-height: 300px;
        }
      }

      .auction-live-info {
        text-transform: uppercase;
        color: #1f9512;
        font-family: Lato;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  .scroll-loader-wrap {
    visibility: hidden;
    text-align: center;
    &.active {
      visibility: visible;
    }
    .scroll-loader {
      display: inline-block;
    }
  }
}

.auctions-wrapper, .watchlist-wrapper, .submitted-wrapper {
  .btn {
    @include basic-button;

    &:not(:first-of-type) {
      margin-top: 10px;
    }

    &.filter-btn {
      @include filter-button;
      width: 231px;
      @media (max-width: $mobile-width) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
.submitted-wrapper {
  .watch-info {
    .watch-auction {
      .watch-lots {
        .lot-item {
          .lot-info-bottom {
            .info-btns {
              @media (max-width: $mobile-width) {
                justify-content: start;
                align-items: start;
              }
            }
          }
        }
      }
    }
  }
}
.watchlist-wrapper {
  .watch-lots {
    .lot-item {
      .lot-info {
        .lot-info-bottom {
          @media (max-width: $mobile-width) {
            justify-content: start;
            align-items: start;
          }
          .info-wrap {
            @media (max-width: $mobile-width) {
              justify-content: start;
              align-items: start;
            }
          }
        }
      }
    }
  }
}
.auctions-controls, .watchlist-controls {
  padding: 10px 0px 10px 0px;
  border-top: 1px solid $gray-border;
  border-bottom: 1px solid $gray-border;

  .filters-heading {
    margin: 0;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }

  .auctions-filters-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: $mobile-width) {
      flex-direction: row;
    }
  }
  .btn {
    margin-top: 10px !important;
  }
}

.auction-info, .watch-auction {
  display: flex;
  flex: 2;
  flex-direction: column;
  .auction-info-top, .auction-info-middle {
    border-bottom: 1px solid $gray-border;
  }
  .auction-info-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 12px 12px 25px;

    @media (min-width: $mobile-width) {
      flex-direction: row;
    }
    @media (max-width: $mobile-width) {
      padding: 12px 12px 12px 19px;
    }

    .auction-title, .auction-live-date  {
      margin: 0;
    }
    .auction-title {
      @include black-title;
      margin-bottom: 10px;
    }
    .auction-live-date, .auction-closed-date {
      @include gray-title;
      margin-bottom: 15px;

      @media (min-width: $mobile-width) {
        line-height: 36px;
        margin-bottom: 0;
      }
    }

    .info-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;

      & > *:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  .auction-info-middle {
    display: flex;
    flex-direction: column;
    min-height: 90px;

    @media (min-width: $mobile-width) {
      flex-direction: row;
    }

    .auction-type {
      width: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #ededed;

      @media (min-width: $mobile-width) {
        max-width: 126px;
        border-bottom: none;
        border-right: 1px solid #ededed;
      }

      img {
        max-width: 100%;
        max-height: 50px;
      }
    }
    .auction-desc {
      flex: 1;
      font-size: 12px;
      color: #5d5d5d;
      line-height: 24px;
      font-family: 'Lato';
      padding: 15px 30px 15px 8px;
      white-space: break-spaces;
      @media (max-width: $mobile-width) {
        padding-left: 19px;
      }
      .auction-readMore {
        color: #e6bd2f;
        font-size: 12px;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .auction-logo {
      width: 100%;
      min-height: 75px;
      padding: 7px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #ededed;

      @media (min-width: $mobile-width) {
        max-width: 250px;
        border-top: none;
        border-left: 1px solid #ededed;
      }

      img {
        max-width: 100%;
        max-height: 75px;
      }
    }
  }
  .auction-info-bottom {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;

    & > span {
      color: #5d5d5d;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 35px;
      padding: 0 10px;
      margin-bottom: 5px;
      border-radius: 5px;
      border: 1px solid #5d5d5d;

      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }
  }
}

.watch-auction .auction-info-top {
  .line1, .line2 {
    margin-left: 0 !important;
  }
}

@media (max-width: $medium-width) {
  .auctions-wrapper {
    .auctions-list {
      .auction-item {
        display: block;

        .auction-img-container {
          min-width: 250px;
          max-width: 100%;
          border-right: none;
          border-bottom: 1px solid $gray-border;
        }
      }
    }
  }
}

@media (max-width: 633px) {
}

.icon-blackened {
  filter: brightness(0)
}
