@import '../general/variables.scss';
.lot-wrapper {
  position: relative;
  .hidden {
    display: none;
  }
  .lot-info {
    .title-block {
      @media (max-width: $medium-width) {
        justify-content: center;
        align-items: center;
      }
    }
  }
  .top-btns {
    display: flex;
    &.results {
      flex-wrap: wrap;
      .back-btn {
        width: 228px;
        @media (max-width: $mobile-width) {
          width: 100%;
        }
      }
      .watching-btn {
        width: 228px;
        height: 44px;
        margin-bottom: 10px;
        @media (max-width: $mobile-width) {
          width: 100%;
          margin-right: 0;
        }
      }
      &.results-flex {
        flex-wrap: nowrap;
        .back-btn {
          min-width: 180px;
          width: auto;
          flex-grow: 1;
          @media (max-width: $mobile-width) {
            width: 100%;
          }
        }
        .watching-btn {
          min-width: 175px;
          max-width: 180px;
          width: auto;
          flex-grow: 1;
          @media (max-width: $medium-width) {
            min-width: 220px;
            max-width: 800px;
          }
          @media (max-width: $mobile-width) {
            width: 100%;
            max-width: 1000px;
          }
        }
        @media (max-width: $medium-width) {
          flex-wrap: wrap;
        }
      }
    }
  }
  .btn {
    @include basic-button;
    width: 228px;
    margin: 0 10px 10px 0;
    &.btn-download-file {
      width: 271px;
      @media (max-width: 1280px) {
        width: 228px;
        margin: 0 10px 10px 0;
      }
      @media (max-width: $medium-width) {
        width: 100%;
        margin: 0 10px 10px 0;
      }
      @media (max-width: $mobile-width) {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }
    @media (max-width: $mobile-width) {
      width: 100%;
      margin: 0 0 10px 0;
    }
    &.btn-flex {
      width: auto;
      flex-grow: 1;
      @media (max-width: $medium-width) {
        min-width: 220px;
        width: 228px;
      }
      @media (max-width: $mobile-width) {
        width: 100%;
      }
      .btn-title {
        display: inline-block;
      }
    }
  }
  .lot-number {
    @include gray-title;
  }
  .lot-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .auction-title, .auction-live-date, .auction-closed-date {
      margin: 0;
    }
    .auction-title {
      @include black-title;
      margin-bottom: 15px;
    }
    .auction-live-date, .auction-closed-date {
      @include date-header;
    }
    .right {
      display: flex;
      order: 1;
      border-left: 0;
    }
    .left {
      .left {
        order: 2;
      }
      .back-img {
        &:before {
          transform: rotate(360deg) !important;
        }
      }
    }
  }
  .lot-number {
    margin: 0 0 20px 0;
  }
  .pdf-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .btn {
      @include basic-button-old;
      margin-bottom: 20px;
      margin-right: 15px;
      color: #333333;
      background-color: #cccccc;
      &:hover {
        background-color: #aeaeae;
      }
      &.hidden {
        display: none;
      }
    }
  }
  .lot-info {
    position: relative;
    border: 1px solid $gray-border;
    border-radius: 10px;
    padding: 0 50px;
    margin-top: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background: white;
    font-family: $default-font;
    .lot-info-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 0 -50px;
      height: 59px;
      border-radius: 5px 5px 0 0;
      background-color: #2e2e2e;
      color: white;
      font-size: 32px;
      padding: 0 17px;
      @media (max-width: $medium-width) {
        font-size: 24px;
        margin: 0 -10px;
      }
      @media (max-width: $mobile-width) {
        font-size: 17px;
      }
      p {
        margin: 0;
      }
    }
    .lot-title-wrapper {
      display: flex;
      justify-content: space-between;
      .company-logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: $mobile-width) {
          width: 150px;
          height: 100px;
          margin-left: 5px;
        }
        img {
          max-height: 50px;
          object-fit: contain;
        }
      }
    }
    .lot-title-weights {
      display: flex;
      width: 100%;
      font-size: 20px;
      margin-bottom: 10px;
      .title-weight {
        font-weight: 600;
        color: #5d5d5d;
        font-family: 'Lato';
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        flex: 1;
        border: 1px solid black;
        border-radius: 5px 0 0 0;
        margin: 0;
        &.curfew {
          color: #DBB52C;
          background-color: #5d5d5d;
          border-radius: 0 5px 0 0;
        }
      }
    }
    .details {
      margin-top: 20px;
    }
    .lot-title {
      display: block;
      color: #2e2e2e;
      font-size: 36px;
      margin: 36px 0 0 0;
      @media (max-width: $medium-width) {
        font-size: 20px;
      }
      @media (max-width: 600px) {
        font-size: 18px;
      }
      @media (max-width: 360px) {
        max-width: 135px;
        margin-left: 25px;
        margin-right: 5px;
      }
    }
  }
  .lot-closed-state {
    display: block;
    font-size: 36px;
    @media (max-width: $medium-width) {
      font-size: 26px;
    }
    margin-top: 8px;
    min-width: 280px;
    font-weight: 100;
    color: #dd2d2e;
    word-break: break-word;
    @media (max-width: 600px) {
      min-width: 180px;
    }
    @media (max-width: 600px) {
      font-size: 22px;
    }
    @media (max-width: 420px) {
      min-width: 160px;
      font-size: 20px;
    }
    @media (max-width: 350px) {
      margin-left: 25px;
    }
    &.sold, &.soldAfterAuction {
      color: $dark-green;
    }
    &.passed {
      color: #f9891a;
    }
  }
}
.lot-purchaser-location {
  display: block;
  font-size: 20px;
  color: $dark-green;
  @media (max-width: 600px) {
    min-width: 180px;
  }
  @media (max-width: 420px) {
    min-width: 160px;
    font-size: 12px;
  }
  @media (max-width: 350px) {
    margin-left: 25px;
  }
}
@media (max-width: $medium-width) {
  .lot-wrapper {
    .lot-menu {
    }
    .lot-info {
      border-left: 0;
      border-right: 0;
      padding: 0 10px;
    }
  }
  .media-carousel {
    .media-wrap {
      .media-area {
      }
    }
  }
}
@media (max-width: 633px) {
  .lot-wrapper {
    .lot-menu {
      >.left {
        margin-bottom: 20px;
      }
    }
  }
}

#useful-links-details {
  padding: 15px 0;
  margin: 20px 0 50px;
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6;

  .lots-links-wrapper {
    padding-top: 15px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .link {
    margin: 0 10px;
    padding-bottom: 3px;
    color: rgb(71, 137, 196);
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid rgb(71, 137, 196);
    text-decoration: none;
  }
}