@import "../../themes/general/variables.scss";
.app-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0,0,0,0.8);
  user-select: none;
  max-height: 100%;
  .app-modal {
    min-width: 300px;
    max-width: 50%;
    min-height: 190px;
    max-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-height: 700px) {
      max-height: unset;
    }
    padding: 15px 25px;
    position: absolute;
    top: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 10px;
    background-color: white;
    .title, .message {
      margin: 0;
      padding: 10px 0;
      // border-bottom: 2px solid $gray-border;
    }
    .title {
      text-align: center;
      font: 700 25px 'Lato';
    }
    .message {
      text-align: center;
      font: 400 18px Lato;
    }
    .buttons {
      display: flex;
      text-align: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px 0;
      .modal-button {
        min-height: 2em;
        min-width: 10em;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: $nav-active-bg;
        color: white;
        font: 400 20px 'Myriad Pro';
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          box-shadow: 2px 2px 2px #888888;
        }
      }
    }
    @media (max-height: 460px) {
      padding-bottom: 0;
    }
  }
  &.upload-csv {
    .app-modal {
      max-width: 650px;
      min-height: 600px;
      padding: 0;
      display: block;
      .title {
        width: 100%;
        padding-left: 20px;
        background: $nav-active-bg;
        text-align: left;
        color: white;
        font: 400 20px "Lato";
      }
      .buttons {
        width: 100%;
        padding: 20px;
        display: inline-block;
        .modal-button.custom {
          @include basic-button;
          min-width: 9em;
          padding-left: 10px;
          justify-content: left;
          border: 1px solid #c6c6c6;
          background-color: #ededed;
          color: #666666;
          &:first-of-type {
            float: left;
          }
          &:last-of-type {
            float: right;
          }
          .icon-wrapper {
            width: 44px;
            position: absolute;
            top: 0;
            right: 0;
            border-color: #c6c6c6;
          }
          @media (max-width: $mobile-width) {
            float: none !important;
          }
        }
      }
      p.message {
        padding: 0 25px;
        text-align: left;
      }
      .custom-message {
        padding: 15px 25px;
        .message {
          padding: 0;
          margin: 0 0 20px;
        }
        .form-row {
          .row-title {
            width: 160px;
          }
        }
      }
      .fg-dropdown__menu {
        height: 103px;
        min-height: 103px;
        .fg-dropdown__menu-list {
          height: 100px;
          min-height: 100px;
        }
      }
    }
  }
  .registerInterest-success-modal {
    margin-bottom: 815px;
  }
}

.ReactModal__Overlay {
  z-index: 50;
}