@import './general/variables.scss';

.watchlist-filters-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: $mobile-width) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @media (min-width: $mobile-width-min) {
    .filter-range-wrapper-md {
      max-width: 166px;
      .filter-btn-md {
        max-width: 166px;
      }
    }
    .filter-multi-wrapper-md {
      max-width: 166px;
      .filter-btn-md {
        max-width: 166px;
      }
    }
    .filter-btn:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.watch-auction-info-top {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  padding: 10px;
  border-radius: 5px;
  background-color: #007fc1;
  margin: 25px 0 13px;

  @media (min-width: $mobile-width) {
    flex-direction: row;
    align-items: center;
    padding: 3px 0;
  }

  & > div:not(.info-btns) {
    display: flex;
    flex: 1;
    align-self: stretch;
    margin-bottom: 15px;

    @media (min-width: $mobile-width) {
      margin-bottom: 0;
    }
  }

  .watch-auction-kind {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    padding-left: 12px;

    @media (min-width: $mobile-width) {
      min-width: 115px;
      padding-left: 0;
      border-right: 1px solid #fff;
    }

    .cattle-icon {
      filter: invert(100%) brightness(200%);
    }
  }

  .watch-auction-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;

    h3 {
      color: #ffffff;
      font-family: Lato;
      font-size: 30px;
      font-weight: 400;
      margin: 0 0 12px;
    }

    p {
      color: #efefef;
      font-family: Lato;
      font-size: 20px;
      font-weight: 400;
      margin: 0;
    }
  }

  .info-btns {
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 0;

    @media (min-width: $large-width) {
      flex-direction: row;
    }

    a {
      text-decoration: none;
    }

    .btn {
      @include basic-button;
      border-color: #000;

      &.filter-btn-md {
        max-width: 166px;
      }

      .icon-wrapper {
        border-color: #000;
      }

      &.watch-enter-btn {
        margin-bottom: 10px;
        background-color: #fac917;
        color: #fff;

        @media (min-width: $large-width) {
          margin-bottom: 0;
          margin-right: 7px;
        }
      }
    }
  }
}
