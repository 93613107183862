@import '../../themes/general/variables.scss';

.watching-btn {
  @include basic-button;
  background-color: #fff;
  color: #030404;
  &.list {
    width: 184px;
    height: 35px;
  }
  .icon-wrapper {
    background-color: #F9891A;
    transition: 400ms;
    color: #fff;
  }

  &.active {
    background-color: #F9891A;
    color: #fff;
    .icon-wrapper {
      background-color: #fff;
      transition: 400ms;
      color: #F9891A;
    }
  }
}
.watching-small-btn{
  .watch-flag {
    width: 33px;
    height: 38px;
    &.active {
      color: #007fc1;
    }
  }
}
