@import "../../themes/general/variables.scss";
.media-carousel {
  position: relative;
  width: 100%;
  white-space: nowrap;
  margin: 5px 0;

  .nav-prev, .nav-next {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    position: absolute;
    top: 25% !important;
    height: 50%;
    display: flex;
    align-items: center;
    user-select: none;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    cursor: pointer;
    &.active {
      opacity: 1;
      pointer-events: all;
      z-index: 50;
    }
    &.active:hover {
      opacity: 1 !important;
    }
    .icon-wrapper {
      font-size: 25px;
      color: #fff;
    }
  }
  .nav-prev {
    left: 10px;
    z-index: 9;
  }
  .nav-next {
    top: 0;
    right: 10px;
    justify-content: flex-end;
  }
  .media-wrap {
    margin: 0;
    overflow: hidden;
    user-select: none;
    .media-area {
      position: relative;
      transition: all 0.5s;
      label {
        cursor: pointer;
      }
      .no-image {
        .file-input {
          display: none;
        }
      }
      .carousel-item {
        cursor: pointer;
        width: 320px;
        height: 213px;
        max-width: 100%;
        margin-right: 3px;
        display: inline-block;
        position: relative;
        overflow: hidden;
        @media (max-width: $medium-width) {
          width: 240px;
          height: 180px;
        }
        &.with-title {
          height: 235px;
        }
        .video-image-wrapper {
          height: 100%;
          position: relative;
          .carousel-img {
            width: unset !important;
            height: unset !important;
            object-fit: contain !important;
            max-width: 100%;
            max-height: 100%;
          }
          svg {
            position: absolute;
            top: calc(50% - 75px / 2);
            left: calc(50% - 75px / 2);
            height: 75px;
            width: 75px;
            color: #2e2e2e;
            z-index: 2;
          }
        }
        .remove {
          position: absolute;
          top: 15px;
          left: 15px;
          z-index: 2;
        }
        .set-main {
          padding: 3px 5px;
          background: #808080;
          border-radius: 5px;
          font: $regular-text;
          color: #fff;
          position: absolute;
          bottom: 15px;
          right: 15px;
          z-index: 2;
          width: 42% ;
          //transform: translate(0px, 110px) ;
          .inner-line {
            display: flex;
            align-items: center;
            .set-main-text {
              margin: 0 0 0 5px;
            }
          }
        }
        .carousel-img, .carousel-video {
          display: block;
          //object-fit: contain !important;
        }
        .date {
          padding: 3px 5px;
          background: #808080;
          border-radius: 5px;
          position: absolute;
          z-index: 2;
          //top: 24px;
          bottom: 15px;
          //right: 10px;
          left: 15px;
          display: flex;
          justify-content: center;
          font: $regular-text;
          color: #fff;
          
        }
        .carousel-img {
          position: absolute;
          width: 100%;
          height: 100%;
          //max-height: 100%;
          // height: 180px;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          z-index: 1;
          /* max-width: 100%;
          max-height: 100%; */
        }
        .video-title {
          display: flex;
          padding: 5px;
          justify-content: center;
          text-align: center;
          white-space: normal;
        }
        .carousel-video {
          width: 100%;
          height: 100%;
          margin: auto;
          object-fit: initial;
          &.with-title {
            height: 80%;
          }
        }
        .video-prewiev {
          object-fit: contain;
        }
        .spinner {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 5;
          &.hidden {
            display: none;
          }
        }
      }
    }
  }
  &.preview {
    height: 100%;
    margin: 0;
    .media-wrap {
      height: 100%;
      margin: 0;
      .media-area {
        height: 100%;
        .carousel-item {
          width: 100%;
          height: 100%;
          margin-right: 0px;
          @media (max-width: $medium-width) {
            width: 100% !important;
          }
          .carousel-img {
            width: 100%;
            height: 100%;
            object-fit: fill !important;
            /* max-width: 100%;
            max-height: 100%; */
          }
        }
      }
    }
    .nav-prev {
      background: transparent;
      left: 5px;
    }
    .nav-next {
      background: transparent;
      right: 5px;
    }
  }
  &:not(.preview) {
  }
}

.carousel-wrap {
  width: 100%;
  height: 100%;
}

.dots-wrap {
  padding-bottom: 12px;
  text-align: center;
  .dot {
    width: 10px;
    height: 10px;
    margin-right: 9px;
    background: #2e2e2e;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    &.active {
      background: #8e8e8e;
    }
  }
}

canvas {
  width: 320px;
  height: 190px;
  position: absolute;
  top: 20px;
}
