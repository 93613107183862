



.auctions-wrapper .icon .img {
    width: 190px;
    margin-right: 45px;
}
  .auctions-wrapper .logo-reg-link {
    display: flex;
    justify-content: right;
    padding-bottom: 10px;
  }
 
  .auctions-wrapper .top-line {
    display: flex;
    justify-content: right;
    font-size: 16px;
    font-family: lato;
  }
  .auctions-wrapper .top-line .span-text {
    padding-right: 4px;
  }
 
  .auctions-wrapper .top-line .sign-uplink {
    color: #1f9512;
    text-decoration: none;
  }



  


   /* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
 /* @import "../node_modules/react-dropdown/style.css";
 @import "../node_modules/react-toggle/style.css";
 @import "../node_modules/react-day-picker/lib/style.css";
 @import "../node_modules/rc-slider/assets/index.css";
 @import "../node_modules/react-table/react-table.css"; */

 @import '../node_modules/react-dropdown/style.css';
 /* @import '../node_modules/react-toggle/style.css'; */
 /* @import '../node_modules/react-day-picker/lib/style.css'; */
 @import '../node_modules/rc-slider/assets/index.css';
  /* @import '../node_modules/react-table/react-table.css';  */
  @font-face {
    font-family: "Myriad Pro";
    src: url("../src/assets/fonts/MyriadPro-Regular.otf");
    font-weight: 400;
  }
  @font-face {
    font-family: "Myriad Pro";
    src: url("../src/assets/fonts/MyriadPro-BoldSemiCn.woff");
    font-weight: 700;
  }
  @font-face {
    font-family: "Lato";
    src: url("../src/assets/fonts/Lato-Thin.woff2");
    font-weight: 100;
  }
  @font-face {
    font-family: "Lato";
    src: url("../src/assets/fonts/Lato-Regular.woff2");
    font-weight: 400;
  }
  @font-face {
    font-family: "Lato";
    src: url("../src/assets/fonts/Lato-Bold.woff2");
    font-weight: 700;
  }
  @font-face {
    font-family: "Lato";
    src: url("../src/assets/fonts/Lato-Black.woff2");
    font-weight: 900;
  }
  * {
    box-sizing: border-box;
    outline: none;
  }
  
  html, body {
    margin: 0;
    height: 100%;
  }
  html #root, body #root {
    height: 100%;
  }
  html #root > div, body #root > div {
    position: relative;
    height: 100%;
  }
  
  body {
    min-width: 320px;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  html {
    height: 100%;
  }
  
  body {
    min-height: 100%;
  }
  
  .body-container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 40px 5px;
    background-color: #cccccc;
    clear: both;
  }
  .body-container .inner-container {
    position: relative;
    background-color: white;
    max-width: 1280px;
    width: 100%;
    min-height: 1020px;
    margin: auto;
    background-color: #ededed;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
    padding: 40px 50px;
  }
  @media (max-width: 900px) {
    .body-container .inner-container {
      padding: 25px 10px;
    }
  }
  @media (max-width: 900px) {
    .body-container {
      padding: 20px 0;
    }
  }
  
  .no-image {
    display: inline-flex;
    vertical-align: top;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: grey;
    width: 100%;
    height: 100%;
    font: normal 16px "Lato";
    color: #fff;
  }
  .no-image:before {
    content: "";
    background: url("../src/assets/img/icon_photo.png") no-repeat;
    background-size: cover;
    display: flex;
    width: 53px;
    height: 40px;
    margin-bottom: 10px;
  }
  .no-image.cattle:before {
    background: url("../src/assets/img/cow.png") no-repeat;
  }
  .no-image.sheep:before {
    background: url("../src/assets/img/sheep.png") no-repeat;
  }
  .no-image.goat:before {
    background: url("../src/assets/img/goat-image-middle.png") no-repeat;
  }
  
  .separator {
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #c6c6c6;
    margin: 0;
  }
  
  .app-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.7);
  }
  .app-spinner-overlay.area {
    position: absolute;
  }
  .app-spinner-overlay .app-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .app-spinner-overlay .app-spinner.area {
    position: absolute;
  }
  
  .remove {
    width: 20px;
    height: 20px;
    background: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
  }
  .remove:after {
    width: 20px;
    height: 20px;
    content: "";
    background: url("../src/assets/img/closebutton.png") no-repeat;
    background-size: cover;
    display: block;
  }
  
  button:focus, label:focus {
    box-shadow: 0 0 2px 2px #a3a3b1;
  }
  
  .disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  
  .fg-header {
    color: #030404;
    font-family: Lato;
    font-size: 40px;
    font-weight: 400;
    margin: 0 0 20px 0;
  }
  @media (max-width: 550px) {
    .fg-header {
      font-size: 28px;
    }
  }
  .fg-header.watch-header {
    margin-bottom: 10px;
  }
  
  .fg-secondary-header {
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 300;
    color: #545757;
    font-family: "Lato";
  }
  
  .fg-sub-header {
    color: #030404;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    margin: 0 0 20px 0;
    line-height: 26px;
  }
  
  .fg-paragraph {
    color: #030404;
    font: 400 16px Lato;
  }
  
  .network-share-button {
    margin-left: 10px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .app-route {
    position: relative;
    z-index: 3;
  }
  
  .no-data-placeholder {
    margin-top: 20px;
    text-align: center;
  }
  .no-data-placeholder .message {
    display: flex;
    justify-content: center;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
  }
  
  .ReactModalPortal-Email .overlay {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 800;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-height: 500px) {
    .ReactModalPortal-Email .overlay {
      overflow-y: scroll;
    }
  }
  .ReactModalPortal-Email .overlay .content {
    display: flex;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    width: 60%;
    flex-direction: column;
    align-items: center;
    border: 1px solid #cccccc;
    background: white;
    overflow: hidden;
    outline: none;
    font-family: "Lato";
  }
  @media (max-width: 500px) {
    .ReactModalPortal-Email .overlay .content {
      width: 90%;
    }
  }
  @media (min-width: 1200px) {
    .ReactModalPortal-Email .overlay .content {
      max-width: 700px;
    }
  }
  @media (max-height: 500px) {
    .ReactModalPortal-Email .overlay .content {
      margin-top: 90px;
      margin-bottom: 30px;
    }
  }
  @media (max-height: 350px) {
    .ReactModalPortal-Email .overlay .content {
      margin-top: 150px;
      margin-bottom: 30px;
    }
  }
  @media (max-height: 390px) {
    .ReactModalPortal-Email .overlay .content {
      max-height: 420px;
    }
  }
  .ReactModalPortal-Email .overlay .content .icon-danger {
    color: #dd2d2e;
  }
  .ReactModalPortal-Email .overlay .content .icon-close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    right: -1px;
    top: -1px;
    font-size: 22px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    cursor: pointer;
  }
  @media (max-width: 800px) {
    .ReactModalPortal-Email .overlay .content .icon-close {
      height: 30px;
      width: 30px;
    }
  }
  .ReactModalPortal-Email .overlay .content .modal-header {
    text-align: center;
  }
  @media (max-width: 800px) {
    .ReactModalPortal-Email .overlay .content .modal-header {
      font-size: 18px;
    }
  }
  .ReactModalPortal-Email .overlay .content .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    width: 60%;
  }
  @media (max-width: 450px) {
    .ReactModalPortal-Email .overlay .content .modal-body {
      margin-top: 0;
    }
  }
  @media (max-height: 390px) {
    .ReactModalPortal-Email .overlay .content .modal-body {
      margin-top: 0;
    }
  }
  @media (max-width: 800px) {
    .ReactModalPortal-Email .overlay .content .modal-body {
      width: 100%;
    }
  }
  .ReactModalPortal-Email .overlay .content .modal-body.download {
    margin-top: 0;
  }
  @media (max-width: 800px) {
    .ReactModalPortal-Email .overlay .content .modal-body.download {
      width: 100%;
    }
  }
  .ReactModalPortal-Email .overlay .content .modal-body form {
    width: 100%;
  }
  .ReactModalPortal-Email .overlay .content .modal-body .modal-catalogue-title {
    font-size: 20px;
    text-align: center;
  }
  .ReactModalPortal-Email .overlay .content .modal-input ~ .modal-input {
    margin-top: 30px;
  }
  .ReactModalPortal-Email .overlay .content .modal-input {
    display: block;
    width: 100%;
    min-height: 45px;
    border-radius: 5px;
    background-color: #666666;
    color: #ffffff;
    font: 100 18px Lato;
    line-height: 30px;
    transition: background-color 0.5s ease;
    padding-left: 15px;
    border: none;
  }
  .ReactModalPortal-Email .overlay .content .modal-input:focus {
    background-color: rgba(102, 102, 102, 0.8);
  }
  .ReactModalPortal-Email .overlay .content .modal-input::placeholder {
    color: #ffffff;
  }
  .ReactModalPortal-Email .overlay .content .choice-desc {
    text-align: left;
  }
  @media (max-width: 800px) {
    .ReactModalPortal-Email .overlay .content .choice-desc {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
  @media (max-width: 400px) {
    .ReactModalPortal-Email .overlay .content .choice-desc {
      max-width: 250px;
      margin-top: 20px;
    }
  }
  .ReactModalPortal-Email .overlay .content .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 50px;
  }
  @media (max-width: 450px) {
    .ReactModalPortal-Email .overlay .content .buttons {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1348px) {
    .ReactModalPortal-Email .overlay .content .buttons .button {
      flex: 1 1;
      min-width: 213px !important;
    }
  }
  .ReactModalPortal-Email .overlay .content .buttons .button {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin-top: 30px;
    width: 100%;
    border-radius: 3px;
    color: #ffffff;
    background-color: #1f9512;
  }
  .ReactModalPortal-Email .overlay .content .buttons .button:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .ReactModalPortal-Email .overlay .content .buttons .button.ok-button {
    display: flex;
    justify-content: center;
  }
  .ReactModalPortal-Email .overlay .content .buttons .button.red-button {
    background-color: #dd2d2e;
  }
  .ReactModalPortal-Email .overlay .content .buttons .button .button-text {
    color: #ffffff;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    padding-right: 20px;
  }
  @media (max-height: 390px) {
    .ReactModalPortal-Email .overlay .content .buttons .button {
      margin-top: 10px;
    }
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .auctions-wrapper {
    position: relative;
    background-color: #ededed;
  }
  .auctions-wrapper .red {
    color: red;
  }
  .auctions-wrapper .icon .img {
    width: 190px;
    margin-right: 45px;
  }
  .auctions-wrapper .logo-reg-link {
    display: flex;
    justify-content: right;
    padding-bottom: 10px;
  }
 
  .auctions-wrapper .top-line {
    display: flex;
    justify-content: right;
    font-size: 16px;
    font-family: lato;
  }
  .auctions-wrapper .top-line .span-text {
    padding-right: 4px;
  }
 
  .auctions-wrapper .top-line .sign-uplink {
    color: #1f9512;
    text-decoration: none;
  }
  .auctions-wrapper .auctions-list {
    margin-top: 20px;
  }
  .auctions-wrapper .auctions-list .auctions-group .group-title {
    color: #000000;
    font-family: Lato;
    font-size: 30px;
    font-weight: 400;
    font-weight: normal;
    font-size: 25px;
    text-align: center;
    margin-bottom: 25px;
    color: #5d5d5d;
  }
  .auctions-wrapper .auctions-list .auctions-empty {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  .auctions-wrapper .auctions-list .locked-item {
    position: relative;
    overflow: hidden;
    pointer-events: none;
    cursor: none;
    margin-bottom: 25px;
  }
  .auctions-wrapper .auctions-list .locked-item .result {
    position: absolute;
    width: 117%;
    left: -8%;
    height: 125px;
    font-size: 40px;
    font-weight: 300;
    z-index: 10;
    top: calc(50% - 65px);
    transform: rotate(-16deg);
    background: rgba(22, 22, 22, 0.4);
    color: #ffffff;
    text-transform: uppercase;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
  }
  .auctions-wrapper .auctions-list .locked-item .auction-item {
    margin: 0;
    filter: brightness(80%);
  }
  .auctions-wrapper .auctions-list .auction-item {
    display: flex;
    position: relative;
    border: 1px solid #c6c6c6;
    margin-bottom: 25px;
    border-radius: 5px;
    background-color: #ffffff;
    overflow: hidden;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container {
    min-width: 320px;
    max-width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-right: 1px solid #c6c6c6;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fac917;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper > img {
    filter: brightness(0.01) invert(100%);
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn {
    color: #fff;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    border-color: #fff;
    margin-bottom: 0;
    background-color: transparent;
    padding: 0;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn span:first-child {
    margin: 0 auto;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn .icon-wrapper {
    border-color: #fff;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn:hover {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.16), 0 0 5px rgba(255, 255, 255, 0.3);
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper-open {
    background-color: #535353;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper-open .enter-auction-btn {
    font-size: 20px;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .auction-img {
    max-width: 100%;
    max-height: 100%;
    user-select: none;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-img-container .no-image {
    min-height: 300px;
  }
  .auctions-wrapper .auctions-list .auction-item .auction-live-info {
    text-transform: uppercase;
    color: #1f9512;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
  }
  .auctions-wrapper .scroll-loader-wrap {
    visibility: hidden;
    text-align: center;
  }
  .auctions-wrapper .scroll-loader-wrap.active {
    visibility: visible;
  }
  .auctions-wrapper .scroll-loader-wrap .scroll-loader {
    display: inline-block;
  }
  
  .auctions-wrapper .btn, .watchlist-wrapper .btn, .submitted-wrapper .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
  }
  .auctions-wrapper .btn:hover, .watchlist-wrapper .btn:hover, .submitted-wrapper .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .auctions-wrapper .btn .icon-wrapper, .watchlist-wrapper .btn .icon-wrapper, .submitted-wrapper .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .auctions-wrapper .btn .icon-wrapper.icon-green, .watchlist-wrapper .btn .icon-wrapper.icon-green, .submitted-wrapper .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .auctions-wrapper .btn .icon-wrapper.icon-success, .watchlist-wrapper .btn .icon-wrapper.icon-success, .submitted-wrapper .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .auctions-wrapper .btn .icon-wrapper.icon-warning, .watchlist-wrapper .btn .icon-wrapper.icon-warning, .submitted-wrapper .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .auctions-wrapper .btn .icon-wrapper.icon-danger, .watchlist-wrapper .btn .icon-wrapper.icon-danger, .submitted-wrapper .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .auctions-wrapper .btn .icon-wrapper.icon-red, .watchlist-wrapper .btn .icon-wrapper.icon-red, .submitted-wrapper .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .auctions-wrapper .btn:not(:first-of-type), .watchlist-wrapper .btn:not(:first-of-type), .submitted-wrapper .btn:not(:first-of-type) {
    margin-top: 10px;
  }
  .auctions-wrapper .btn.filter-btn, .watchlist-wrapper .btn.filter-btn, .submitted-wrapper .btn.filter-btn {
    margin: 0;
    padding-left: 10px;
    width: 231px;
  }
  .auctions-wrapper .btn.filter-btn:hover, .watchlist-wrapper .btn.filter-btn:hover, .submitted-wrapper .btn.filter-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 550px) {
    .auctions-wrapper .btn.filter-btn, .watchlist-wrapper .btn.filter-btn, .submitted-wrapper .btn.filter-btn {
      width: 100%;
    }
  }
  @media (min-width: 551px) {
    .auctions-wrapper .btn.filter-btn:not(:last-of-type), .watchlist-wrapper .btn.filter-btn:not(:last-of-type), .submitted-wrapper .btn.filter-btn:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  .auctions-wrapper .btn.filter-btn:hover:not(.btn-opened), .watchlist-wrapper .btn.filter-btn:hover:not(.btn-opened), .submitted-wrapper .btn.filter-btn:hover:not(.btn-opened) {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .auctions-wrapper .btn.filter-btn:hover:not(.btn-opened) .icon-wrapper, .watchlist-wrapper .btn.filter-btn:hover:not(.btn-opened) .icon-wrapper, .submitted-wrapper .btn.filter-btn:hover:not(.btn-opened) .icon-wrapper {
    background-color: #fac917;
  }
  .auctions-wrapper .btn.filter-btn .icon-wrapper, .watchlist-wrapper .btn.filter-btn .icon-wrapper, .submitted-wrapper .btn.filter-btn .icon-wrapper {
    transition: 200ms;
    color: #5d5d5d;
  }
  @media (min-width: 550px) and (max-width: 900px) {
    .auctions-wrapper .btn.filter-btn .icon-wrapper, .watchlist-wrapper .btn.filter-btn .icon-wrapper, .submitted-wrapper .btn.filter-btn .icon-wrapper {
      margin-left: 10px;
    }
  }
  @media (min-width: 550px) {
    .auctions-wrapper .btn.filter-btn-sm, .watchlist-wrapper .btn.filter-btn-sm, .submitted-wrapper .btn.filter-btn-sm {
      max-width: 120px;
    }
    .auctions-wrapper .btn.filter-btn-md, .watchlist-wrapper .btn.filter-btn-md, .submitted-wrapper .btn.filter-btn-md {
      max-width: 155px;
    }
    .auctions-wrapper .btn.filter-btn-lg, .watchlist-wrapper .btn.filter-btn-lg, .submitted-wrapper .btn.filter-btn-lg {
      max-width: 230px;
    }
  }
  .auctions-wrapper .btn.filter-btn-active:not(.btn-opened), .watchlist-wrapper .btn.filter-btn-active:not(.btn-opened), .submitted-wrapper .btn.filter-btn-active:not(.btn-opened) {
    border-color: #1f9512;
  }
  .auctions-wrapper .btn.filter-btn-active .icon-wrapper, .auctions-wrapper .btn.filter-btn-active:hover .icon-wrapper, .watchlist-wrapper .btn.filter-btn-active .icon-wrapper, .watchlist-wrapper .btn.filter-btn-active:hover .icon-wrapper, .submitted-wrapper .btn.filter-btn-active .icon-wrapper, .submitted-wrapper .btn.filter-btn-active:hover .icon-wrapper {
    background-color: #1f9512;
    border-color: #1f9512;
  }
  .auctions-wrapper .btn.filter-btn-active .icon-wrapper > svg, .auctions-wrapper .btn.filter-btn-active .icon-wrapper > img, .auctions-wrapper .btn.filter-btn-active:hover .icon-wrapper > svg, .auctions-wrapper .btn.filter-btn-active:hover .icon-wrapper > img, .watchlist-wrapper .btn.filter-btn-active .icon-wrapper > svg, .watchlist-wrapper .btn.filter-btn-active .icon-wrapper > img, .watchlist-wrapper .btn.filter-btn-active:hover .icon-wrapper > svg, .watchlist-wrapper .btn.filter-btn-active:hover .icon-wrapper > img, .submitted-wrapper .btn.filter-btn-active .icon-wrapper > svg, .submitted-wrapper .btn.filter-btn-active .icon-wrapper > img, .submitted-wrapper .btn.filter-btn-active:hover .icon-wrapper > svg, .submitted-wrapper .btn.filter-btn-active:hover .icon-wrapper > img {
    filter: invert(100) brightness(200);
  }
  @media (max-width: 550px) {
    .auctions-wrapper .btn.filter-btn, .watchlist-wrapper .btn.filter-btn, .submitted-wrapper .btn.filter-btn {
      width: 100%;
      max-width: 100%;
    }
  }
  
  @media (max-width: 550px) {
    .submitted-wrapper .watch-info .watch-auction .watch-lots .lot-item .lot-info-bottom .info-btns {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  @media (max-width: 550px) {
    .watchlist-wrapper .watch-lots .lot-item .lot-info .lot-info-bottom {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  @media (max-width: 550px) {
    .watchlist-wrapper .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  .auctions-controls, .watchlist-controls {
    padding: 10px 0px 10px 0px;
    border-top: 1px solid #c6c6c6;
    border-bottom: 1px solid #c6c6c6;
  }
  .auctions-controls .filters-heading, .watchlist-controls .filters-heading {
    margin: 0;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
  .auctions-controls .auctions-filters-wrapper, .watchlist-controls .auctions-filters-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  @media (min-width: 550px) {
    .auctions-controls .auctions-filters-wrapper, .watchlist-controls .auctions-filters-wrapper {
      flex-direction: row;
    }
  }
  .auctions-controls .btn, .watchlist-controls .btn {
    margin-top: 10px !important;
  }
  
  .auction-info, .watch-auction {
    display: flex;
    flex: 2;
    flex-direction: column;
  }
  .auction-info .auction-info-top, .auction-info .auction-info-middle, .watch-auction .auction-info-top, .watch-auction .auction-info-middle {
    border-bottom: 1px solid #c6c6c6;
  }
  .auction-info .auction-info-top, .watch-auction .auction-info-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 12px 12px 25px;
  }
  @media (min-width: 550px) {
    .auction-info .auction-info-top, .watch-auction .auction-info-top {
      flex-direction: row;
    }
  }
  @media (max-width: 550px) {
    .auction-info .auction-info-top, .watch-auction .auction-info-top {
      padding: 12px 12px 12px 19px;
    }
  }
  .auction-info .auction-info-top .auction-title, .auction-info .auction-info-top .auction-live-date, .watch-auction .auction-info-top .auction-title, .watch-auction .auction-info-top .auction-live-date {
    margin: 0;
  }
  .auction-info .auction-info-top .auction-title, .watch-auction .auction-info-top .auction-title {
    color: #000000;
    font-family: Lato;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .auction-info .auction-info-top .auction-live-date, .auction-info .auction-info-top .auction-closed-date, .watch-auction .auction-info-top .auction-live-date, .watch-auction .auction-info-top .auction-closed-date {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  @media (min-width: 550px) {
    .auction-info .auction-info-top .auction-live-date, .auction-info .auction-info-top .auction-closed-date, .watch-auction .auction-info-top .auction-live-date, .watch-auction .auction-info-top .auction-closed-date {
      line-height: 36px;
      margin-bottom: 0;
    }
  }
  .auction-info .auction-info-top .info-btns, .watch-auction .auction-info-top .info-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .auction-info .auction-info-top .info-btns > *:not(:first-child), .watch-auction .auction-info-top .info-btns > *:not(:first-child) {
    margin-top: 10px;
  }
  .auction-info .auction-info-middle, .watch-auction .auction-info-middle {
    display: flex;
    flex-direction: column;
    min-height: 90px;
  }
  @media (min-width: 550px) {
    .auction-info .auction-info-middle, .watch-auction .auction-info-middle {
      flex-direction: row;
    }
  }
  .auction-info .auction-info-middle .auction-type, .watch-auction .auction-info-middle .auction-type {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ededed;
  }
  @media (min-width: 550px) {
    .auction-info .auction-info-middle .auction-type, .watch-auction .auction-info-middle .auction-type {
      max-width: 126px;
      border-bottom: none;
      border-right: 1px solid #ededed;
    }
  }
  .auction-info .auction-info-middle .auction-type img, .watch-auction .auction-info-middle .auction-type img {
    max-width: 100%;
    max-height: 50px;
  }
  .auction-info .auction-info-middle .auction-desc, .watch-auction .auction-info-middle .auction-desc {
    flex: 1;
    font-size: 12px;
    color: #5d5d5d;
    line-height: 24px;
    font-family: "Lato";
    padding: 15px 30px 15px 8px;
    white-space: break-spaces;
  }
  @media (max-width: 550px) {
    .auction-info .auction-info-middle .auction-desc, .watch-auction .auction-info-middle .auction-desc {
      padding-left: 19px;
    }
  }
  .auction-info .auction-info-middle .auction-desc .auction-readMore, .watch-auction .auction-info-middle .auction-desc .auction-readMore {
    color: #e6bd2f;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
  }
  .auction-info .auction-info-middle .auction-logo, .watch-auction .auction-info-middle .auction-logo {
    width: 100%;
    min-height: 75px;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ededed;
  }
  @media (min-width: 550px) {
    .auction-info .auction-info-middle .auction-logo, .watch-auction .auction-info-middle .auction-logo {
      max-width: 250px;
      border-top: none;
      border-left: 1px solid #ededed;
    }
  }
  .auction-info .auction-info-middle .auction-logo img, .watch-auction .auction-info-middle .auction-logo img {
    max-width: 100%;
    max-height: 75px;
  }
  .auction-info .auction-info-bottom, .watch-auction .auction-info-bottom {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .auction-info .auction-info-bottom > span, .watch-auction .auction-info-bottom > span {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 35px;
    padding: 0 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: 1px solid #5d5d5d;
  }
  .auction-info .auction-info-bottom > span:not(:last-of-type), .watch-auction .auction-info-bottom > span:not(:last-of-type) {
    margin-right: 5px;
  }
  
  .watch-auction .auction-info-top .line1, .watch-auction .auction-info-top .line2 {
    margin-left: 0 !important;
  }
  
  @media (max-width: 900px) {
    .auctions-wrapper .auctions-list .auction-item {
      display: block;
    }
    .auctions-wrapper .auctions-list .auction-item .auction-img-container {
      min-width: 250px;
      max-width: 100%;
      border-right: none;
      border-bottom: 1px solid #c6c6c6;
    }
  }
  .icon-blackened {
    filter: brightness(0);
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .lots-wrapper .top-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .lots-wrapper .top-header .left, .lots-wrapper .top-header .right {
    margin-bottom: 20px;
  }
  .lots-wrapper .top-header .right {
    margin-left: 40px;
  }
  @media (max-width: 550px) {
    .lots-wrapper .top-header .right {
      width: 150px;
      height: 100px;
      margin-left: 5px;
    }
  }
  .lots-wrapper .top-header .link {
    color: blue;
  }
  .lots-wrapper .top-header .auction-title {
    color: #030404;
    font-family: Lato;
    font-size: 40px;
    font-weight: 400;
    margin: 0 0 20px 0;
    margin-bottom: 10px;
  }
  @media (max-width: 550px) {
    .lots-wrapper .top-header .auction-title {
      font-size: 28px;
    }
  }
  .lots-wrapper .top-header .auction-subtitle {
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 300;
    color: #545757;
    font-family: "Lato";
  }
  .lots-wrapper .top-header .auction-live-date, .lots-wrapper .top-header .auction-closed-date {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    margin: 0 0 20px 0;
  }
  @media (max-width: 550px) {
    .lots-wrapper .top-header .auction-live-date, .lots-wrapper .top-header .auction-closed-date {
      font-size: 16px;
    }
  }
  .lots-wrapper .top-header .auction-desc {
    margin: 0;
    color: #030404;
    font: 400 16px Lato;
    white-space: break-spaces;
  }
  .lots-wrapper .top-header .auction-logo img {
    max-width: 250px;
    max-height: 125px;
  }
  @media (max-width: 550px) {
    .lots-wrapper .top-header .auction-logo img {
      object-fit: contain;
      width: 150px;
      height: 100px;
    }
  }
  .lots-wrapper .top-header .auction-live-date .status {
    display: inline-block;
    padding-left: 32px;
  }
  @media (max-width: 605px) {
    .lots-wrapper .top-header .auction-live-date .status {
      display: block;
      padding-left: 0;
      margin-top: 4px;
    }
  }
  .lots-wrapper .top-header .auction-live-date .status-open {
    color: #fac917;
  }
  .lots-wrapper .top-header .auction-live-date .status-live {
    color: #1f9512;
  }
  .lots-wrapper .top-btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .lots-wrapper .top-btns.upcoming {
    justify-content: space-between;
  }
  .lots-wrapper .top-btns .left, .lots-wrapper .top-btns .right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (max-width: 550px) {
    .lots-wrapper .top-btns .left, .lots-wrapper .top-btns .right {
      width: 100%;
    }
  }
  .lots-wrapper .top-btns .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    flex-shrink: 0;
    margin: 0 10px 10px 0;
  }
  .lots-wrapper .top-btns .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .lots-wrapper .top-btns .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .lots-wrapper .top-btns .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .lots-wrapper .top-btns .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .lots-wrapper .top-btns .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .lots-wrapper .top-btns .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .lots-wrapper .top-btns .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  @media (max-width: 550px) {
    .lots-wrapper .top-btns .btn {
      margin-right: 0;
      width: 100%;
    }
  }
  .lots-wrapper .top-btns .btn.enter-auction-btn {
    color: #fff;
    background-color: #fac917;
    margin-right: 0;
  }
  .lots-wrapper .top-btns.results {
    border-bottom: 1px solid #c6c6c6;
  }
  .lots-wrapper .table-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 25px 0;
    padding: 40px 25px;
    background-color: #fff;
    border-radius: 5px 5px 0 0;
  }
  .lots-wrapper .table-wrapper > div, .lots-wrapper .table-wrapper > span {
    font-family: Lato;
    font-size: 16px;
    display: flex;
    padding: 5px;
  }
  .lots-wrapper .table-wrapper > div > div, .lots-wrapper .table-wrapper > span > div {
    width: 20%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .lots-wrapper .table-wrapper > div > div:first-child, .lots-wrapper .table-wrapper > span > div:first-child {
    width: 60%;
    min-width: 130px !important;
    padding-right: 15px;
  }
  .lots-wrapper .table-wrapper .table-title {
    background-color: #666666;
    padding: 15px;
    min-width: 640px;
    justify-content: center;
    font-family: Lato;
    font-size: 14px;
    color: #fff;
    margin: 0 5px;
  }
  .lots-wrapper .table-wrapper .table-header, .lots-wrapper .table-wrapper .table-footer, .lots-wrapper .table-wrapper .table-row {
    font-weight: bold;
  }
  .lots-wrapper .table-wrapper .table-header div, .lots-wrapper .table-wrapper .table-footer div, .lots-wrapper .table-wrapper .table-row div {
    padding: 15px;
    font-family: Lato;
    font-size: 14px;
  }
  .lots-wrapper .table-wrapper .table-header div, .lots-wrapper .table-wrapper .table-footer div {
    margin: 1px;
    border: 1px solid #666;
    background-color: #c6c6c6;
    color: #4d4e4e;
  }
  .lots-wrapper .table-wrapper .table-row {
    padding-top: 0;
    padding-bottom: 0;
  }
  .lots-wrapper .table-wrapper .table-row.single-row div {
    min-width: 100px;
    border: 1px solid #c6c6c6;
  }
  .lots-wrapper .table-wrapper .table-row:not(.single-row):first-of-type div {
    border: 1px solid #c6c6c6;
    border-bottom: none;
  }
  .lots-wrapper .table-wrapper .table-row:not(.single-row):not(:first-of-type) div, .lots-wrapper .table-wrapper .table-row:not(.single-row):not(:last-of-type) div {
    border-left: 1px solid #c6c6c6;
    border-right: 1px solid #c6c6c6;
  }
  .lots-wrapper .table-wrapper .table-row:not(.single-row):last-of-type div {
    border: 1px solid #c6c6c6;
    border-top: none;
  }
  .lots-wrapper .table-wrapper .table-row div {
    margin: 0 1px;
    background-color: #ededed;
    color: #030404;
  }
  .lots-wrapper .table-wrapper.table-auction-results {
    overflow-x: scroll;
    display: inline-flex;
  }
  .lots-wrapper .table-wrapper.table-auction-results span.table-row div {
    min-width: 100px;
  }
  @media (max-width: 700px) {
    .lots-wrapper .table-wrapper.table-auction-results {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .lots-wrapper .table-wrapper.table-auction-results div.table-header div,
  .lots-wrapper .table-wrapper.table-auction-results div.table-row div,
  .lots-wrapper .table-wrapper.table-auction-results div.table-footer div {
    min-width: 100px;
  }
  .lots-wrapper .lots-controls {
    padding: 10px 0;
    border-top: 1px solid #c6c6c6;
    border-bottom: 1px solid #c6c6c6;
  }
  .lots-wrapper .lots-controls .filters-heading {
    margin: 0;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
  .lots-wrapper .lots-controls .lots-filters-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  @media (min-width: 550px) {
    .lots-wrapper .lots-controls .lots-filters-wrapper {
      flex-direction: row;
    }
  }
  @media (min-width: 1300px) {
    .lots-wrapper .lots-controls .lots-filters-wrapper {
      justify-content: space-between;
    }
  }
  .lots-wrapper .lots-controls .filter-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin: 0;
    padding-left: 10px;
    margin-top: 10px;
    position: initial;
  }
  .lots-wrapper .lots-controls .filter-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .lots-wrapper .lots-controls .filter-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .lots-wrapper .lots-controls .filter-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 550px) {
    .lots-wrapper .lots-controls .filter-btn {
      width: 100%;
    }
  }
  @media (min-width: 551px) {
    .lots-wrapper .lots-controls .filter-btn:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  .lots-wrapper .lots-controls .filter-btn:hover:not(.btn-opened) {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .lots-wrapper .lots-controls .filter-btn:hover:not(.btn-opened) .icon-wrapper {
    background-color: #fac917;
  }
  .lots-wrapper .lots-controls .filter-btn .icon-wrapper {
    transition: 200ms;
    color: #5d5d5d;
  }
  @media (min-width: 550px) and (max-width: 900px) {
    .lots-wrapper .lots-controls .filter-btn .icon-wrapper {
      margin-left: 10px;
    }
  }
  @media (min-width: 550px) {
    .lots-wrapper .lots-controls .filter-btn-sm {
      max-width: 120px;
    }
    .lots-wrapper .lots-controls .filter-btn-md {
      max-width: 155px;
    }
    .lots-wrapper .lots-controls .filter-btn-lg {
      max-width: 230px;
    }
  }
  .lots-wrapper .lots-controls .filter-btn-active:not(.btn-opened) {
    border-color: #1f9512;
  }
  .lots-wrapper .lots-controls .filter-btn-active .icon-wrapper, .lots-wrapper .lots-controls .filter-btn-active:hover .icon-wrapper {
    background-color: #1f9512;
    border-color: #1f9512;
  }
  .lots-wrapper .lots-controls .filter-btn-active .icon-wrapper > svg, .lots-wrapper .lots-controls .filter-btn-active .icon-wrapper > img, .lots-wrapper .lots-controls .filter-btn-active:hover .icon-wrapper > svg, .lots-wrapper .lots-controls .filter-btn-active:hover .icon-wrapper > img {
    filter: invert(100) brightness(200);
  }
  .lots-wrapper .catalog-settings {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }
  .lots-wrapper .catalog-settings > * {
    flex: 1;
  }
  .lots-wrapper .catalog-settings .catalog-header {
    align-self: flex-end;
    margin: 0;
    font: 400 24px Lato;
    color: #5d5d5d;
    text-transform: uppercase;
    text-align: center;
  }
  .lots-wrapper .catalog-settings .view-modes {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .lots-wrapper .catalog-settings .view-modes .view-mode-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 45px;
    height: 45px;
    background-color: #fff;
    border: 1px solid #464646;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
  }
  @media (max-width: 900px) {
    .lots-wrapper .catalog-settings .view-modes .view-mode-button {
      display: none;
    }
  }
  .lots-wrapper .catalog-settings .view-modes .view-mode-button:not(:last-child) {
    margin-right: 5px;
  }
  .lots-wrapper .catalog-settings .view-modes .view-mode-button:before {
    content: "";
    width: 100%;
    height: 100%;
    transform: scale(1.1);
    background-size: cover;
    background-position: center;
  }
  .lots-wrapper .catalog-settings .view-modes .view-mode-button.view-mode-list:before {
    background-image: url("../src/assets/img/view-list.png");
  }
  .lots-wrapper .catalog-settings .view-modes .view-mode-button.view-mode-grid:before {
    background-image: url("../src/assets/img/view-grid.png");
  }
  .lots-wrapper .catalog-settings .view-modes .view-mode-button.active {
    background-color: #1f9512;
  }
  .lots-wrapper .catalog-settings .view-modes .view-mode-button.active:before {
    filter: brightness(0) invert(1);
  }
  
  .lot-location {
    text-transform: uppercase;
  }
  
  .lots {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 900px) {
    .lots {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .lots .left-side {
    width: 100%;
  }
  .lots .left-side.showCalculator {
    width: 80%;
  }
  @media (max-width: 900px) {
    .lots .left-side.showCalculator {
      width: 100%;
    }
  }
  
  .lot-info-wrapper {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 900px) {
    .lot-info-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .lot-info-wrapper .left-side {
    width: 100%;
  }
  .lot-info-wrapper .left-side.showCalculator {
    width: 80%;
  }
  @media (max-width: 900px) {
    .lot-info-wrapper .left-side.showCalculator {
      width: 100%;
    }
  }
  
  .lots-list, .watch-lots {
    display: flex;
    flex-direction: column;
    position: relative;
    zoom: 80%;
    margin-top: 25px;
  }
  .lots-list p, .watch-lots p {
    margin: 0;
  }
  .lots-list.results .lot-item.lot-item-withdrawn .lot-info-middle, .watch-lots.results .lot-item.lot-item-withdrawn .lot-info-middle {
    background-color: #626262;
  }
  .lots-list .lot-item, .watch-lots .lot-item {
    display: flex;
    position: relative;
    margin-bottom: 25px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #2e2e2e;
  }
  @media (max-width: 550px) {
    .lots-list .lot-item .lot-item-top .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-item-top .lot-info .lot-info-bottom {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  @media (max-width: 550px) {
    .lots-list .lot-item .lot-item-top .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-item-top .lot-info .lot-info-bottom .info-wrap {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  @media (max-width: 550px) {
    .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom {
      align-items: flex-start;
    }
  }
  @media (max-width: 550px) {
    .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-wrap {
      justify-content: flex-start;
    }
  }
  .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns {
    width: 192px;
    justify-content: flex-end;
  }
  .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child {
    margin-bottom: 5px;
    margin-right: 0 !important;
  }
  @media (max-width: 550px) {
    .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child {
      margin-bottom: 0;
    }
  }
  .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns span:first-child, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns span:first-child {
    margin-bottom: 5px;
    margin-right: 0 !important;
  }
  @media (max-width: 550px) {
    .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns span:first-child, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns span:first-child {
      margin-bottom: 0;
    }
  }
  .lots-list .lot-item.dump, .watch-lots .lot-item.dump {
    border: 0;
    box-shadow: none;
    background-color: transparent;
  }
  .lots-list .lot-item.lot-item-withdrawn, .watch-lots .lot-item.lot-item-withdrawn {
    background-color: #626262;
  }
  .lots-list .lot-item.lot-item-withdrawn .lot-info, .watch-lots .lot-item.lot-item-withdrawn .lot-info {
    background-color: #cccccc;
  }
  .lots-list .lot-item.lot-item-withdrawn .lot-info .lot-info-middle, .watch-lots .lot-item.lot-item-withdrawn .lot-info .lot-info-middle {
    min-height: auto;
    border-bottom: 0;
  }
  .lots-list .lot-item.lot-item-withdrawn .lot-info .lot-info-middle .lot-title, .watch-lots .lot-item.lot-item-withdrawn .lot-info .lot-info-middle .lot-title {
    text-decoration: line-through;
    margin: 0;
  }
  .lots-list .lot-item.existing, .watch-lots .lot-item.existing {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .lots-list .lot-item.existing .lot-item-top, .watch-lots .lot-item.existing .lot-item-top {
    flex: 1;
    display: flex;
    position: relative;
  }
  .lots-list .lot-item.existing .lot-item-bottom, .watch-lots .lot-item.existing .lot-item-bottom {
    flex: 1;
    border-top: 1px solid #c6c6c6;
  }
  .lots-list .lot-item .lot-img-container, .watch-lots .lot-item .lot-img-container {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    /* width: 240px; */
    width: 320px;
    height: 220px;
  }
  @media (max-width: 900px) {
    .lots-list .lot-item .lot-img-container, .watch-lots .lot-item .lot-img-container {
      width: 100%;
    }
  }
  .lots-list .lot-item .lot-info-top, .watch-lots .lot-item .lot-info-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 9px 16px;
    color: #fff;
    background-color: #2e2e2e;
    font: 100 16px Lato;
  }
  .lots-list .lot-item .lot-info-top .lot-number .my-listing, .watch-lots .lot-item .lot-info-top .lot-number .my-listing {
    padding-left: 5px;
  }
  .lots-list .lot-item .lot-info-top .lot-number .my-listing .icon-wrapper, .watch-lots .lot-item .lot-info-top .lot-number .my-listing .icon-wrapper {
    position: relative;
    top: -2px;
    padding: 0 5px;
  }
  .lots-list .lot-item .lot-info, .watch-lots .lot-item .lot-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: Lato;
  }
  .lots-list .lot-item .lot-info .lot-info-middle, .watch-lots .lot-item .lot-info .lot-info-middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0;
    min-height: 100px;
    padding: 5px 10px 8px 20px;
    border-bottom: 1px solid #c6c6c6;
  }
  @media (min-width: 550px) {
    .lots-list .lot-item .lot-info .lot-info-middle, .watch-lots .lot-item .lot-info .lot-info-middle {
      flex-direction: row;
    }
  }
  .lots-list .lot-item .lot-info .lot-info-middle .left, .watch-lots .lot-item .lot-info .lot-info-middle .left {
    padding-right: 16px;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .lot-title-wrapper, .watch-lots .lot-item .lot-info .lot-info-middle .lot-title-wrapper {
    display: flex;
    justify-content: space-between;
    min-width: 275px;
    width: 100%;
    margin-bottom: 16px;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .lot-sub-title, .watch-lots .lot-item .lot-info .lot-info-middle .lot-sub-title {
    min-width: 125px;
    color: #2e2e2e;
    font-size: 18px;
    margin: 8px 0 8px;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .curfew, .watch-lots .lot-item .lot-info .lot-info-middle .curfew {
    color: #e6bd2f;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .lot-title, .watch-lots .lot-item .lot-info .lot-info-middle .lot-title {
    color: #2e2e2e;
    font-size: 18px;
    min-height: 0px;
    margin: 8px 0 12px !important;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state-container, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state-container {
    margin-bottom: 24px;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state {
    display: block;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    color: #dd2d2e;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.lot-closed-success, .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.sold, .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.soldAfterAuction, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.lot-closed-success, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.sold, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.soldAfterAuction {
    color: #1f9512;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.passed, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.passed {
    color: #f9891a;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .lot-comments, .watch-lots .lot-item .lot-info .lot-info-middle .lot-comments {
    word-break: break-word;
    margin-top: 12px;
    color: #696969;
    font-size: 12px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .lot-comments .lot-readMore, .watch-lots .lot-item .lot-info .lot-info-middle .lot-comments .lot-readMore {
    color: #e6bd2f;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns {
    margin-bottom: 8px;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns > *:not(:last-child), .watch-lots .lot-item .lot-info .lot-info-middle .info-btns > *:not(:last-child) {
    margin-bottom: 8px;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    width: 184px;
    height: 35px;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn:hover, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-green, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-success, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-warning, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-danger, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-red, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .lots-list .lot-item .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-info .lot-info-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
  }
  @media (min-width: 550px) {
    .lots-list .lot-item .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-info .lot-info-bottom {
      flex-direction: row;
    }
  }
  .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  @media (min-width: 550px) {
    .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
      justify-content: flex-start;
    }
  }
  .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap span, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap span {
    display: inline-block;
    line-height: 35px;
    padding: 0 7px;
    border-radius: 5px;
    border: 1px solid #5d5d5d;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  @media (min-width: 1300px) {
    .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap span, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap span {
      margin-bottom: 0;
      justify-content: flex-start;
    }
  }
  .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap span:not(:last-child), .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap span:not(:last-child) {
    margin-right: 5px;
  }
  .lots-list .lot-item .lot-info .lot-info-bottom .optiweigh-logo-wrapper, .watch-lots .lot-item .lot-info .lot-info-bottom .optiweigh-logo-wrapper {
    display: flex;
  }
  .lots-list .lot-item .lot-info .lot-info-bottom .optiweigh-logo-wrapper img, .watch-lots .lot-item .lot-info .lot-info-bottom .optiweigh-logo-wrapper img {
    align-self: center;
    max-height: 25px;
    margin-bottom: 10px;
  }
  @media (max-width: 1035px) {
    .lots-list .lot-item .lot-info .lot-info-bottom .optiweigh-logo-wrapper, .watch-lots .lot-item .lot-info .lot-info-bottom .optiweigh-logo-wrapper {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .lots-list .lot-item .lot-info .lot-info-bottom .company-logo-wrapper img, .watch-lots .lot-item .lot-info .lot-info-bottom .company-logo-wrapper img {
    max-height: 40px;
  }
  
  @media (min-width: 900px) {
    .lots-list.grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .lots-list.grid .lot-item {
      display: flex;
      flex-direction: column;
      width: 32%;
      height: 783px;
    }
    .lots-list.grid .lot-item .lot-img-container {
      width: 100%;
      height: 250px;
    }
    .lots-list.grid .lot-item .lot-info .lot-info-middle {
      padding: 9px 16px;
      flex-direction: column;
    }
    .lots-list.grid .lot-item .lot-info .lot-info-middle .left {
      padding: 0;
    }
    .lots-list.grid .lot-item .lot-info .lot-info-middle .left .lot-title {
      height: 80px;
      text-align: center;
      margin-top: 0;
    }
    .lots-list.grid .lot-item .lot-info .lot-info-middle .info-btns {
      display: flex;
      flex-direction: row;
      margin: 10px 0;
    }
    .lots-list.grid .lot-item .lot-info .lot-info-middle .info-btns > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 5px;
    }
    .lots-list.grid .lot-item .lot-info .lot-info-bottom {
      flex-direction: column;
      padding: 0;
    }
    .lots-list.grid .lot-item .lot-info .lot-info-bottom .info-wrap {
      width: 100%;
      justify-content: center;
      padding: 12px 16px 8px 16px;
      border-bottom: 1px solid #c6c6c6;
    }
    .lots-list.grid .lot-item .lot-info .lot-info-bottom .info-wrap span {
      margin-bottom: 4px;
    }
    .lots-list.grid .lot-item .lot-info .lot-info-bottom .company-logo-wrapper {
      height: 64px;
      padding: 12px 0;
    }
  }
  @media (max-width: 1300px) and (min-width: 900px) {
    .lots-list.grid .lot-item {
      width: 49%;
    }
    .lots-list.grid.dump {
      display: none;
    }
  }
  @media (max-width: 900px) {
    .lots-wrapper .catalog-settings {
      flex-direction: column;
    }
    .lots-wrapper .catalog-settings .catalog-header {
      margin-top: 10px;
      align-self: center;
    }
  
    .lots-list .lot-item, .watch-lots .lot-item {
      display: block;
    }
    .lots-list .lot-item.existing .lot-item-top, .watch-lots .lot-item.existing .lot-item-top {
      display: block;
    }
    .lots-list .lot-item .lot-img-container, .watch-lots .lot-item .lot-img-container {
      height: 270px;
    }
    .lots-list .lot-item .lot-img-container-withdrawn, .watch-lots .lot-item .lot-img-container-withdrawn {
      height: 100px;
    }
  }
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .lot-wrapper {
    position: relative;
  }
  .lot-wrapper .hidden {
    display: none;
  }
  @media (max-width: 900px) {
    .lot-wrapper .lot-info .title-block {
      justify-content: center;
      align-items: center;
    }
  }
  .lot-wrapper .top-btns {
    display: flex;
  }
  .lot-wrapper .top-btns.results {
    flex-wrap: wrap;
  }
  .lot-wrapper .top-btns.results .back-btn {
    width: 228px;
  }
  @media (max-width: 550px) {
    .lot-wrapper .top-btns.results .back-btn {
      width: 100%;
    }
  }
  .lot-wrapper .top-btns.results .watching-btn {
    width: 228px;
    height: 44px;
    margin-bottom: 10px;
  }
  @media (max-width: 550px) {
    .lot-wrapper .top-btns.results .watching-btn {
      width: 100%;
      margin-right: 0;
    }
  }
  .lot-wrapper .top-btns.results.results-flex {
    flex-wrap: nowrap;
  }
  .lot-wrapper .top-btns.results.results-flex .back-btn {
    min-width: 180px;
    width: auto;
    flex-grow: 1;
  }
  @media (max-width: 550px) {
    .lot-wrapper .top-btns.results.results-flex .back-btn {
      width: 100%;
    }
  }
  .lot-wrapper .top-btns.results.results-flex .watching-btn {
    min-width: 175px;
    max-width: 180px;
    width: auto;
    flex-grow: 1;
  }
  @media (max-width: 900px) {
    .lot-wrapper .top-btns.results.results-flex .watching-btn {
      min-width: 220px;
      max-width: 800px;
    }
  }
  @media (max-width: 550px) {
    .lot-wrapper .top-btns.results.results-flex .watching-btn {
      width: 100%;
      max-width: 1000px;
    }
  }
  @media (max-width: 900px) {
    .lot-wrapper .top-btns.results.results-flex {
      flex-wrap: wrap;
    }
  }
  .lot-wrapper .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    width: 228px;
    margin: 0 10px 10px 0;
  }
  .lot-wrapper .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .lot-wrapper .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .lot-wrapper .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .lot-wrapper .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .lot-wrapper .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .lot-wrapper .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .lot-wrapper .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .lot-wrapper .btn.btn-download-file {
    width: 271px;
  }
  @media (max-width: 1280px) {
    .lot-wrapper .btn.btn-download-file {
      width: 228px;
      margin: 0 10px 10px 0;
    }
  }
  @media (max-width: 900px) {
    .lot-wrapper .btn.btn-download-file {
      width: 100%;
      margin: 0 10px 10px 0;
    }
  }
  @media (max-width: 550px) {
    .lot-wrapper .btn.btn-download-file {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
  @media (max-width: 550px) {
    .lot-wrapper .btn {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
  .lot-wrapper .btn.btn-flex {
    width: auto;
    flex-grow: 1;
  }
  @media (max-width: 900px) {
    .lot-wrapper .btn.btn-flex {
      min-width: 220px;
      width: 228px;
    }
  }
  @media (max-width: 550px) {
    .lot-wrapper .btn.btn-flex {
      width: 100%;
    }
  }
  .lot-wrapper .btn.btn-flex .btn-title {
    display: inline-block;
  }
  .lot-wrapper .lot-number {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
  }
  .lot-wrapper .lot-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .lot-wrapper .lot-menu .auction-title, .lot-wrapper .lot-menu .auction-live-date, .lot-wrapper .lot-menu .auction-closed-date {
    margin: 0;
  }
  .lot-wrapper .lot-menu .auction-title {
    color: #000000;
    font-family: Lato;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .lot-wrapper .lot-menu .auction-live-date, .lot-wrapper .lot-menu .auction-closed-date {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
  }
  @media (max-width: 550px) {
    .lot-wrapper .lot-menu .auction-live-date, .lot-wrapper .lot-menu .auction-closed-date {
      font-size: 16px;
    }
  }
  .lot-wrapper .lot-menu .right {
    display: flex;
    order: 1;
    border-left: 0;
  }
  .lot-wrapper .lot-menu .left .left {
    order: 2;
  }
  .lot-wrapper .lot-menu .left .back-img:before {
    transform: rotate(360deg) !important;
  }
  .lot-wrapper .lot-number {
    margin: 0 0 20px 0;
  }
  .lot-wrapper .pdf-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .lot-wrapper .pdf-buttons .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 31px;
    font-family: "Myriad Pro";
    font-size: 16px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
    transition: background-color 0.2s;
    margin: 0;
    user-select: none;
    text-decoration: none;
    margin-bottom: 20px;
    margin-right: 15px;
    color: #333333;
    background-color: #cccccc;
  }
  .lot-wrapper .pdf-buttons .btn:hover {
    background-color: #aeaeae;
  }
  .lot-wrapper .pdf-buttons .btn.hidden {
    display: none;
  }
  .lot-wrapper .lot-info {
    position: relative;
    border: 1px solid #c6c6c6;
    border-radius: 10px;
    padding: 0 50px;
    margin-top: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background: white;
    font-family: "Lato";
  }
  .lot-wrapper .lot-info .lot-info-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 -50px;
    height: 59px;
    border-radius: 5px 5px 0 0;
    background-color: #2e2e2e;
    color: white;
    font-size: 32px;
    padding: 0 17px;
  }
  @media (max-width: 900px) {
    .lot-wrapper .lot-info .lot-info-top {
      font-size: 24px;
      margin: 0 -10px;
    }
  }
  @media (max-width: 550px) {
    .lot-wrapper .lot-info .lot-info-top {
      font-size: 17px;
    }
  }
  .lot-wrapper .lot-info .lot-info-top p {
    margin: 0;
  }
  .lot-wrapper .lot-info .lot-title-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .lot-wrapper .lot-info .lot-title-wrapper .company-logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 550px) {
    .lot-wrapper .lot-info .lot-title-wrapper .company-logo-wrapper {
      width: 150px;
      height: 100px;
      margin-left: 5px;
    }
  }
  .lot-wrapper .lot-info .lot-title-wrapper .company-logo-wrapper img {
    max-height: 50px;
    object-fit: contain;
  }
  .lot-wrapper .lot-info .lot-title-weights {
    display: flex;
    width: 100%;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .lot-wrapper .lot-info .lot-title-weights .title-weight {
    font-weight: 600;
    color: #5d5d5d;
    font-family: "Lato";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    flex: 1;
    border: 1px solid black;
    border-radius: 5px 0 0 0;
    margin: 0;
  }
  .lot-wrapper .lot-info .lot-title-weights .title-weight.curfew {
    color: #DBB52C;
    background-color: #5d5d5d;
    border-radius: 0 5px 0 0;
  }
  .lot-wrapper .lot-info .details {
    margin-top: 20px;
  }
  .lot-wrapper .lot-info .lot-title {
    display: block;
    color: #2e2e2e;
    font-size: 36px;
    margin: 36px 0 0 0;
  }
  @media (max-width: 900px) {
    .lot-wrapper .lot-info .lot-title {
      font-size: 20px;
    }
  }
  @media (max-width: 600px) {
    .lot-wrapper .lot-info .lot-title {
      font-size: 18px;
    }
  }
  @media (max-width: 360px) {
    .lot-wrapper .lot-info .lot-title {
      max-width: 135px;
      margin-left: 25px;
      margin-right: 5px;
    }
  }
  .lot-wrapper .lot-closed-state {
    display: block;
    font-size: 36px;
    margin-top: 8px;
    min-width: 280px;
    font-weight: 100;
    color: #dd2d2e;
    word-break: break-word;
  }
  @media (max-width: 900px) {
    .lot-wrapper .lot-closed-state {
      font-size: 26px;
    }
  }
  @media (max-width: 600px) {
    .lot-wrapper .lot-closed-state {
      min-width: 180px;
    }
  }
  @media (max-width: 600px) {
    .lot-wrapper .lot-closed-state {
      font-size: 22px;
    }
  }
  @media (max-width: 420px) {
    .lot-wrapper .lot-closed-state {
      min-width: 160px;
      font-size: 20px;
    }
  }
  @media (max-width: 350px) {
    .lot-wrapper .lot-closed-state {
      margin-left: 25px;
    }
  }
  .lot-wrapper .lot-closed-state.sold, .lot-wrapper .lot-closed-state.soldAfterAuction {
    color: #1f9512;
  }
  .lot-wrapper .lot-closed-state.passed {
    color: #f9891a;
  }
  
  .lot-purchaser-location {
    display: block;
    font-size: 20px;
    color: #1f9512;
  }
  @media (max-width: 600px) {
    .lot-purchaser-location {
      min-width: 180px;
    }
  }
  @media (max-width: 420px) {
    .lot-purchaser-location {
      min-width: 160px;
      font-size: 12px;
    }
  }
  @media (max-width: 350px) {
    .lot-purchaser-location {
      margin-left: 25px;
    }
  }
  
  @media (max-width: 900px) {
    .lot-wrapper .lot-info {
      border-left: 0;
      border-right: 0;
      padding: 0 10px;
    }
  }
  @media (max-width: 633px) {
    .lot-wrapper .lot-menu > .left {
      margin-bottom: 20px;
    }
  }
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .app-routes {
    position: relative;
    flex: 1 0;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .notifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #cccccc;
    margin: auto;
    padding: 0 5px 0 5px;
  }
  .notifications .notification ~ .notification {
    margin-top: 10px;
  }
  .notifications .notification {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 90px;
    width: 100%;
    max-width: 1280px;
    padding: 10px 0px 10px 0px;
    margin: auto 5px auto 5px;
    border-radius: 5px;
  }
  .notifications .notification.my-sync-lots {
    background-color: #219028;
    font-family: "Lato";
  }
  .notifications .notification.my-sync-lots .sync-image {
    background: url("../src/assets/img/sync.png") no-repeat;
    background-size: contain;
    height: 50px;
    width: 50px;
    margin-left: 20px;
  }
  @media (max-width: 550px) {
    .notifications .notification.my-sync-lots .sync-image {
      display: none;
    }
  }
  .notifications .notification.my-sync-lots .text-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
  .notifications .notification.my-sync-lots .text-wrapper h3 {
    color: white;
    font-size: 22px;
    margin: 0;
    margin-left: 3%;
    font-weight: 400;
  }
  .notifications .notification.my-sync-lots .text-wrapper .text {
    font-size: 16px;
    flex: 1;
    font-weight: 100;
  }
  @media (max-width: 750px) {
    .notifications .notification.my-sync-lots .text-wrapper .text {
      font-size: 14px;
    }
  }
  .notifications .notification a {
    width: 215px;
    margin-right: 45px;
    text-decoration: none;
  }
  @media (max-width: 550px) {
    .notifications .notification a {
      width: 185px;
      margin-right: 30px;
    }
  }
  @media (max-width: 400px) {
    .notifications .notification a {
      width: 145px;
    }
  }
  .notifications .notification .icon {
    flex: 1;
    margin-left: 10px;
  }
  .notifications .notification .icon svg {
    min-width: 100%;
    min-height: 70px;
    color: #ffffff;
  }
  @media (max-width: 550px) {
    .notifications .notification .icon {
      display: none;
    }
  }
  .notifications .notification .text {
    display: flex;
    align-items: center;
    flex: 6;
    margin: 0px 5px 0px 3%;
    color: #ffffff;
    font-family: Lato;
    font-size: 30px;
    font-weight: 100;
    line-height: inherit;
    white-space: normal;
  }
  @media (max-width: 750px) {
    .notifications .notification .text {
      font-size: 20px;
    }
  }
  @media (max-width: 550px) {
    .notifications .notification .text {
      font-size: 15px;
    }
  }
  .notifications .notification .text .hint {
    min-width: 170px;
    margin-right: 10px;
    font-size: 24px;
  }
  @media (max-width: 1000px) {
    .notifications .notification .text .hint {
      font-size: 15px;
      margin-right: 10px;
      min-width: 65px;
    }
  }
  .notifications .notification .text .notification-title {
    max-width: 560px;
  }
  @media (max-width: 1000px) {
    .notifications .notification .text .notification-title {
      font-size: 15px;
      margin-right: 10px;
    }
  }
  .notifications .notification .close {
    position: absolute;
    cursor: pointer;
    color: #bbbcbb;
    top: 5px;
    right: 5px;
  }
  .notifications .notification .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    flex-shrink: 0;
    margin-right: 30px;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
  .notifications .notification .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .notifications .notification .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .notifications .notification .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .notifications .notification .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .notifications .notification .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .notifications .notification .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .notifications .notification .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  @media (max-width: 550px) {
    .notifications .notification .btn {
      width: 185px;
    }
  }
  @media (max-width: 400px) {
    .notifications .notification .btn {
      width: 145px;
      padding-left: 0;
      display: flex;
      justify-content: center;
    }
  }
  .notifications .notification .btn.enter-auction-btn {
    color: #fff;
    background-color: transparent;
  }
  .notifications .notification .btn.enter-auction-btn .icon-wrapper {
    border-left: 1px solid #ffffff;
  }
  @media (max-width: 550px) {
    .notifications .notification .btn.enter-auction-btn .icon-wrapper {
      display: none;
    }
  }
  .notifications .notification .btn span {
    font-family: Lato;
    font-size: 20px;
    font-weight: 100;
  }
  .notifications .notification.live-server-warning {
    background-color: #ffffff;
    height: 80px;
    min-height: 80px;
  }
  .notifications .notification.live-server-warning .text-wrapper {
    height: 80px;
    overflow-y: scroll;
  }
  .notifications .notification.live-server-warning .text-wrapper .server-error-text {
    display: grid;
  }
  .notifications .notification.live-server-warning .text-wrapper .text {
    color: red;
  }
  .notifications .notification.live-server-warning .icon-wrapper {
    display: flex;
    align-items: center;
    padding-left: 30px;
    color: #5d5d5d;
    font-size: 50px;
  }
  .notifications .notification.live-server-warning .icon-wrapper .no-internet-image {
    background: url("../src/assets/img/No-internet.png") no-repeat;
    background-size: contain;
    height: 50px;
    width: 50px;
  }
  .notifications .notification.profile {
    background-color: #de3b30;
  }
  .notifications .notification.profile .btn:hover {
    background-color: #ffffff;
    color: #de3b30;
  }
  .notifications .notification.profile .btn:hover .icon-wrapper {
    border-left: 1px solid #de3b30;
  }
  .notifications .notification.profile .btn:hover .icon-wrapper svg {
    color: #de3b30;
  }
  .notifications .notification.review {
    background-color: #ee7c34;
  }
  .notifications .notification.review .btn:hover {
    background-color: #ffffff;
    color: #ee7c34;
  }
  .notifications .notification.review .btn:hover .icon-wrapper {
    border-left: 1px solid #ee7c34;
  }
  .notifications .notification.review .btn:hover .icon-wrapper svg {
    color: #ee7c34;
  }
  .notifications .notification.live {
    background-color: #f6c232;
  }
  .notifications .notification.live .btn:hover {
    background-color: #ffffff;
    color: #f6c232;
  }
  .notifications .notification.live .btn:hover .icon-wrapper {
    border-left: 1px solid #f6c232;
  }
  .notifications .notification.live .btn:hover .icon-wrapper svg {
    color: #f6c232;
  }
  .notifications .notification.open {
    background-color: #535353;
  }
  @media (min-width: 1000px) {
    .notifications .notification.open .text .notification-title {
      margin-left: 20px;
    }
  }
  .notifications .notification.open .btn:hover {
    background-color: #ffffff;
    color: #535353;
  }
  .notifications .notification.open .btn:hover .icon-wrapper {
    border-left: 1px solid #535353;
  }
  .notifications .notification.open .btn:hover .icon-wrapper svg {
    color: #535353;
  }
  .notifications .notification:first-child {
    margin-top: 40px;
  }
  @media (max-width: 900px) {
    .notifications .notification:first-child {
      margin-top: 20px;
    }
  }
  
  .server-error {
    width: 100%;
    display: flex;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .back-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    padding-left: 0;
    padding-right: 20px;
    width: 208px;
    padding-right: 10px;
    flex-shrink: 0;
    margin: 0 10px 10px 0;
    justify-content: flex-start;
  }
  .back-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .back-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .back-btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .back-btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .back-btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .back-btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .back-btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .back-btn .icon-wrapper {
    border-left: 0;
    border-right: 1px solid #000000;
    border-radius: 0 5px 5px 0;
  }
  @media (max-width: 550px) {
    .back-btn {
      margin-right: 0;
      width: 100%;
    }
  }
  .back-btn .icon-wrapper {
    margin-right: 10px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .app-header {
    position: relative;
    flex: 0 0;
    z-index: 1;
    background-color: #000;
    border-bottom: 1px solid #c6c6c6;
  }
  .app-header .header-wrap {
    height: 75px;
    max-width: 1280px;
    padding: 0 15px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  @media (max-width: 900px) {
    .app-header .header-wrap {
      padding-right: 100px;
    }
  }
  .app-header .header-wrap .logo:before {
    content: "";
    background: url("../src/assets/img/garmgate.png") no-repeat left center;
    background-size: cover;
    width: 204px;
    height: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 900px) {
    .app-header .header-wrap .logo:before {
      background-size: 80%;
    }
  }
  .app-header .header-wrap .date-time {
    background-color: red;
  }
  .app-header .header-wrap .right {
    font-family: "Myriad Pro";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  .app-header .header-wrap .right .user-info, .app-header .header-wrap .right .profile, .app-header .header-wrap .right .logout {
    color: #cccccc;
  }
  .app-header .header-wrap .right .profile, .app-header .header-wrap .right .logout {
    text-decoration: none;
    cursor: pointer;
    transition: color 0.5s linear;
  }
  .app-header .header-wrap .right .profile .user-icon, .app-header .header-wrap .right .logout .user-icon {
    margin-right: 5px;
  }
  .app-header .header-wrap .right .profile:hover, .app-header .header-wrap .right .logout:hover {
    color: white;
  }
  .app-header .header-wrap .right .bar:before {
    content: "|";
    padding: 0 15px;
    color: #304253;
  }
  @media (max-width: 900px) {
    .app-header .header-wrap .right {
      display: none;
    }
  }
  .app-header .header-wrap .notification {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    background: red;
    color: white;
    font-family: Lato;
    font-size: 10px;
    right: 88px;
    top: 20px;
  }
  @media (max-width: 900px) {
    .app-header .header-wrap .notification {
      display: none;
    }
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .live-app-header {
    position: relative;
    flex: 0 0;
    background-color: #000;
    border-bottom: 1px solid #c6c6c6;
    height: 120px;
    z-index: 200;
  }
  @media (max-width: 900px) {
    .live-app-header {
      padding-left: 0px !important;
      height: initial;
    }
  }
  .live-app-header .live-header-wrap {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap {
      flex-wrap: wrap;
    }
  }
  .live-app-header .live-header-wrap .logo-wrapper {
    flex: 1 1;
    height: 120px;
    min-width: 215px;
    width: 215px;
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .logo-wrapper {
      border-bottom: 1px solid gray;
      flex: 1 1;
      height: 90px;
      min-width: unset;
      width: unset;
    }
  }
  .live-app-header .live-header-wrap .logo-wrapper .logo {
    height: 80px;
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .logo-wrapper .logo {
      height: 65px;
    }
  }
  .live-app-header .live-header-wrap .logo-wrapper .logo .logo-image {
    background: url("../src/assets/img/garmgate.png") no-repeat left center;
    background-size: cover;
    width: 204px;
    height: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .logo-wrapper .logo .logo-image {
      background-size: 80%;
    }
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .logo-wrapper .logo .logo-image {
      top: 20%;
      left: 20px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .logo-wrapper .logo .logo-image {
      top: 20%;
      left: 12px;
    }
  }
  .live-app-header .live-header-wrap .logo-wrapper .logo-contact-number {
    color: white;
    font-family: "Lato";
    font-weight: 300;
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .logo-wrapper .logo-contact-number {
      margin-left: 20px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .logo-wrapper .logo-contact-number {
      margin-left: 12px;
    }
  }
  .live-app-header .live-header-wrap .data {
    display: flex;
    justify-content: space-between;
    height: 120px;
    width: 100%;
    padding-left: 20px;
    border-left: 1px solid gray;
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .data {
      border-left: unset;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data {
      padding-left: 0px;
    }
  }
  .live-app-header .live-header-wrap .data .title-date {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: white;
    font-family: Lato;
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .title-date {
      margin-left: 10px;
      width: 40%;
      justify-content: center;
    }
  }
  .live-app-header .live-header-wrap .data .title-date .auction-number {
    margin: 0;
    margin-right: 10px;
  }
  .live-app-header .live-header-wrap .data .title-date .title {
    font-size: 27px;
    font-weight: 400;
  }
  @media (max-width: 1000px) {
    .live-app-header .live-header-wrap .data .title-date .title {
      font-size: 20px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .title-date .title {
      font-size: 15px;
    }
  }
  @media (max-width: 400px) {
    .live-app-header .live-header-wrap .data .title-date .title {
      margin-bottom: 7px;
    }
  }
  .live-app-header .live-header-wrap .data .title-date .date {
    font-size: 21px;
    font-weight: 100;
  }
  @media (max-width: 1000px) {
    .live-app-header .live-header-wrap .data .title-date .date {
      font-size: 17px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .title-date .date {
      font-size: 15px;
    }
  }
  .live-app-header .live-header-wrap .data .right-data {
    display: flex;
    justify-content: flex-end;
    vertical-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .right-data .user-data {
      justify-content: center;
      padding-right: 9px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .right-data .user-data .user-name {
      margin-bottom: 7px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .right-data {
      width: 60%;
      display: flex;
      justify-content: flex-end;
    }
  }
  .live-app-header .live-header-wrap .data .user-data {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: white;
    font-family: Lato;
    padding-right: 15px;
  }
  .live-app-header .live-header-wrap .data .user-data .user-name {
    font-size: 15px;
  }
  @media (max-width: 1000px) {
    .live-app-header .live-header-wrap .data .user-data .user-name {
      font-size: 17px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .user-data .user-name {
      font-size: 12px;
    }
  }
  .live-app-header .live-header-wrap .data .user-data .user-id {
    font-size: 15px;
  }
  @media (max-width: 1000px) {
    .live-app-header .live-header-wrap .data .user-data .user-id {
      font-size: 15px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .user-data .user-id {
      font-size: 12px;
    }
  }
  .live-app-header .live-header-wrap .data .timer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
  }
  .live-app-header .live-header-wrap .data .timer .auction-timer {
    height: 80px;
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .timer .auction-timer svg {
      width: 30px;
      height: 30px;
    }
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .data .timer .auction-timer {
      width: 170px;
      height: 80px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .timer .auction-timer {
      width: 140px;
      height: 80px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .timer .auction-timer .info {
      width: 100%;
      justify-content: center;
    }
  }
  .live-app-header .live-header-wrap .data .timer .auction-timer .info .state {
    margin-bottom: 0;
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .data .timer .auction-timer .info .state {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .timer .auction-timer .info .state {
      margin-bottom: 3px;
    }
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .data .timer .auction-timer .info .small-time {
      font-size: 14px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .timer .auction-timer .info .small-time {
      font-size: 16px;
    }
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .data .timer .auction-timer .info .big-time {
      font-size: 14px;
    }
  }
  .live-app-header .live-header-wrap .logout-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 120px;
    border-left: 1px solid gray;
    font-family: "Myriad Pro";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  @media (max-width: 900px) {
    .live-app-header .live-header-wrap .logout-wrapper {
      height: 90px;
      border-bottom: 1px solid gray;
    }
  }
  .live-app-header .live-header-wrap .logout-wrapper:hover {
    color: white;
    background-color: #d11e2d;
  }
  .live-app-header .live-header-wrap .logout-wrapper .logout:hover {
    color: black;
  }
  .live-app-header .live-header-wrap .logout-wrapper .icon-door {
    margin-right: 10px;
    margin-bottom: -2px;
    width: 20px;
    height: 20px;
  }
  .live-app-header .live-header-wrap .logout-wrapper .user-info, .live-app-header .live-header-wrap .logout-wrapper .logout {
    font-family: Lato;
    color: #cccccc;
  }
  .live-app-header .live-header-wrap .logout-wrapper .logout {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    width: 200px;
  }
  @media (max-width: 500px) {
    .live-app-header .live-header-wrap .logout-wrapper .logout {
      width: 150px;
    }
  }
  .live-app-header .live-header-wrap .logout-wrapper .bar:before {
    content: "|";
    padding: 0 15px;
    color: #304253;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .app-footer {
    flex: 0 0;
    z-index: 1;
    background-color: black;
  }
  .app-footer .footer-wrap {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 75px;
    padding: 0 15px;
    text-align: center;
  }
  .app-footer .footer-wrap .copyright {
    color: #ffffff;
    font-family: Lato;
    font-size: 17px;
    font-weight: 100;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .app-navbar {
    position: relative;
    width: 100%;
    border-bottom: 5px solid #5d5d5d;
    background-color: #333333;
    z-index: 2;
    user-select: none;
  }
  .app-navbar .navbar-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 50px;
    overflow: hidden;
  }
  @media (min-width: 900px) {
    .app-navbar .navbar-wrap {
      max-width: 1280px;
      padding: 0 15px;
      margin: 0 auto;
      justify-content: flex-start;
    }
  }
  .app-navbar .navbar-wrap .close, .app-navbar .navbar-wrap .user-tab {
    display: none;
  }
  .app-navbar .navbar-wrap .navbar {
    display: flex;
    position: relative;
    font-family: Lato;
    font-size: 16px;
  }
  @media (max-width: 900px) {
    .app-navbar .navbar-wrap .navbar {
      font-size: 14px;
    }
  }
  .app-navbar .navbar-wrap .navbar .tab {
    margin-top: 8px;
    display: flex;
    padding: 10px 15px 20px 15px;
    border-radius: 5px;
    border-top: 3px solid transparent;
    text-decoration: none;
  }
  @media (max-width: 900px) {
    .app-navbar .navbar-wrap .navbar .tab {
      padding: 10px 10px 20px 10px;
    }
  }
  .app-navbar .navbar-wrap .navbar .tab.mobile {
    display: none;
  }
  .app-navbar .navbar-wrap .navbar .tab .tab-title {
    margin: 0 5px;
    color: #c6c6c6;
    font-size: 16px;
    font-weight: 400;
  }
  .app-navbar .navbar-wrap .navbar .tab .tab-title .tab-text {
    padding-left: 5px;
  }
  .app-navbar .navbar-wrap .navbar .tab.active {
    border-color: #219028;
    background-color: #5d5d5d;
  }
  @media (max-width: 550px) {
    .app-navbar .navbar-wrap .navbar .tab.active {
      height: 51px;
    }
  }
  .app-navbar .navbar-wrap .navbar .tab.active .tab-title {
    color: #ffffff;
  }
  .app-navbar .navbar-wrap .navbar .tab:hover .tab-title {
    color: #ffffff;
  }
  
  @media (max-width: 900px) {
    .app-navbar:not(.live-navbar) {
      width: 0;
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
      overflow: hidden;
      transition: width 0.5s ease;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap {
      width: 300px;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap .user-tab {
      display: block;
      position: absolute;
      top: 16px;
      padding-left: 20px;
      font-family: Lato;
      font-size: 16px;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap .user-tab .user-icon {
      margin-right: 5px;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap .user-tab .profile {
      color: #c6c6c6;
      text-decoration: none;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap .user-tab .profile:hover {
      color: #ffffff;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap .user-tab .notification {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.7;
      background: red;
      color: white;
      font-family: Lato;
      font-size: 10px;
      right: -10px;
      top: -7px;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap .tab-title .tab-text {
      top: 0 !important;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap .close {
      display: block;
      height: 50px;
      text-align: right;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap .close span {
      display: inline-block;
      background-repeat: no-repeat;
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-top: 15px;
      margin-right: 20px;
      background-image: url("../src/assets/img/close.svg");
      transition: opacity 0.5s ease;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap .close span:hover {
      opacity: 0.8;
    }
    .app-navbar:not(.live-navbar) .navbar-wrap, .app-navbar:not(.live-navbar) .navbar {
      height: 100%;
      display: block;
    }
    .app-navbar:not(.live-navbar) .navbar {
      width: 100%;
    }
    .app-navbar:not(.live-navbar) .navbar .tab {
      display: block !important;
    }
    .app-navbar:not(.live-navbar) .navbar .tab .tab-title {
      border: none !important;
      margin: 0 !important;
      display: block;
      border-radius: 0 !important;
      padding: 20px;
    }
    .app-navbar:not(.live-navbar).show-mobile {
      width: 300px;
    }
    .app-navbar:not(.live-navbar) .navbar {
      flex-direction: column;
    }
    .app-navbar:not(.live-navbar) .navbar .tab {
      padding: 0 15px;
      position: relative;
    }
    .app-navbar:not(.live-navbar) .navbar .tab:before {
      position: absolute !important;
      top: 13px !important;
    }
    .app-navbar:not(.live-navbar) .navbar .tab .tab-title {
      padding: 13px 20px;
    }
  }
  
  .overlay {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    background-color: rgba(51, 51, 51, 0.3);
  }
  .overlay.show {
    right: 0;
  }
  
  .burger {
    display: none;
    background-image: url("../src/assets/img/menu_icon.svg");
    width: 30px;
    height: 25px;
    position: absolute;
    z-index: 2;
    right: 25px;
    top: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    cursor: pointer;
  }
  @media (max-width: 900px) {
    .burger {
      display: block;
    }
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .media-carousel {
    position: relative;
    width: 100%;
    white-space: nowrap;
    margin: 5px 0;
  }
  .media-carousel .nav-prev, .media-carousel .nav-next {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 25% !important;
    height: 50%;
    display: flex;
    align-items: center;
    user-select: none;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    cursor: pointer;
  }
  .media-carousel .nav-prev.active, .media-carousel .nav-next.active {
    opacity: 1;
    pointer-events: all;
    z-index: 50;
  }
  .media-carousel .nav-prev.active:hover, .media-carousel .nav-next.active:hover {
    opacity: 1 !important;
  }
  .media-carousel .nav-prev .icon-wrapper, .media-carousel .nav-next .icon-wrapper {
    font-size: 25px;
    color: #fff;
  }
  .media-carousel .nav-prev {
    left: 10px;
    z-index: 9;
  }
  .media-carousel .nav-next {
    top: 0;
    right: 10px;
    justify-content: flex-end;
  }
  .media-carousel .media-wrap {
    margin: 0;
    overflow: hidden;
    user-select: none;
  }
  .media-carousel .media-wrap .media-area {
    position: relative;
    transition: all 0.5s;
  }
  .media-carousel .media-wrap .media-area label {
    cursor: pointer;
  }
  .media-carousel .media-wrap .media-area .no-image .file-input {
    display: none;
  }
  .media-carousel .media-wrap .media-area .carousel-item {
    cursor: pointer;
    width: 320px;
    height: 213px;
    max-width: 100%;
    margin-right: 3px;
    display: inline-block;
    position: relative;
  }
  @media (max-width: 900px) {
    .media-carousel .media-wrap .media-area .carousel-item {
      width: 240px;
      height: 180px;
    }
  }
  .media-carousel .media-wrap .media-area .carousel-item.with-title {
    height: 235px;
  }
  .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper {
    height: 100%;
    position: relative;
  }
  .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper .carousel-img {
    height: unset !important;
    width: unset !important;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper .carousel-img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
  .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper .carousel-img.video-thumbnail {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
  .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper svg {
    position: absolute;
    top: calc(50% - 75px / 2);
    left: calc(50% - 75px / 2);
    height: 75px;
    width: 75px;
    color: #2e2e2e;
    z-index: 2;
  }
  .media-carousel .media-wrap .media-area .carousel-item .remove {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
  }
  .media-carousel .media-wrap .media-area .carousel-item .set-main {
    padding: 3px 5px;
    background: #808080;
    border-radius: 5px;
    font: 400 16px "Lato";
    color: #fff;
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 2;
  }
  .media-carousel .media-wrap .media-area .carousel-item .set-main .inner-line {
    display: flex;
    align-items: center;
  }
  .media-carousel .media-wrap .media-area .carousel-item .set-main .inner-line .set-main-text {
    margin: 0 0 0 5px;
  }
  .media-carousel .media-wrap .media-area .carousel-item .carousel-img, .media-carousel .media-wrap .media-area .carousel-item .carousel-video {
    display: block;
  }
  .media-carousel .media-wrap .media-area .carousel-item .date {
    padding: 3px 5px;
    border-radius: 5px;
    font: 400 16px "Lato";
    color: #fff;
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #808080;
  }
  .media-carousel .media-wrap .media-area .carousel-item .image-loader-spinner {
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  .media-carousel .media-wrap .media-area .carousel-item .carousel-img {
    height: unset!important;
    width: unset!important;
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
  }
  .media-carousel .media-wrap .media-area .carousel-item .video-title {
    display: flex;
    padding: 5px;
    justify-content: center;
    text-align: center;
    white-space: normal;
  }
  .media-carousel .media-wrap .media-area .carousel-item .carousel-video {
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: initial;
  }
  .media-carousel .media-wrap .media-area .carousel-item .carousel-video.with-title {
    height: 80%;
  }
  .media-carousel .media-wrap .media-area .carousel-item .video-prewiev {
    object-fit: contain;
  }
  .media-carousel .media-wrap .media-area .carousel-item .spinner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
  .media-carousel .media-wrap .media-area .carousel-item .spinner.hidden {
    display: none;
  }
  .media-carousel.preview {
    height: 100%;
    margin: 0;
  }
  .media-carousel.preview .media-wrap {
    height: 100%;
    margin: 0;
  }
  .media-carousel.preview .media-wrap .media-area {
    height: 100%;
  }
  .media-carousel.preview .media-wrap .media-area .carousel-item {
    width: 100%;
    height: 100%;
    margin-right: 0px;
  }
  @media (max-width: 900px) {
    .media-carousel.preview .media-wrap .media-area .carousel-item {
      width: 100% !important;
    }
  }
  .media-carousel.preview .media-wrap .media-area .carousel-item .carousel-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .media-carousel.preview .nav-prev {
    background: transparent;
    left: 5px;
  }
  .media-carousel.preview .nav-next {
    background: transparent;
    right: 5px;
  }
  .carousel-wrap {
    width: 100%;
    height: 100%;
  }
  
  .dots-wrap {
    padding-bottom: 12px;
    text-align: center;
  }
  .dots-wrap .dot {
    width: 10px;
    height: 10px;
    margin-right: 9px;
    background: #2e2e2e;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
  }
  .dots-wrap .dot.active {
    background: #8e8e8e;
  }
  
  canvas {
    width: 320px;
    height: 190px;
    position: absolute;
    top: 20px;
  }
  
  div[data-rbd-droppable-context-id] {
    position: relative;
  }
  
  .order {
    padding: 3px 5px;
    background: #808080;
    border-radius: 5px;
    position: absolute;
    z-index: 2;
    top: 24px;
    right: 10px;
    font: 400 16px "Lato";
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .order .control-icon {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }
  .order .text {
    background: #808080;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 2px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .details, .lot-documents {
    position: relative;
  }
  .details .row, .lot-documents .row {
    margin-bottom: 5px;
    border-radius: 2px;
  }
  .details .row .row-title-wrap, .lot-documents .row .row-title-wrap {
    margin: 0;
    background-color: #666666;
    border-bottom: 1px solid #c6c6c6;
  }
  .details .row .row-title-wrap .row-title, .lot-documents .row .row-title-wrap .row-title {
    padding: 10px 10px;
    margin: 0;
    font: 700 16px "Lato";
    color: white;
  }
  .details .row .documents-container, .lot-documents .row .documents-container {
    margin-top: 5px;
    padding: 20px 0;
    background-color: #ededed;
    border: 1px solid #c6c6c6;
  }
  .details .row .fields-container, .lot-documents .row .fields-container {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 900px) {
    .details .row .fields-container, .lot-documents .row .fields-container {
      flex-direction: column;
    }
  }
  .details .row .left-column, .details .row .right-column, .lot-documents .row .left-column, .lot-documents .row .right-column {
    border: 1px solid #c6c6c6;
    background-color: #ededed;
    width: 50%;
  }
  @media (max-width: 900px) {
    .details .row .left-column, .details .row .right-column, .lot-documents .row .left-column, .lot-documents .row .right-column {
      width: 100%;
      border-radius: 0 !important;
    }
  }
  .details .row .left-column, .lot-documents .row .left-column {
    margin-right: 5px;
  }
  @media (max-width: 900px) {
    .details .row .left-column, .lot-documents .row .left-column {
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: #c6c6c6;
    }
  }
  @media (max-width: 900px) {
    .details .row .right-column, .lot-documents .row .right-column {
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: #c6c6c6;
    }
  }
  .details .row .one-column, .lot-documents .row .one-column {
    border: 1px solid #c6c6c6;
    background-color: #ededed;
    width: 100%;
  }
  .details .row .one-column .option .option-line .option-value, .lot-documents .row .one-column .option .option-line .option-value {
    flex: 5;
  }
  .details .row .file-wrap, .lot-documents .row .file-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10px;
  }
  @media (max-width: 550px) {
    .details .row .file-wrap, .lot-documents .row .file-wrap {
      flex-direction: column;
    }
  }
  .details .row .file-wrap svg, .lot-documents .row .file-wrap svg {
    margin-left: 5px;
  }
  .details .row .file-wrap .description, .lot-documents .row .file-wrap .description {
    flex: 1 1;
  }
  .details .row .file-wrap .file-name, .lot-documents .row .file-wrap .file-name {
    flex: 1 1;
    font-weight: bold;
    margin: 0;
  }
  .details .row .file-wrap .view-link, .details .row .file-wrap .download-link, .lot-documents .row .file-wrap .view-link, .lot-documents .row .file-wrap .download-link {
    flex: 1 1;
    color: #030404;
    text-decoration: none;
  }
  .details .row .repeat-option .repeat-title-wrap, .lot-documents .row .repeat-option .repeat-title-wrap {
    margin: 0;
    background-color: white;
    border-width: 1px 0;
    border-style: solid;
    border-color: #c6c6c6;
  }
  .details .row .repeat-option .repeat-title-wrap .repeat-title, .lot-documents .row .repeat-option .repeat-title-wrap .repeat-title {
    padding: 10px 10px;
    margin: 0;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    font-size: 16px;
    font-weight: 700;
  }
  .details .row .repeat-option .repeat-line, .lot-documents .row .repeat-option .repeat-line {
    margin-left: 10px;
  }
  .details .row .option .option-line, .lot-documents .row .option .option-line {
    display: flex;
    align-items: center;
  }
  .details .row .option .option-line.text-info, .lot-documents .row .option .option-line.text-info {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: 900px) {
    .details .row .option .option-line.text-info, .lot-documents .row .option .option-line.text-info {
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  @media (max-width: 550px) {
    .details .row .option .option-line.text-info, .lot-documents .row .option .option-line.text-info {
      font-size: 9px;
    }
  }
  .details .row .option .option-line .option-title, .details .row .option .option-line .option-value, .lot-documents .row .option .option-line .option-title, .lot-documents .row .option .option-line .option-value {
    word-break: break-word;
    margin: 0;
    padding: 5px 10px;
    color: #030404;
  }
  .details .row .option .option-line .option-title.empty, .details .row .option .option-line .option-value.empty, .lot-documents .row .option .option-line .option-title.empty, .lot-documents .row .option .option-line .option-value.empty {
    color: #7e7e7e;
  }
  .details .row .option .option-line .option-title, .lot-documents .row .option .option-line .option-title {
    flex: 1;
    min-width: 100px;
    font: 400 16px "Lato";
  }
  .details .row .option .option-line .option-value, .lot-documents .row .option .option-line .option-value {
    flex: 2;
  }
  @media (max-width: 550px) {
    .details .row .option .option-line .option-value, .lot-documents .row .option .option-line .option-value {
      flex: 1;
    }
  }
  .details .row .option .option-line .option-value .paragraph, .lot-documents .row .option .option-line .option-value .paragraph {
    display: block;
  }
  .details .row .option .option-line .option-value a, .lot-documents .row .option .option-line .option-value a {
    text-decoration: none;
  }
  .details .row .option .option-line .option-value a:hover, .lot-documents .row .option .option-line .option-value a:hover {
    text-decoration: underline;
  }
  .details .row .option .option-line .option-value .signature-img, .lot-documents .row .option .option-line .option-value .signature-img {
    height: 100px;
  }
  .details .row .option .signature-icon, .lot-documents .row .option .signature-icon {
    width: 180px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .auction-timer {
    display: flex;
    width: 185px;
    height: 70px;
    background-color: yellow;
    border-radius: 5px;
    padding: 1px;
  }
  .auction-timer .icon {
    width: 50px;
    height: 50px;
    margin: auto 10px auto 10px;
  }
  .auction-timer .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Lato;
  }
  .auction-timer .info .state {
    font-size: 17px;
    margin-bottom: 6px;
  }
  @media (max-width: 1400px) {
    .auction-timer .info .state {
      font-size: 16px;
    }
  }
  .auction-timer .info .big-time {
    font-size: 15px;
    margin-bottom: 3px;
  }
  .auction-timer .info .small-time {
    font-size: 27px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .lot.timer-text {
    font: 400 16px "Lato";
    color: #666;
    word-wrap: none;
    user-select: none;
    word-break: break-all;
  }
  @media (max-width: 410px) {
    .lot.timer-text {
      font-size: 16px !important;
    }
  }
  .lot.frozen {
    background: red;
    border-radius: 0 3px 3px 0;
    padding-right: 1px;
    padding-left: 1px;
  }
  .lot.paused {
    background: grey;
    border-radius: 0 3px 3px 0;
    padding-right: 1px;
    padding-left: 1px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .app-modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8);
    user-select: none;
    max-height: 100%;
  }
  .app-modal-overlay .app-modal {
    min-width: 300px;
    max-width: 50%;
    min-height: 190px;
    max-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 25px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 10px;
    background-color: white;
  }
  @media (max-height: 700px) {
    .app-modal-overlay .app-modal {
      max-height: unset;
    }
  }
  .app-modal-overlay .app-modal .title, .app-modal-overlay .app-modal .message {
    margin: 0;
    padding: 10px 0;
  }
  .app-modal-overlay .app-modal .title {
    text-align: center;
    font: 700 25px "Lato";
  }
  .app-modal-overlay .app-modal .message {
    text-align: center;
    font: 400 18px Lato;
  }
  .app-modal-overlay .app-modal .buttons {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .app-modal-overlay .app-modal .buttons .modal-button {
    min-height: 2em;
    min-width: 10em;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: #5d5d5d;
    color: white;
    font: 400 20px "Myriad Pro";
    cursor: pointer;
    transition: all 0.3s;
  }
  .app-modal-overlay .app-modal .buttons .modal-button:hover {
    box-shadow: 2px 2px 2px #888888;
  }
  @media (max-height: 460px) {
    .app-modal-overlay .app-modal {
      padding-bottom: 0;
    }
  }
  .app-modal-overlay .app-modal-1 {
    min-width: 300px;
    max-width: 50%;
    min-height: 300px;
    max-height: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px 25px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 10px;
    background-color: white;
  }
  @media (max-height: 700px) {
    .app-modal-overlay .app-modal-1 {
      max-height: unset;
    }
  }
  .app-modal-overlay .app-modal-1 .title, .app-modal-overlay .app-modal-1 .message {
    margin: 0;
    padding: 10px 0;
  }
  .app-modal-overlay .app-modal-1 .title {
    text-align: center;
    font: 700 25px "Lato";
  }
  .app-modal-overlay .app-modal-1 .message {
    text-align: center;
    font: 400 18px Lato;
  }
  .app-modal-overlay .app-modal-1 .buttons {
    padding-top: 60px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .app-modal-overlay .app-modal-1 .buttons .modal-button {
    min-height: 2em;
    min-width: 10em;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: #5d5d5d;
    color: white;
    font: 400 20px "Myriad Pro";
    cursor: pointer;
    transition: all 0.3s;
  }
  .app-modal-overlay .app-modal-1 .buttons .modal-button:hover {
    box-shadow: 2px 2px 2px #888888;
  }
  @media (max-height: 460px) {
    .app-modal-overlay .app-modal-1 {
      padding-bottom: 0;
    }
  }
  .app-modal-overlay.upload-csv .app-modal {
    max-width: 650px;
    min-height: 600px;
    padding: 0;
    display: block;
  }
  .app-modal-overlay.upload-csv .app-modal .title {
    width: 100%;
    padding-left: 20px;
    background: #5d5d5d;
    text-align: left;
    color: white;
    font: 400 20px "Lato";
  }
  .app-modal-overlay.upload-csv .app-modal .buttons {
    width: 100%;
    padding: 20px;
    display: inline-block;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    min-width: 9em;
    padding-left: 10px;
    justify-content: left;
    border: 1px solid #c6c6c6;
    background-color: #ededed;
    color: #666666;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom:first-of-type {
    float: left;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom:last-of-type {
    float: right;
  }
  .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper {
    width: 44px;
    position: absolute;
    top: 0;
    right: 0;
    border-color: #c6c6c6;
  }
  @media (max-width: 550px) {
    .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom {
      float: none !important;
    }
  }
  .app-modal-overlay.upload-csv .app-modal p.message {
    padding: 0 25px;
    text-align: left;
  }
  .app-modal-overlay.upload-csv .app-modal .custom-message {
    padding: 15px 25px;
  }
  .app-modal-overlay.upload-csv .app-modal .custom-message .message {
    padding: 0;
    margin: 0 0 20px;
  }
  .app-modal-overlay.upload-csv .app-modal .custom-message .form-row .row-title {
    width: 160px;
  }
  .app-modal-overlay.upload-csv .app-modal .fg-dropdown__menu {
    height: 103px;
    min-height: 103px;
    z-index: 10;
  }
  .app-modal-overlay.upload-csv .app-modal .fg-dropdown__menu .fg-dropdown__menu-list {
    height: 100px;
    min-height: 100px;
  }
  
  .ReactModal__Overlay {
    z-index: 50;
  }
  
  .select-dropdown {
    font-family: Lato;
  }
  .select-dropdown :hover {
    cursor: pointer;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .watching-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    width: 184px;
    height: 35px;
    background-color: #fff;
    color: #030404;
  }
  .watching-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .watching-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .watching-btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .watching-btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .watching-btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .watching-btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .watching-btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .watching-btn .icon-wrapper {
    background-color: #007fc1;
    transition: 400ms;
    color: #fff;
  }
  .watching-btn.active {
    background-color: #007fc1;
    color: #fff;
  }
  .watching-btn.active .icon-wrapper {
    background-color: #fff;
    transition: 400ms;
    color: #007fc1;
  }
  .watching-btn.disabled {
    pointer-events: none;
  }
  
  .watching-small-btn.with-borders {
    border: 3px solid black;
    padding: 5px;
    border-radius: 4px;
    border-color: #007fc1;
  }
  .watching-small-btn.active {
    background: #007fc1;
  }
  .watching-small-btn .watch-flag {
    width: 33px;
    height: 38px;
    color: #007fc1;
  }
  .watching-small-btn .watch-flag.small {
    width: 18px;
    height: 20px;
  }
  .watching-small-btn .watch-flag.active {
    color: #fff;
  }
  .watching-small-btn .watch-flag.liveLot {
    color: #000;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .form-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    min-width: 100%;
    min-height: 40px;
  }
  .form-row.form-row-repeat-for-number .rfn-fields {
    margin-right: 0;
  }
  @media (min-width: 1300px) {
    .form-row.form-row-repeat-for-number .rfn-stats {
      border-radius: 0 0 0 5px;
    }
    .form-row.form-row-repeat-for-number .rfn-fields {
      margin-left: 5px;
      border-radius: 0 0 5px 0;
    }
  }
  @media (max-width: 1300px) {
    .form-row.form-row-repeat-for-number .rfn-stats {
      border-radius: 0 0 5px 5px;
    }
    .form-row.form-row-repeat-for-number .rfn-fields {
      margin: 0;
      border-radius: 5px;
    }
  }
  .form-row.imports-exports {
    border-radius: 5px;
  }
  .form-row.imports-exports .rfn-fields {
    min-height: 190px;
    margin: 0;
    display: block;
    border-radius: inherit;
  }
  .form-row.imports-exports .rfn-fields .block h3 {
    width: 240px;
    height: 44px;
    margin: 0;
    padding-top: 12px;
    padding-left: 20px;
    float: left;
    font: 400 16px Lato;
  }
  .form-row.imports-exports .rfn-fields .block:last-child .btn {
    margin-bottom: 0;
  }
  @media (min-width: 550px) {
    .form-row.imports-exports .rfn-fields {
      height: 140px;
    }
  }
  .form-row.imports-exports .hidden {
    position: absolute;
  }
  .form-row .selection-wrapper {
    display: flex;
    width: calc(100% - 260px);
  }
  @media (max-width: 550px) {
    .form-row .selection-wrapper {
      width: 100%;
    }
  }
  .form-row .selection-wrapper .option-block {
    margin-left: 5px;
    padding: 10px 10px;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    font: 400 14px Lato;
    color: #666666;
    background-color: #ededed;
    width: inherit;
    max-width: 270px;
    display: flex;
    align-items: center;
  }
  @media (max-width: 700px) {
    .form-row .selection-wrapper .option-block {
      width: 100%;
    }
  }
  .form-row .selection-wrapper .option-block.active {
    background-color: #fff;
    border: 2px solid green;
  }
  .form-row .selection-wrapper .option-block:hover {
    cursor: pointer;
  }
  .form-row .selection-wrapper .option-block:first-child {
    margin-left: 0;
  }
  .form-row .selection-wrapper .icon-green {
    color: green;
    font-size: 20px;
    margin-left: 5px;
  }
  .form-row-long .row-title {
    align-self: flex-start;
    padding-top: 20px;
  }
  .form-row .row-title {
    width: 260px;
  }
  .form-row .row-title-long {
    width: 350px;
  }
  .form-row .row-content {
    position: relative;
    padding-left: 15px;
  }
  .form-row .row-content .value {
    font: 400 14px Lato;
  }
  .form-row .input, .form-row .textarea {
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    padding: 10px 10px;
    width: 270px;
    font: 400 14px Lato;
    color: #666666;
    background-color: #ededed;
  }
  .form-row .input.short, .form-row .textarea.short {
    width: 150px;
    height: 34px;
  }
  .form-row .hidden {
    visibility: hidden;
  }
  .form-row .hidden.no-width {
    width: 0;
  }
  .form-row .input {
    height: 44px;
  }
  .form-row .textarea {
    min-height: 115px;
    resize: none;
  }
  .form-row .date-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    width: 270px;
    padding-left: 10px;
    background-color: #ededed;
    border: 1px solid #c6c6c6;
    box-shadow: none !important;
  }
  .form-row .date-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .form-row .date-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .form-row .date-btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .form-row .date-btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .form-row .date-btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .form-row .date-btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .form-row .date-btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .form-row .date-btn .date-value {
    color: #666666;
  }
  .form-row .date-btn .icon-wrapper {
    color: #5d5d5d;
    border-color: #c6c6c6;
  }
  .form-row .date-btn-opened {
    background-color: #fff;
    border-width: 1px 1px 0 1px;
    border-color: #464646;
    border-radius: 5px 5px 0 0;
  }
  .form-row .date-btn-opened .icon-wrapper {
    background-color: #1f9512;
    color: #fff;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }
  .form-row .day-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 900;
    width: 270px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #464646;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
  }
  .form-row .day-picker-container .btns {
    padding: 0 10px 0 10px;
    display: flex;
    width: 100%;
  }
  .form-row .day-picker-container .btns .btn {
    flex: 1 1;
    margin: 0 0 10px 0;
    width: 40px;
  }
  .form-row .day-picker-container .btns .btn ~ .btn {
    margin-left: 10px;
  }
  .form-row .rating {
    display: flex;
    justify-content: space-between;
    width: 270px;
    padding-top: 20px;
  }
  .form-row .rating .rating-item {
    min-height: 40px;
    width: 40px;
    justify-content: space-between;
  }
  .form-row .switcher {
    display: flex;
    align-items: center;
  }
  .form-row .switcher .react-toggle {
    margin: 0 20px;
  }
  .form-row .switcher .row-text {
    margin: 0;
    color: #333;
  }
  .form-row .fg-select-container .fg-dropdown__control {
    width: 270px;
  }
  .form-row .fg-select-container .fg-dropdown__input {
    max-width: 200px;
  }
  .form-row-rfn {
    display: flex;
    flex-flow: row nowrap;
  }
  .form-row-rfn .rfn-stats, .form-row-rfn .rfn-fields, .form-row-rfn .rfn-notice, .form-row-rfn .rfn-editWeight {
    min-height: 240px;
    border: 1px solid #c6c6c6;
    background-color: #ffffff;
  }
  @media (max-width: 1300px) {
    .form-row-rfn .rfn-stats, .form-row-rfn .rfn-fields, .form-row-rfn .rfn-notice, .form-row-rfn .rfn-editWeight {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .form-row-rfn .rfn-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 250px;
    border-radius: 0 0 0 5px;
    padding: 10px 20px;
  }
  .form-row-rfn .rfn-stats .rfn-current {
    font-family: Lato;
    font-weight: 400;
    color: #030404;
  }
  .form-row-rfn .rfn-stats .rfn-current .current-num {
    font-size: 88px;
  }
  .form-row-rfn .rfn-stats .rfn-current .total-num {
    font-size: 44px;
  }
  .form-row-rfn .rfn-stats .fg-progress-bar {
    height: 26px;
    max-width: 250px;
    border: 0;
    border-radius: 5px;
    background-color: #efefef;
  }
  .form-row-rfn .rfn-stats .rfn-progress-info {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
  }
  .form-row-rfn .rfn-stats .rfn-progress-info .rfn-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50% - 2px);
    font: 400 14px Lato;
    color: #030404;
    border-radius: 5px;
    padding: 10px 20px;
  }
  .form-row-rfn .rfn-stats .rfn-progress-info .rfn-info-rating {
    background-color: #76b6d7;
  }
  .form-row-rfn .rfn-stats .rfn-progress-info .rfn-info-weight {
    background-color: #f4db82;
  }
  .form-row-rfn .rfn-stats .rfn-progress-info .rfn-info-value {
    font-size: 30px;
  }
  .form-row-rfn .rfn-fields {
    display: flex;
    flex: 1;
    position: relative;
    border-radius: 0 0 5px;
    margin: 0 5px;
    padding: 20px 0;
  }
  @media screen and (max-width: 550px) {
    .form-row-rfn .rfn-fields {
      padding: 0;
    }
  }
  .form-row-rfn .rfn-fields:not(:last-child) {
    justify-content: center;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    overflow: hidden;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-fields-container {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    transition: all 0.4s linear;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-fields-container .rfn-fields-item {
    position: relative;
    min-width: 100%;
    padding: 0 20px;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-fields-container .rfn-fields-item .form-row {
    justify-content: center;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-fields-container .rfn-fields-item .form-row .row-title {
    width: 170px;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 20px;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    width: 140px;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav-prev {
    padding-left: 0;
    padding-right: 20px;
    margin-right: 2px;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav-prev .icon-wrapper {
    border-left: 0;
    border-right: 1px solid #000000;
    border-radius: 0 5px 5px 0;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .rfn-nav-next {
    margin-left: 2px;
  }
  .form-row-rfn .rfn-fields .rfn-fields-wrapper .rfn-controls .locked {
    filter: grayscale(100%);
    pointer-events: none;
  }
  .form-row-rfn .rfn-notice {
    margin: 0 0 0 5px;
    flex: 0 0 220px;
    border-radius: 0 0 5px;
    padding: 10px;
    color: #030404;
  }
  .form-row-rfn .rfn-notice .rfn-recommend {
    font: 400 14px Lato;
    padding: 30px 10px 0 5px;
  }
  @media (max-width: 1300px) {
    .form-row-rfn .rfn-notice .rfn-recommend {
      padding: 0;
      vertical-align: middle;
    }
  }
  .form-row-rfn .rfn-editWeight {
    font: 400 16px Lato;
    max-width: none;
    flex: 0 0 280px;
    color: #030404;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    overflow: visible;
  }
  .form-row-rfn .rfn-editWeight .rfn-editWeight-container {
    max-width: 440px;
  }
  .form-row-rfn .rfn-editWeight .rfn-editWeight-container .form-row .row-content p {
    margin-right: 3px;
  }
  .form-row-rfn .rfn-editWeight .row-title .row-text {
    font-size: 14px;
  }
  .form-row-rfn .rfn-editWeight .rfn-fields-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .form-row-rfn .rfn-editWeight .rfn-fields-container .space-between {
    justify-content: space-between;
  }
  .form-row-rfn .rfn-editWeight .form-row .input {
    width: 130px;
    padding-right: 20px;
  }
  .form-row-rfn .rfn-editWeight .form-row .percent {
    position: absolute;
    top: 13px;
    right: 5px;
  }
  .form-row-rfn .rfn-editWeight .row-title {
    width: 90px;
  }
  .form-row-rfn .rfn-editWeight .adjust-weight-button {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    align-items: center;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    width: 150px;
    padding-left: 10px;
  }
  @media (max-width: 1300px) {
    .form-row-rfn .rfn-editWeight .adjust-weight-button {
      align-self: center;
      margin-left: 310px;
    }
  }
  @media (max-width: 550px) {
    .form-row-rfn .rfn-editWeight .adjust-weight-button {
      align-self: flex-end;
      margin-left: 0;
    }
  }
  .form-row-rfn .rfn-editWeight .adjust-weight-button .icon-wrapper {
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex: 0 0 44px;
    flex: 0 0 44px;
    height: 100%;
    font-size: 20px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: rgba(0, 0, 0, 0.7);
    background-color: #f6c232;
  }
  .form-row .close-button-holder {
    position: relative;
  }
  .form-row .close-button-holder .close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .form-row .close-button-holder .close:before {
    content: "";
    background: #5d5d5d url("../src/assets/img/closebutton.png");
    background-size: cover;
    border-radius: 50%;
    display: block;
    width: 20px;
    height: 20px;
  }
  
  .row-text {
    margin: 0;
    font: 400 16px Lato;
    color: #030404;
  }
  .row-text.row-text-invalid {
    color: #dd2d2e;
  }
  .row-text .asterisk {
    color: #dd2d2e;
  }
  
  .rfb-rows {
    padding-left: 15px;
  }
  
  @media (max-width: 1300px) {
    .rfn-editWeight {
      margin-top: 5px;
      max-width: 600px;
    }
  }
  @media (max-width: 1300px) and (max-width: 550px) {
    .rfn-editWeight {
      align-items: flex-start !important;
    }
  }
  @media (max-width: 1300px) {
    .form-row-rfn {
      flex-direction: column;
    }
    .form-row-rfn .rfn-stats, .form-row-rfn .rfn-fields, .form-row-rfn .rfn-notice, .form-row-rfn .rfn-editWeight {
      flex: 1;
      width: 100%;
    }
    .form-row-rfn .rfn-stats {
      margin: 0 0 5px 0;
      height: 275px;
    }
    .form-row-rfn .rfn-fields {
      justify-content: center;
      height: 275px;
    }
    .form-row-rfn .rfn-fields .rfn-fields-wrapper {
      max-width: 600px;
    }
    .form-row-rfn .rfn-notice {
      margin: 5px 0 0 0;
      height: 200px;
    }
  }
  @media (max-width: 1300px) and (max-width: 1300px) {
    .form-row-rfn .rfn-notice {
      height: 100px;
    }
  }
  @media (max-width: 550px) {
    .form-row {
      display: block;
      margin-bottom: 15px;
    }
    .form-row .row-title {
      width: 100%;
      margin-bottom: 10px;
    }
    .form-row-long .row-title {
      padding-top: 0;
    }
    .form-row .row-content {
      padding-left: 0;
    }
    .form-row .fg-select-container .fg-dropdown__control {
      width: 100%;
    }
    .form-row .fg-select-container .fg-dropdown__input {
      max-width: 100%;
    }
    .form-row .date-btn, .form-row .day-picker-container {
      width: 100%;
    }
    .form-row .day-picker-container .btn {
      width: calc(100% - 30px);
    }
    .form-row .rating {
      padding-top: 0;
      width: 100%;
    }
    .form-row .input, .form-row .textarea {
      width: 100% !important;
    }
  
    .rfb-rows {
      padding-left: 5px;
    }
  }
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  .checkbox-container .handle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .checkbox-container .handle .checked {
    opacity: 0;
  }
  .checkbox-container.green .handle {
    width: 15px;
    height: 15px;
    border: 1px solid #cccccc;
  }
  .checkbox-container.green .handle .checked {
    opacity: 0;
  }
  .checkbox-container.green .handle:hover {
    border-color: #00cc33;
  }
  .checkbox-container.green.active .handle {
    background: #1f9512;
    border-color: #cccccc;
  }
  .checkbox-container.green.active .handle .checked {
    opacity: 1;
  }
  .checkbox-container.disabled-checkbox .handle {
    cursor: initial;
  }
  .checkbox-container.disabled-checkbox .handle:hover {
    border-color: #cccccc;
  }
  .checkbox-container.gray {
    display: inline-block;
  }
  .checkbox-container.gray .handle {
    width: 32px;
    height: 32px;
    background-color: #666666;
    border-radius: 2px;
    transition: all 0.3s linear;
  }
  .checkbox-container.gray .handle .checked {
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  .checkbox-container.gray .handle .checked:after {
    display: block;
    position: relative;
    width: 18px;
    height: 15px;
    content: "";
    background: url("../src/assets/img/icon-tick.png") no-repeat;
    background-size: cover;
  }
  .checkbox-container.gray.active .handle .checked {
    opacity: 1;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .checkbox-with-value-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  .checkbox-with-value-container .handle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .checkbox-with-value-container .handle .checked {
    opacity: 0;
  }
  .checkbox-with-value-container.green .handle {
    width: 24px;
    height: 24px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    font-family: "Lato";
  }
  .checkbox-with-value-container.green .handle .checked {
    opacity: 0;
  }
  .checkbox-with-value-container.green .handle:hover {
    border-color: #00cc33;
  }
  .checkbox-with-value-container.green.active .handle {
    background: #1f9512;
    border-color: #cccccc;
  }
  .checkbox-with-value-container.green.active .handle .checked {
    opacity: 1;
  }
  .checkbox-with-value-container.green.active .handle .checkbox-text {
    color: #ffffff;
  }
  .checkbox-with-value-container.disabled-checkbox .handle {
    cursor: initial;
  }
  .checkbox-with-value-container.disabled-checkbox .handle:hover {
    border-color: #cccccc;
  }
  .checkbox-with-value-container.gray {
    display: inline-block;
  }
  .checkbox-with-value-container.gray .handle {
    width: 32px;
    height: 32px;
    background-color: #666666;
    border-radius: 2px;
    transition: all 0.3s linear;
  }
  .checkbox-with-value-container.gray .handle .checked {
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  .checkbox-with-value-container.gray .handle .checked:after {
    display: block;
    position: relative;
    width: 18px;
    height: 15px;
    content: "";
    background: url("../src/assets/img/icon-tick.png") no-repeat;
    background-size: cover;
  }
  .checkbox-with-value-container.gray.active .handle .checked {
    opacity: 1;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .rating-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rating-item .rating-value {
    padding-top: 2px;
    font: 400 14px Lato;
    color: #030404;
    text-align: center;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .sort-component {
    display: flex;
    margin-top: 20px;
    user-select: none;
  }
  .sort-component .sort-dropdown {
    position: relative;
    margin-right: 10px;
  }
  .sort-component .sort-dropdown .dropdown-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin: 0;
    padding-left: 10px;
    min-width: 240px;
  }
  .sort-component .sort-dropdown .dropdown-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .sort-component .sort-dropdown .dropdown-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .sort-component .sort-dropdown .dropdown-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 550px) {
    .sort-component .sort-dropdown .dropdown-btn {
      width: 100%;
    }
  }
  @media (min-width: 551px) {
    .sort-component .sort-dropdown .dropdown-btn:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  .sort-component .sort-dropdown .dropdown-btn:hover:not(.btn-opened) {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .sort-component .sort-dropdown .dropdown-btn:hover:not(.btn-opened) .icon-wrapper {
    background-color: #fac917;
  }
  .sort-component .sort-dropdown .dropdown-btn .icon-wrapper {
    transition: 200ms;
    color: #5d5d5d;
  }
  @media (min-width: 550px) and (max-width: 900px) {
    .sort-component .sort-dropdown .dropdown-btn .icon-wrapper {
      margin-left: 10px;
    }
  }
  @media (min-width: 550px) {
    .sort-component .sort-dropdown .dropdown-btn-sm {
      max-width: 120px;
    }
    .sort-component .sort-dropdown .dropdown-btn-md {
      max-width: 155px;
    }
    .sort-component .sort-dropdown .dropdown-btn-lg {
      max-width: 230px;
    }
  }
  .sort-component .sort-dropdown .dropdown-btn-active:not(.btn-opened) {
    border-color: #1f9512;
  }
  .sort-component .sort-dropdown .dropdown-btn-active .icon-wrapper, .sort-component .sort-dropdown .dropdown-btn-active:hover .icon-wrapper {
    background-color: #1f9512;
    border-color: #1f9512;
  }
  .sort-component .sort-dropdown .dropdown-btn-active .icon-wrapper > svg, .sort-component .sort-dropdown .dropdown-btn-active .icon-wrapper > img, .sort-component .sort-dropdown .dropdown-btn-active:hover .icon-wrapper > svg, .sort-component .sort-dropdown .dropdown-btn-active:hover .icon-wrapper > img {
    filter: invert(100) brightness(200);
  }
  .sort-component .dropdown-choices {
    display: none;
    position: absolute;
    min-width: 240px;
    z-index: 100;
  }
  .sort-component .dropdown-choices .dropdown-choice {
    display: block;
    background-color: white;
    padding: 15px 5px;
    border: 1px solid #c6c6c6;
    font-family: Lato;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .sort-component .dropdown-choices .dropdown-choice:hover {
    background-color: #e1e1e1;
  }
  .sort-component .dropdown-choices .dropdown-choice:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .sort-component .dropdown-choices .dropdown-choice:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .sort-component .dropdown-choices .dropdown-choice.selected {
    background-color: #cccccc;
  }
  .sort-component .sort-direction-btn {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 44px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #c6c6c6;
    user-select: none;
    cursor: pointer;
  }
  .sort-component .sort-direction-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 22px;
    color: #5d5d5d;
  }
  .sort-component.opened .dropdown-choices {
    display: block;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .select-component {
    display: flex;
    flex-direction: column;
    user-select: none;
  }
  .select-component .hide {
    display: none;
  }
  .select-component .select-items {
    position: absolute;
    margin-top: 42px;
    min-width: 130px;
    z-index: 1;
  }
  .select-component .select-items li:first-child {
    border-radius: 0px 5px 0px 0px;
  }
  .select-component .select-items li:last-child {
    border-radius: 0px 0px 5px 5px;
  }
  .select-component .select-item ~ .select-item {
    border-top: 0;
  }
  .select-component .select-item {
    display: block;
    background-color: #c6c6c6;
    padding: 15px 5px;
    border: 1px solid #5d5d5d;
    font-family: Lato;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .select-component .dropdown-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin: 0;
    padding-left: 10px;
    background-color: #c6c6c6;
    width: 130px;
    overflow: hidden;
  }
  .select-component .dropdown-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .select-component .dropdown-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .select-component .dropdown-btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .select-component .dropdown-btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .select-component .dropdown-btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .select-component .dropdown-btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .select-component .dropdown-btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .select-component .dropdown-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 550px) {
    .select-component .dropdown-btn {
      width: 100%;
    }
  }
  @media (min-width: 551px) {
    .select-component .dropdown-btn:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  .select-component .dropdown-btn:hover:not(.btn-opened) {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .select-component .dropdown-btn:hover:not(.btn-opened) .icon-wrapper {
    background-color: #fac917;
  }
  .select-component .dropdown-btn .icon-wrapper {
    transition: 200ms;
    color: #5d5d5d;
  }
  @media (min-width: 550px) and (max-width: 900px) {
    .select-component .dropdown-btn .icon-wrapper {
      margin-left: 10px;
    }
  }
  @media (min-width: 550px) {
    .select-component .dropdown-btn-sm {
      max-width: 120px;
    }
    .select-component .dropdown-btn-md {
      max-width: 155px;
    }
    .select-component .dropdown-btn-lg {
      max-width: 230px;
    }
  }
  .select-component .dropdown-btn-active:not(.btn-opened) {
    border-color: #1f9512;
  }
  .select-component .dropdown-btn-active .icon-wrapper, .select-component .dropdown-btn-active:hover .icon-wrapper {
    background-color: #1f9512;
    border-color: #1f9512;
  }
  .select-component .dropdown-btn-active .icon-wrapper > svg, .select-component .dropdown-btn-active .icon-wrapper > img, .select-component .dropdown-btn-active:hover .icon-wrapper > svg, .select-component .dropdown-btn-active:hover .icon-wrapper > img {
    filter: invert(100) brightness(200);
  }
  .select-component .dropdown-btn .icon-wrapper {
    color: white;
  }
  .select-component .dropdown-btn.show {
    border-bottom: 0;
    border-radius: 5px 5px 5px 0;
    box-shadow: unset;
    z-index: 900;
  }
  .select-component .dropdown-btn.show:hover {
    box-shadow: none;
  }
  
  .fg-select-container .fg-dropdown-container {
    font: 400 14px Lato;
    color: #666666;
  }
  .fg-select-container .fg-dropdown-container input {
    font: inherit;
  }
  .fg-select-container .fg-dropdown__control {
    height: 44px;
    background-color: #ededed;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
    overflow: hidden;
  }
  .fg-select-container .fg-dropdown__control--is-focused:hover {
    border-color: #c6c6c6;
  }
  .fg-select-container .fg-dropdown__value-container {
    padding-left: 10px;
  }
  .fg-select-container .fg-dropdown__placeholder, .fg-select-container .fg-dropdown__single-value, .fg-select-container .fg-dropdown__input {
    color: #666666;
  }
  .fg-select-container .fg-dropdown__placeholder, .fg-select-container .fg-dropdown__single-value {
    max-width: calc(100% - 30px);
  }
  .fg-select-container .fg-dropdown__input {
    overflow: hidden;
  }
  .fg-select-container .fg-dropdown__indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-left: 1px solid #c6c6c6;
    border-radius: 5px 0 0 5px;
  }
  .fg-select-container .fg-dropdown__clear-indicator {
    display: none;
  }
  .fg-select-container .fg-dropdown__indicator-separator {
    display: none;
  }
  .fg-select-container .fg-dropdown__arrow {
    font-size: 22px;
    color: #5d5d5d;
  }
  .fg-select-container .fg-dropdown__menu {
    margin: 0;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #c6c6c6;
    border-radius: 0 0 5px 5px;
    box-shadow: none;
  }
  .fg-select-container .fg-dropdown__menu-list {
    padding: 0;
  }
  .fg-select-container .fg-dropdown__option {
    margin: 1px 0;
    padding-left: 10px;
    cursor: pointer;
    color: #666;
    background-color: #fff;
  }
  .fg-select-container .fg-dropdown__option:active {
    background-color: rgba(198, 198, 198, 0.5);
  }
  .fg-select-container .fg-dropdown__option--is-focused {
    background-color: rgba(198, 198, 198, 0.3);
  }
  .fg-select-container .fg-dropdown__option--is-selected {
    background-color: rgba(198, 198, 198, 0.7);
  }
  .fg-select-container .warning-text {
    margin: 5px;
    color: red;
    font-family: "Lato";
  }
  .fg-select-container .fg-dropdown-container.red .fg-dropdown__control {
    border-color: red;
  }
  .fg-select-container .fg-dropdown-container.fg-dropdown-custom-input .fg-dropdown__input input {
    opacity: 1 !important;
  }
  .fg-select-container .fg-dropdown-container.fg-dropdown-uppercase-input .fg-dropdown__input input {
    text-transform: uppercase;
  }
  .fg-select-container .fg-dropdown__option--is-selected {
    background-color: #C0C0C0 !important;
    font-weight: 600 !important;
  }
  
  .fg-table-container .ReactTable {
    font-size: 14px;
    font-family: Lato;
    border: none;
  }
  .fg-table-container .ReactTable .rt-thead.-headerGroups {
    background: #666666;
    color: white;
    padding: 8px 11px;
    text-align: left;
  }
  .fg-table-container .ReactTable .rt-tr {
    text-align: left;
  }
  .fg-table-container .ReactTable .rt-thead.-header .rt-th, .fg-table-container .ReactTable .rt-thead.-header .rt-td, .fg-table-container .ReactTable .rt-tfoot .rt-th, .fg-table-container .ReactTable .rt-tfoot .rt-td {
    background: #c6c6c6;
    color: #4d4e4e;
    border: 1px solid #666666;
    margin: 2px 3px 5px 0;
    padding: 13px 0 13px 17px;
    font-weight: bold;
  }
  .fg-table-container .ReactTable .rt-thead.-header .rt-th:last-child, .fg-table-container .ReactTable .rt-thead.-header .rt-td:last-child, .fg-table-container .ReactTable .rt-tfoot .rt-th:last-child, .fg-table-container .ReactTable .rt-tfoot .rt-td:last-child {
    margin-right: 0;
  }
  .fg-table-container .ReactTable .rt-tbody {
    background: white;
    color: #030404;
    padding: 0;
  }
  .fg-table-container .ReactTable .rt-tbody .rt-td {
    background: #ededed;
    border: 1px solid #c6c6c6;
    border-bottom: none;
    border-top: none;
    margin-left: 3px;
    padding-left: 8px;
  }
  .fg-table-container .ReactTable .rt-tbody .rt-td:first-child {
    margin-left: 0;
  }
  .fg-table-container .ReactTable .rt-tbody .rt-tr-group {
    margin-top: -1px;
  }
  .fg-table-container .ReactTable .rt-tbody .rt-tr-group .rt-tr {
    margin-bottom: 3px;
    border: 1px solid #c6c6c6;
  }
  .fg-table-container .ReactTable .rt-tbody .rt-tr-group:first-child {
    margin-top: 0px;
  }
  .fg-table-container .ReactTable .rt-tbody .rt-tr-group:first-child .rt-td {
    padding-top: 17px;
    border-top: 1px solid #c6c6c6;
  }
  .fg-table-container .ReactTable .rt-tbody .rt-tr-group:last-child .rt-td {
    border-bottom: 1px solid #c6c6c6;
    padding-bottom: 17px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .fg-progress-bar {
    position: relative;
    height: 20px;
    width: 100%;
    border: 1px solid #333;
    color: #030404;
    font: 400 14px Lato;
    overflow: hidden;
    user-select: none;
  }
  .fg-progress-bar-filler {
    background-color: #76b6d7;
    height: 100%;
    border-radius: inherit;
    transition: width 0.2s ease-in;
  }
  .fg-progress-bar-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .filter-date-wrapper,
  .filter-multi-wrapper,
  .filter-range-wrapper {
    width: 100%;
    position: relative;
  }
  @media (min-width: 551px) {
    .filter-date-wrapper,
  .filter-multi-wrapper,
  .filter-range-wrapper {
      max-width: 231px;
      margin-right: 10px;
    }
  }
  .filter-date-wrapper .btn-opened,
  .filter-multi-wrapper .btn-opened,
  .filter-range-wrapper .btn-opened {
    position: relative;
    z-index: 900;
    border-color: #000;
    border-radius: 5px;
  }
  @media (min-width: 551px) {
    .filter-date-wrapper .btn-opened,
  .filter-multi-wrapper .btn-opened,
  .filter-range-wrapper .btn-opened {
      border-bottom: 0;
      border-radius: 5px 5px 5px 0;
    }
  }
  .filter-date-wrapper .btn-opened .icon-wrapper,
  .filter-multi-wrapper .btn-opened .icon-wrapper,
  .filter-range-wrapper .btn-opened .icon-wrapper {
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  
  .filter-date-inner,
  .filter-multi-inner,
  .filter-range-inner {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 25px 15px;
    background-color: #fff;
    font-family: Lato;
    border-radius: 5px;
    border: 1px solid #000;
    z-index: 99;
  }
  @media (min-width: 551px) {
    .filter-date-inner,
  .filter-multi-inner,
  .filter-range-inner {
      width: auto;
    }
  }
  @media (min-width: 1300px) {
    .filter-date-inner,
  .filter-multi-inner,
  .filter-range-inner {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .filter-date-inner > div:first-child,
  .filter-multi-inner > div:first-child,
  .filter-range-inner > div:first-child {
    text-align: center;
  }
  @media (min-width: 1300px) {
    .filter-date-inner > div:first-child,
  .filter-multi-inner > div:first-child,
  .filter-range-inner > div:first-child {
      text-align: left;
    }
  }
  .filter-date-inner.calendars-right-aligned,
  .filter-multi-inner.calendars-right-aligned,
  .filter-range-inner.calendars-right-aligned {
    left: auto;
    right: 0;
  }
  @media (min-width: 551px) {
    .filter-date-inner.calendars-right-aligned,
  .filter-multi-inner.calendars-right-aligned,
  .filter-range-inner.calendars-right-aligned {
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
      right: 1px;
    }
  }
  .filter-date-inner h3, .filter-date-inner p,
  .filter-multi-inner h3,
  .filter-multi-inner p,
  .filter-range-inner h3,
  .filter-range-inner p {
    color: #5d5d5d;
    margin: 0;
  }
  .filter-date-inner h3,
  .filter-multi-inner h3,
  .filter-range-inner h3 {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
  }
  .filter-date-inner p,
  .filter-multi-inner p,
  .filter-range-inner p {
    font-size: 14px;
  }
  .filter-date-inner .calendars-wrapper,
  .filter-multi-inner .calendars-wrapper,
  .filter-range-inner .calendars-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media (min-width: 551px) {
    .filter-date-inner .calendars-wrapper,
  .filter-multi-inner .calendars-wrapper,
  .filter-range-inner .calendars-wrapper {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .filter-date-inner .calendars-wrapper.in-column,
  .filter-multi-inner .calendars-wrapper.in-column,
  .filter-range-inner .calendars-wrapper.in-column {
    flex-direction: column;
  }
  
  .calendars-buttons,
  .below-description {
    margin-bottom: 20px;
  }
  
  .range-buttons {
    display: flex;
    justify-content: center;
  }
  .range-buttons .btn:first-child {
    margin-right: 10px;
  }
  
  @media (min-width: 1300px) {
    .filter-date-inner {
      width: 873px;
    }
  }
  .calendars-controls {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 14px;
    color: #5d5d5d;
  }
  
  .calendars-controls label,
  .filter-multi-options label {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
  }
  .calendars-controls label span,
  .filter-multi-options label span {
    cursor: pointer;
  }
  .calendars-controls .filter-checkbox,
  .filter-multi-options .filter-checkbox {
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #000;
  }
  .calendars-controls .filter-checkbox.active,
  .filter-multi-options .filter-checkbox.active {
    background-color: #1f9512;
  }
  .calendars-controls input,
  .filter-multi-options input {
    display: none;
  }
  
  .DayPicker-wrapper {
    font-family: Lato;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role=gridcell] .DayPicker-Filled {
    border: 1px solid #464646;
    border-right: 0;
    border-bottom: 0;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role=gridcell]:last-of-type .DayPicker-Filled {
    border-right: 1px solid #464646;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:first-child .DayPicker-Day:not([role=gridcell]) + .DayPicker-Day .DayPicker-Filled {
    border-top-left-radius: 5px;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:first-child .DayPicker-Day[role=gridcell]:first-of-type .DayPicker-Filled {
    border-top-left-radius: 5px;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:first-child .DayPicker-Day[role=gridcell]:last-of-type .DayPicker-Filled {
    border-right: 1px solid #464646;
    border-top-right-radius: 5px;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:last-child .DayPicker-Day[role=gridcell] .DayPicker-Filled {
    border-bottom: 1px solid #464646;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:last-child .DayPicker-Day[role=gridcell]:first-of-type .DayPicker-Filled {
    border-bottom-left-radius: 5px;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:last-child .DayPicker-Day[role=gridcell]:last-of-type .DayPicker-Filled {
    border-right: 1px solid #464646;
    border-bottom-right-radius: 5px;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role=gridcell] {
    position: relative;
    padding: 0;
    height: 33px;
    width: 33px;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role=gridcell] .DayPicker-Filled {
    display: flex;
    width: 100%;
    height: 33px;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role=gridcell] .DayPicker-Filled.today {
    color: white;
    background-color: green;
    font-weight: bold;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day--today {
    font-weight: inherit;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day--today:not(.DayPicker-Day--disabled) {
    color: inherit;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover .DayPicker-Filled {
    background-color: #fac917;
  }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) .DayPicker-Filled {
    background-color: #fac917;
    color: #fff;
  }
  
  .DayPicker-Caption div {
    text-align: center;
  }
  
  .DayPicker-wrapper .DayPicker-NavButton {
    height: 30px;
    width: 30px;
    top: 8px;
    border-radius: 3px;
    border: 1px solid #000;
    background-color: #c6c6c6;
    color: #fff;
  }
  .DayPicker-wrapper .DayPicker-NavButton--prev {
    left: 1rem;
    right: auto;
    margin-right: 0;
    background-image: url(../src/assets/img/arrow-left.png);
  }
  .DayPicker-wrapper .DayPicker-NavButton--next {
    right: 1rem;
    background-image: url(../src/assets/img/arrow-right.png);
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  @media (min-width: 551px) {
    .filter-multi-wrapper-sm,
  .filter-range-wrapper-sm {
      max-width: 120px;
    }
    .filter-multi-wrapper-md,
  .filter-range-wrapper-md {
      max-width: 155px;
    }
    .filter-multi-wrapper-lg,
  .filter-range-wrapper-lg {
      max-width: 230px;
    }
  }
  
  .filter-multi-inner {
    min-width: 100%;
    flex-direction: column !important;
  }
  .filter-multi-inner.overflow {
    max-width: 1200px;
    max-height: 500px;
    overflow: scroll;
    display: block;
  }
  @media screen and (max-width: 1300px) {
    .filter-multi-inner.overflow {
      min-width: 900px;
    }
  }
  @media screen and (max-width: 1010px) {
    .filter-multi-inner.overflow {
      min-width: 700px;
    }
  }
  @media screen and (max-width: 750px) {
    .filter-multi-inner.overflow {
      min-width: 400px;
    }
  }
  @media screen and (max-width: 420px) {
    .filter-multi-inner.overflow {
      min-width: 100%;
    }
  }
  @media (min-width: 550px) {
    .filter-multi-inner {
      border-top-left-radius: 0;
    }
  }
  .filter-multi-inner-right {
    left: auto;
    right: 0;
  }
  @media (min-width: 550px) {
    .filter-multi-inner-right {
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
    }
  }
  @media (min-width: 1300px) {
    .filter-multi-inner {
      min-width: auto;
    }
  }
  .filter-multi-inner .filter-multi-options {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 1300px) {
    .filter-multi-inner .filter-multi-options {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .filter-multi-inner .filter-multi-buttons {
    display: flex;
    justify-content: center;
  }
  .filter-multi-inner ul {
    display: block;
    flex: 1;
    min-width: 200px;
    flex-direction: column;
  }
  @media (min-width: 1300px) {
    .filter-multi-inner ul:not(:last-child) {
      margin-right: 25px;
    }
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .filter-range-inner {
    flex-direction: column;
    width: 80vw;
    max-width: 824px;
  }
  .filter-range-inner.in-column {
    width: auto;
  }
  @media (max-width: 550px) {
    .filter-range-inner.in-column {
      width: 100%;
    }
  }
  @media (min-width: 550px) {
    .filter-range-inner {
      border-top-left-radius: 0;
    }
  }
  .filter-range-inner-right {
    left: auto;
    right: 0;
  }
  @media (min-width: 550px) {
    .filter-range-inner-right {
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
    }
  }
  .filter-range-inner .slider-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 20px 0 30px;
    justify-content: center;
    align-items: center;
  }
  .filter-range-inner .slider-wrapper.in-column {
    flex-direction: column;
  }
  .filter-range-inner .slider-wrapper span {
    width: 120px;
    cursor: text;
    min-width: 110px;
  }
  .filter-range-inner .slider-wrapper span.slider-counter {
    max-width: 150px;
    display: inline-block;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 15px;
    border-radius: 5px;
    border: 1px solid #464646;
  }
  .filter-range-inner .slider-wrapper span.slider-counter-left {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .filter-range-inner .slider-wrapper span.slider-counter-left.in-column {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .filter-range-inner .slider-wrapper span.slider-counter-right {
    margin-top: 0;
    margin-left: 10px;
  }
  .filter-range-inner .slider-wrapper span.slider-counter-right.in-column {
    margin-top: 20px;
    margin-left: 0;
  }
  .filter-range-inner .slider-wrapper span input[type=number]::-webkit-inner-spin-button,
  .filter-range-inner .slider-wrapper span input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .filter-range-inner .slider-wrapper span input.weightFilter {
    width: 52%;
    min-width: 40px;
    float: left;
    margin-right: 3px;
    text-align: right;
    font-family: Lato;
    font-size: 17px;
    border-width: 0;
    color: #5d5d5d;
  }
  .filter-range-inner .slider-wrapper span input.weightSecondField {
    width: 52%;
    min-width: 56px;
    float: left;
    text-align: right;
    font-family: Lato;
    font-size: 17px;
    border-width: 0;
    color: #5d5d5d;
  }
  .filter-range-inner .slider-wrapper span .text-kg {
    font-family: Lato;
    color: #5d5d5d;
    font-size: 17px;
    text-align: left;
  }
  .filter-range-inner .rc-slider {
    flex: 1;
    max-width: 80%;
    margin: -7px 25px 0 25px;
  }
  .filter-range-inner .rc-slider-rail {
    height: 12px;
    background-color: #fff;
    border: 1px solid #000;
  }
  .filter-range-inner .rc-slider-track {
    height: 12px;
    background-color: #1f9512;
    border: 1px solid #000;
  }
  .filter-range-inner .rc-slider-handle {
    width: 40px;
    height: 24px;
    margin-top: -5px;
    margin-left: -20px;
    border-radius: 5px;
    border: 1px solid #000;
    background-color: #c6c6c6;
    transform: none !important;
  }
  .filter-range-inner .rc-slider-handle-1:after, .filter-range-inner .rc-slider-handle-2:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #000;
  }
  .filter-range-inner .rc-slider-handle-1:after {
    content: "Min.";
  }
  .filter-range-inner .rc-slider-handle-2:after {
    content: "Max.";
  }
  
  .slider-wrapper {
    overflow: hidden;
  }
  .slider-wrapper.age {
    flex-direction: column;
  }
  .slider-wrapper.age .slider-wrapper-left, .slider-wrapper.age .slider-wrapper-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .slider-wrapper.age .slider-wrapper-left .slider-counter-age, .slider-wrapper.age .slider-wrapper-right .slider-counter-age {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
  }
  .slider-wrapper.age .slider-wrapper-left .slider-counter-age .inputAge, .slider-wrapper.age .slider-wrapper-right .slider-counter-age .inputAge {
    text-align: center;
  }
  .slider-wrapper.age .slider-wrapper-left .slider-counter-age .text-mounth, .slider-wrapper.age .slider-wrapper-right .slider-counter-age .text-mounth {
    width: 100%;
    min-width: auto;
    text-align: center;
  }
  
  .slider-wrapper .range-age {
    margin-top: 15px;
    margin-bottom: 25px;
  }
  
  .slider-wrapper .slider-wrapper-left,
  .slider-wrapper .slider-wrapper-right {
    display: flex;
    flex: 0.3;
    margin-bottom: 10px;
  }
  .slider-wrapper .slider-wrapper-left .slider-text,
  .slider-wrapper .slider-wrapper-right .slider-text {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #5d5d5d;
    font-size: 18px;
  }
  .slider-wrapper .slider-wrapper-left span,
  .slider-wrapper .slider-wrapper-right span {
    width: 100%;
    cursor: text;
    min-width: 110px;
  }
  .slider-wrapper .slider-wrapper-left span.slider-counter-age:not(:root:root),
  .slider-wrapper .slider-wrapper-right span.slider-counter-age:not(:root:root) {
    line-height: 21px;
  }
  .slider-wrapper .slider-wrapper-left span.slider-counter-age,
  .slider-wrapper .slider-wrapper-right span.slider-counter-age {
    flex-direction: row;
    max-width: 75px;
    height: 60px;
    align-items: stretch;
    display: block;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: 1px solid #464646;
  }
  .slider-wrapper .slider-wrapper-left span.slider-counter-age-left,
  .slider-wrapper .slider-wrapper-right span.slider-counter-age-left {
    margin-bottom: 0;
    margin-right: 0;
  }
  .slider-wrapper .slider-wrapper-left span.slider-counter-age-left.in-column,
  .slider-wrapper .slider-wrapper-right span.slider-counter-age-left.in-column {
    margin-bottom: 0;
    margin-right: 0;
  }
  .slider-wrapper .slider-wrapper-left span.slider-counter-age-right,
  .slider-wrapper .slider-wrapper-right span.slider-counter-age-right {
    margin-top: 0;
    margin-left: 0;
  }
  .slider-wrapper .slider-wrapper-left span.slider-counter-age-right.in-column,
  .slider-wrapper .slider-wrapper-right span.slider-counter-age-right.in-column {
    margin-top: 0;
    margin-left: 0;
  }
  .slider-wrapper .slider-wrapper-left span input,
  .slider-wrapper .slider-wrapper-right span input {
    width: 40%;
    min-width: 40px;
    margin-right: 3px;
    float: left;
    border-width: 0;
    color: #5d5d5d;
    font-size: 18px;
    text-align: right;
  }
  .slider-wrapper .slider-wrapper-left span .text-mounth,
  .slider-wrapper .slider-wrapper-right span .text-mounth {
    font-family: Lato;
    color: #5d5d5d;
    font-size: 18px;
    text-align: left;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .add-lot-wrapper .top-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .add-lot-wrapper .top-header .left, .add-lot-wrapper .top-header .right {
    margin-bottom: 20px;
  }
  .add-lot-wrapper .top-header .right {
    margin-left: 40px;
  }
  @media (max-width: 550px) {
    .add-lot-wrapper .top-header .right {
      width: 150px;
      height: 100px;
      margin-left: 5px;
    }
  }
  .add-lot-wrapper .top-header .link {
    color: blue;
  }
  .add-lot-wrapper .top-header .auction-title {
    color: #030404;
    font-family: Lato;
    font-size: 40px;
    font-weight: 400;
    margin: 0 0 20px 0;
    margin-bottom: 10px;
  }
  @media (max-width: 550px) {
    .add-lot-wrapper .top-header .auction-title {
      font-size: 28px;
    }
  }
  .add-lot-wrapper .top-header .auction-subtitle {
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 300;
    color: #545757;
    font-family: "Lato";
  }
  .add-lot-wrapper .top-header .auction-live-date, .add-lot-wrapper .top-header .auction-closed-date {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    margin: 0 0 20px 0;
  }
  @media (max-width: 550px) {
    .add-lot-wrapper .top-header .auction-live-date, .add-lot-wrapper .top-header .auction-closed-date {
      font-size: 16px;
    }
  }
  .add-lot-wrapper .top-header .auction-desc {
    margin: 0;
    color: #030404;
    font: 400 16px Lato;
    white-space: break-spaces;
  }
  .add-lot-wrapper .top-header .auction-logo img {
    max-width: 250px;
    max-height: 125px;
  }
  @media (max-width: 550px) {
    .add-lot-wrapper .top-header .auction-logo img {
      object-fit: contain;
      width: 150px;
      height: 100px;
    }
  }
  .add-lot-wrapper .lot-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .add-lot-wrapper .admin-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (max-width: 550px) {
    .add-lot-wrapper .admin-controls label {
      width: 100%;
    }
  }
  .add-lot-wrapper .admin-controls input[type=file], .add-lot-wrapper .admin-controls a {
    display: none;
  }
  .add-lot-wrapper .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin: 0 10px 10px 0;
  }
  .add-lot-wrapper .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .add-lot-wrapper .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .add-lot-wrapper .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .add-lot-wrapper .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .add-lot-wrapper .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .add-lot-wrapper .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .add-lot-wrapper .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  @media (max-width: 550px) {
    .add-lot-wrapper .btn {
      margin-right: 0;
      width: 100%;
    }
  }
  .add-lot-wrapper .fields-container {
    position: relative;
  }
  .add-lot-wrapper .fields-container.disabled:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
    z-index: 10;
  }
  .add-lot-wrapper .save-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 10px;
    background-color: white;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    transition: top 0.25s;
    z-index: 500;
    border: 2px solid black;
    border-radius: 5px;
  }
  .add-lot-wrapper .save-notification.show {
    top: 20px;
  }
  .add-lot-wrapper .save-notification .save-notice {
    font-family: Lato;
    font-size: 18px;
    color: black;
  }
  .add-lot-wrapper .asterisk {
    color: #dd2d2e;
  }
  .add-lot-wrapper .notice {
    margin: 25px 0 20px 0;
    color: #dd2d2e;
    font: 400 16px Lato;
  }
  .add-lot-wrapper .lot-form {
    margin: 0 50px;
    overflow: hidden;
  }
  @media (max-width: 900px) {
    .add-lot-wrapper .lot-form {
      margin: 0;
    }
  }
  .add-lot-wrapper .lot-form .input-section .section-body .carousel-wrap .media-carousel .media-wrap .media-area {
    display: inline-flex;
  }
  .add-lot-wrapper .input-section {
    margin-bottom: 20px;
  }
  .add-lot-wrapper .input-section .section-heading {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 5px 5px 0 0;
    padding-left: 15px;
    height: 45px;
    background-color: #666666;
  }
  .add-lot-wrapper .input-section .section-heading .heading-title {
    margin: 0;
    font: 400 14px Lato;
    color: #fff;
  }
  .add-lot-wrapper .input-section .section-body {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 0 0 5px 5px;
    border: 1px solid #c6c6c6;
    padding: 15px;
  }
  .add-lot-wrapper .input-section-first {
    margin-bottom: 5px;
  }
  .add-lot-wrapper .input-section-first .section-body {
    border-radius: 0;
  }
  .add-lot-wrapper .input-section-documents .form-row {
    display: flex;
  }
  .add-lot-wrapper .input-section-documents .form-row .row-title {
    width: initial;
    min-width: 260px;
  }
  .add-lot-wrapper .input-section-documents .form-row .row-content {
    padding: 0 10px;
    display: flex;
    flex: 1;
  }
  .add-lot-wrapper .input-section-documents .form-row .row-content > * {
    margin: 0 4px;
  }
  .add-lot-wrapper .input-section-documents .file-label {
    display: flex;
    align-items: center;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    padding: 0 25px;
    height: 44px;
    font: 400 14px Lato;
    color: #666666;
    background-color: #ededed;
    cursor: pointer;
  }
  .add-lot-wrapper .input-section-documents .documents-text-wrapper {
    width: 270px;
  }
  .add-lot-wrapper .input-section-documents input[type=file] {
    display: none;
  }
  .add-lot-wrapper .input-section-documents .doc-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 5px;
    color: #fff;
    font-size: 26px;
    transition: box-shadow 300ms;
    cursor: pointer;
  }
  .add-lot-wrapper .input-section-documents .doc-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .add-lot-wrapper .input-section-documents .doc-btn-add {
    background-color: #1f9512;
  }
  .add-lot-wrapper .input-section-documents .doc-btn-remove {
    background-color: #dd2d2e;
  }
  .add-lot-wrapper .inner-section-vendorbid {
    margin-top: 20px;
  }
  .add-lot-wrapper .inner-section-vendorbid .checkboxes {
    margin: 15px 25px 5px 25px;
    padding: 15px 20px 10px 20px;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    background-color: #ededed;
  }
  .add-lot-wrapper .inner-section-vendorbid .checkboxes .form-row .inner-line {
    margin: 15px 0;
    display: flex;
    flex-direction: row;
  }
  .add-lot-wrapper .inner-section-vendorbid .checkboxes .form-row .inner-line .row-text {
    color: #666666;
    margin: 0 15px;
  }
  @media (max-width: 1300px) {
    .add-lot-wrapper .input-section-documents .form-row .row-title {
      min-width: 150px;
    }
    .add-lot-wrapper .input-section-documents .documents-text-wrapper {
      width: 180px;
    }
  }
  @media (max-width: 900px) {
    .add-lot-wrapper .input-section-documents .form-row {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;
    }
    .add-lot-wrapper .input-section-documents .form-row .row-title {
      margin-bottom: 10px;
      min-width: 100%;
      text-align: center;
    }
    .add-lot-wrapper .input-section-documents .form-row .row-content {
      flex-wrap: wrap;
    }
    .add-lot-wrapper .input-section-documents .form-row .row-content > * {
      margin: 0 2px;
    }
    .add-lot-wrapper .input-section-documents .file-label {
      width: 150px;
    }
  }
  @media (max-width: 550px) {
    .add-lot-wrapper .input-section-documents .form-row .row-content {
      width: 100%;
    }
    .add-lot-wrapper .input-section-documents .form-row .row-content > * {
      margin: 4px;
    }
    .add-lot-wrapper .input-section-documents .documents-text-wrapper {
      width: 100%;
    }
    .add-lot-wrapper .inner-section-vendorbid .checkboxes {
      margin-left: 0;
      margin-right: 0;
    }
    .add-lot-wrapper .row-text-full {
      margin-bottom: 20px;
    }
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .auth-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: stretch;
    font-family: Lato;
  }
  .auth-wrapper .red-input {
    color: red !important;
  }
  .auth-wrapper .header-wrap .right {
    display: none;
  }
  .auth-wrapper.forgot .background-wrapper {
    height: 100%;
  }
  .auth-wrapper .background-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0;
    background-image: url("../src/assets/img/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .auth-wrapper a {
    text-decoration: none;
  }
  .auth-wrapper .background-wrapper {
    align-items: flex-start;
  }
  .auth-wrapper .background-wrapper .content {
    max-width: 600px;
    width: 100%;
    margin: 55px 15px 15px 15px;
  }
  @media (max-height: 768px) {
    .auth-wrapper .background-wrapper .content {
      margin: 10px 15px 5px 15px;
    }
  }
  .auth-wrapper .background-wrapper .content .text-header {
    align-self: flex-start;
    color: #030404;
    font-weight: 400;
  }
  .auth-wrapper .background-wrapper .content .text-header .main-header {
    font-size: 40px;
  }
  .auth-wrapper .background-wrapper .content .text-header .sub-header {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .auth-wrapper .background-wrapper .content .form-wrapper {
    width: 100%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 35px 100px;
    margin-top: 10px;
  }
  @media (max-width: 550px) {
    .auth-wrapper .background-wrapper .content .form-wrapper {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  .auth-wrapper .background-wrapper .content .form-wrapper .input-container .input {
    max-width: 445px;
    width: 100%;
    display: block;
    height: 45px;
    border-radius: 5px;
    background-color: #666666;
    color: #ffffff;
    font: 100 18px Lato;
    line-height: 30px;
    transition: background-color 0.5s ease;
    padding-left: 15px;
    border: none;
  }
  .auth-wrapper .background-wrapper .content .form-wrapper .input-container .input.less-padding {
    padding: 10px 10px;
  }
  .auth-wrapper .background-wrapper .content .form-wrapper .input-container .input:focus {
    background-color: rgba(102, 102, 102, 0.8);
  }
  .auth-wrapper .background-wrapper .content .form-wrapper .input-container .input::placeholder {
    color: #ffffff;
  }
  .auth-wrapper .background-wrapper .content .warning-text {
    color: red;
    position: absolute;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .top-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .top-line .new {
    color: #000000;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .top-line .nav-link {
    color: #1f9512;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .input-container.email {
    margin: 30px 0 35px 0;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .input-container.password {
    margin-bottom: 35px;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: 12px 15px;
    border-radius: 2px;
    background-color: #1f9512;
    border: none;
    color: #ffffff;
    font: 400 18px Lato;
    text-transform: uppercase;
    transition: background-color 0.2s linear;
    text-decoration: none;
    text-align: center;
    margin: 60px 0 25px 0;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .login-btn:hover {
    background-color: #25b215;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .forgot-link-wrapper {
    margin-bottom: 10px;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .remember-label, .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .forgot-link {
    color: #000000;
    font-size: 16px;
  }
  .auth-wrapper.sign-in .background-wrapper .content .form-wrapper .remember-label {
    padding-left: 12px;
  }
  .auth-wrapper.sign-up .top-info {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
  }
  .auth-wrapper.sign-up .choose-option {
    margin: 50px 0 25px 0;
    color: #000000;
    font-size: 18px;
    font-weight: 700;
  }
  .auth-wrapper.sign-up .option {
    margin-bottom: 30px;
  }
  .auth-wrapper.sign-up .option .option-title {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
  }
  .auth-wrapper.sign-up .option .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: 12px 15px;
    border-radius: 2px;
    background-color: #1f9512;
    border: none;
    color: #ffffff;
    font: 400 18px Lato;
    text-transform: uppercase;
    transition: background-color 0.2s linear;
    text-decoration: none;
    text-align: center;
    margin-top: 5px;
  }
  .auth-wrapper.sign-up .option .nav-link:hover {
    background-color: #25b215;
  }
  .auth-wrapper.sign-up .login-line {
    text-align: right;
    font-size: 18px;
    font-weight: 700;
  }
  .auth-wrapper.sign-up .login-line .already-registered {
    color: #000000;
  }
  .auth-wrapper.sign-up .login-line .sign-in-link {
    color: #1f9512;
    padding-left: 5px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    padding: 10px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form.form-agent-step3 {
    min-height: 925px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form.form-buyer-step3 {
    min-height: 1150px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .warning-text {
    margin: 5px 0 0 0;
    font: 400 13px Lato;
    line-height: 14px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form input[type=file] {
    margin-bottom: 10px;
    color: #fff;
    font-family: Lato;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form textarea {
    max-width: 445px;
    width: 100%;
    display: block;
    margin: 0 auto 10px;
    height: 130px;
    min-height: 130px;
    resize: vertical;
    border: none;
    border-radius: 5px;
    background-color: #666666;
    color: #ffffff;
    font-family: Lato;
    font-size: 18px;
    font-weight: 100;
    line-height: 20px;
    transition: background-color 0.5s ease;
    padding: 10px 15px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form textarea.sale-conditions {
    margin-top: 5px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form textarea::placeholder {
    color: #ffffff;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form textarea:focus {
    background-color: rgba(102, 102, 102, 0.8);
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form p {
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 10px 0;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form p.info-danger {
    color: #dd2d2e;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form p.justify {
    text-align: justify;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .helper-text {
    font: 400 16px "Myriad Pro";
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .text-lg {
    font: 400 18px Lato;
    margin: 10px 0;
    padding-left: 10px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .increased-height .input-container {
    min-height: 100px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .auth-checkbox {
    color: #fff;
    margin-top: 5px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .auth-checkbox .auth-checkbox-label {
    font-size: 16px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .auth-checkbox .auth-checkbox-label.padded-left {
    padding-left: 15px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .auth-checkbox .auth-checkbox-label.seller-type {
    font-size: 18px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .auth-checkbox .auth-checkbox-label.terms .link-terms {
    font-weight: 700;
    color: #000000;
    text-decoration: underline;
    white-space: pre;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .input-container {
    margin-top: 5px;
    min-height: 75px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .input-container.input-hidden {
    display: none;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container {
    margin-top: 5px;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown-container {
    font: 100 18px Lato;
    color: #fff;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown-container input {
    font: inherit;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown--is-disabled {
    opacity: 0.6;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__control {
    border: 0;
    height: 45px;
    background-color: #666666;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__placeholder, .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__single-value, .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__input {
    color: #fff;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__arrow {
    color: #fff;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__menu {
    border: 0;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__option {
    margin: 1px 0;
    color: #fff;
    background-color: #666666;
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__option:active {
    background-color: rgba(50, 50, 50, 0.5);
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__option--is-focused {
    background-color: rgba(50, 50, 50, 0.6);
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .fg-select-container .fg-dropdown__option--is-selected {
    background-color: rgba(50, 50, 50, 0.4);
  }
  .auth-wrapper.registration .background-wrapper .content .form-wrapper form .stick-bottom {
    margin-top: auto;
  }
  .auth-wrapper.change-forgotten a {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    opacity: 1;
  }
  .auth-wrapper.change-forgotten a.nav-link {
    line-height: 30px;
  }
  .auth-wrapper.change-forgotten a.contact-info {
    white-space: pre;
  }
  .auth-wrapper.change-forgotten .forgot {
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.5s linear;
  }
  .auth-wrapper.change-forgotten .forgot:hover {
    color: white;
  }
  
  .auth-columns {
    display: flex;
    flex-direction: row;
  }
  .auth-columns.margin-bottom {
    margin-bottom: 35px;
  }
  .auth-columns.large .input-container {
    min-height: 115px !important;
  }
  .auth-columns > * {
    flex: 1;
    margin-left: 15px;
  }
  .auth-columns > *:first-child {
    margin-left: 0;
  }
  .auth-columns.wrap {
    flex-wrap: wrap;
  }
  
  .choices-wrapper.agency {
    margin: 40px 0;
  }
  
  .auth-pagination {
    display: flex;
    margin-top: 20px;
  }
  .auth-pagination.no-margin {
    margin: 0;
  }
  .auth-pagination-button-right {
    justify-content: flex-end;
  }
  .auth-pagination .auth-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 197px;
    height: 45px;
    border-radius: 3px;
    border: 1px solid #1f9512;
    transition: 400ms;
    cursor: pointer;
  }
  .auth-pagination .auth-button-success {
    background-color: #1f9512;
  }
  .auth-pagination .auth-button-success:hover {
    background-color: #2daf1f;
  }
  .auth-pagination .auth-button-success:disabled {
    background-color: #666;
  }
  .auth-pagination .auth-button-alert {
    background-color: #fac917;
  }
  .auth-pagination .auth-button-alert:hover {
    background-color: #ebb111;
  }
  .auth-pagination .auth-button.btn-text-centered span {
    padding: 0;
    text-align: center;
  }
  .auth-pagination .auth-button span {
    flex: 1;
    color: #fff;
    text-align: left;
    padding-left: 12px;
    font-family: "Myriad Pro";
    font-size: 18px;
    font-weight: 400;
  }
  .auth-pagination .auth-button i {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    width: 43px;
    border-radius: 3px;
  }
  .auth-pagination .auth-button i.btn-right {
    border-left: 1px solid #000;
  }
  .auth-pagination .auth-button i.btn-left {
    border-right: 1px solid #000;
  }
  .auth-pagination .auth-button i svg {
    font-size: 38px;
    color: #fff;
  }
  
  .auth-file-upload {
    display: flex;
    justify-content: space-between;
    max-width: 445px;
    width: 100%;
    margin: 5px auto 0;
    height: 45px;
    border: 1px solid #000;
    border-radius: 3px 2px 2px 3px;
    background-color: #666666;
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 42px;
    transition: background-color 0.5s ease;
    padding-left: 15px;
  }
  .auth-file-upload.margin-bottom {
    margin-bottom: 20px;
  }
  .auth-file-upload:focus {
    background-color: rgba(102, 102, 102, 0.8);
  }
  .auth-file-upload input {
    display: none;
  }
  .auth-file-upload .auth-upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    width: 45px;
    font-size: 26px;
    border-radius: 3px;
    border-left: 1px solid #000;
    background-color: #1f9512;
  }
  .auth-file-upload .auth-upload-button-danger {
    background-color: #dd2d2e;
  }
  
  .input-container {
    width: 100%;
    position: relative;
  }
  .input-container .input {
    width: 100%;
    padding: 10px 15px;
    font: 20px Lato;
    line-height: 23px;
    border: none;
    outline: none;
    -moz-appearance: none;
  }
  .input-container .input.uppercase-text {
    text-transform: uppercase;
  }
  .input-container .input[disabled] {
    opacity: 0.6;
  }
  
  .show-password-button {
    color: #fff;
    background: #666666;
    border: 1px solid #fff;
    padding: 3px;
    border-radius: 4px;
    position: absolute;
    right: 5px;
    top: 10px;
    outline: none;
    user-select: none;
  }
  
  .show-password-button:hover {
    cursor: pointer;
  }
  
  .show-password-button:focus {
    box-shadow: none;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .live {
    -webkit-overflow-scrolling: touch;
    position: relative;
    background: #cccccc;
    max-height: 100%;
  }
  .live .live-navbar {
    z-index: 200;
  }
  @media (max-width: 900px) {
    .live.auction-live {
      overflow: auto;
    }
    .live.auction-live.show-mobile {
      overflow: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
    .live.fixed .live-navbar {
      position: fixed;
      top: -60px;
    }
    .live.fixed .live-navbar .tab {
      height: 40px;
      margin-top: 15px;
    }
  }
  @media (max-width: 550px) {
    .live.fixed .live-navbar {
      position: fixed;
      top: -52px;
    }
    .live.fixed .live-navbar .tab {
      height: 51px;
      margin-top: 8px;
    }
  }
  @media (max-width: 400px) {
    .live .live-navbar .tab {
      padding: 10px 9px 20px 3px !important;
    }
  }
  .live .popup-closing-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.6);
    z-index: 300;
  }
  .live .no-lot {
    background: #ededed !important;
  }
  .live .live-app-header {
    padding-left: 20px;
    border-bottom: 1px solid gray;
  }
  @media (max-width: 400px) {
    .live .live-app-header .header-wrap {
      flex-direction: column;
    }
    .live .live-app-header .header-wrap .logo::before {
      background-position: center;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  @media (max-width: 900px) {
    .live .live-app-header .header-wrap .right {
      display: initial;
    }
  }
  .live .toggle-pictures {
    display: flex;
    align-items: center;
    font: 400 16px "Lato";
    color: #cccccc;
    margin-right: 5px;
  }
  @media (max-width: 900px) {
    .live .toggle-pictures {
      align-self: flex-end;
      margin-right: 20px;
    }
  }
  .live .toggle-pictures .toggle-item {
    display: flex;
    align-items: center;
  }
  .live .toggle-pictures .toggle-item:first-child {
    margin-right: 50px;
  }
  @media screen and (max-width: 550px) {
    .live .toggle-pictures .toggle-item {
      font-size: 12px;
    }
  }
  .live .toggle-pictures .react-toggle {
    margin: 0 10px;
  }
  .live .toggle-pictures .grid-row {
    flex-direction: row;
    margin-right: 10px;
    display: flex;
    width: auto;
  }
  .live .toggle-pictures .grid-row .grid-icon {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .live .toggle-pictures .grid-row .grid-icon.active {
    color: #19AB27;
  }
  .live .navbar-wrap {
    overflow: visible;
    max-width: unset;
  }
  @media (max-width: 900px) {
    .live .navbar-wrap {
      height: 111px;
    }
  }
  @media (max-width: 550px) {
    .live .navbar-wrap {
      height: 100px;
    }
  }
  .live .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  @media (max-width: 900px) {
    .live .navbar {
      flex-direction: column-reverse;
    }
  }
  .live .navbar .navbar-tabs {
    display: flex;
    max-height: 59px;
  }
  @media (max-width: 750px) {
    .live .navbar .navbar-tabs {
      font-size: 11px;
    }
  }
  .live .navbar .tab {
    display: inline-block;
    margin: 0 5px;
    padding: 10px 15px 20px 15px;
    border-radius: 5px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    color: #aeaeae;
    cursor: pointer;
  }
  @media (max-width: 900px) {
    .live .navbar .tab {
      padding: 10px 10px 20px 10px;
    }
  }
  @media (max-width: 550px) {
    .live .navbar .tab {
      padding: 10px 5px 20px 4px;
      margin: 0;
    }
  }
  .live .navbar .tab:hover {
    color: #ffffff;
  }
  .live .navbar .tab.home {
    display: flex;
    margin-top: 10px;
  }
  .live .navbar .tab svg {
    margin-right: 5px;
  }
  .live .live-auction {
    position: relative;
    min-height: 1200px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .live .live-auction .button-close-info {
    display: none;
    flex-flow: row;
    justify-content: flex-start;
    background: #fff;
  }
  .live .live-auction .button-close-info .button-text {
    background: url("../src/assets/img/back-arrow.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 34px;
    margin-left: 20px;
    margin-top: 13px;
  }
  .live .live-auction .button-close-info .button-text:hover {
    opacity: 1;
  }
  .live .live-auction .single-lot-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 430px;
    height: calc(100% - 180px);
    top: 180px;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  @media (max-width: 900px) {
    .live .live-auction .single-lot-container {
      width: 100%;
      height: auto;
    }
  }
  .live .live-auction .single-lot-container.details-popup {
    overflow-y: hidden;
  }
  .live .live-auction .single-lot-container.details-popup .info-container {
    overflow-y: scroll;
  }
  @media (min-width: 900px) {
    .live .live-auction .single-lot-container.details-popup {
      top: 60px !important;
      right: 15% !important;
      margin: auto;
      z-index: 400;
      max-width: 100%;
      width: 70% !important;
    }
    .live .live-auction .single-lot-container.details-popup .bidding-container {
      display: none;
    }
    .live .live-auction .single-lot-container.details-popup .watch-container {
      display: none;
    }
  }
  .live .live-auction .single-lot-container .info-container {
    margin-top: 5px;
    flex-grow: 2;
    background: #fff;
  }
  .live .live-auction .single-lot-container .watch-container {
    padding: 5px;
    margin-top: 5px;
    background: #ffffff;
    border-radius: 5px;
    min-height: 65px;
  }
  @media (max-width: 900px) {
    .live .live-auction .single-lot-container .watch-container {
      margin-bottom: 10px;
    }
  }
  .live .live-auction .single-lot-container .watch-container.no-lot {
    background: #ededed;
  }
  .live .live-auction .single-lot-container .watch-container .watching-btn {
    background: #2e2e2e;
    color: #fff;
    width: 100%;
    border: 0;
    text-transform: uppercase;
    font-size: 18px;
    height: 55px;
  }
  .live .live-auction .single-lot-container .watch-container .watching-btn.active {
    background-color: #007fc1;
  }
  .live .live-auction .single-lot-container .watch-container .watching-btn .icon-wrapper {
    background: transparent;
    border-left: 0;
  }
  .live .live-auction .single-lot-container .watch-container .watching-btn .icon-wrapper svg {
    color: #ffffff;
    height: 34px;
    width: 28px;
  }
  .live .live-auction .no-items {
    margin: auto;
    font: 400 16px "Lato";
    color: #666;
  }
  .live .live-auction .mobileStatus {
    display: none;
  }
  @media (max-width: 900px) {
    .live .live-auction.show-mobile .overlay-info {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 300;
    }
    .live .live-auction.show-mobile .single-lot-container {
      display: block;
    }
    .live .live-auction.show-mobile .single-lot-container .info-container {
      margin-top: 0;
    }
    .live .live-auction.show-mobile .button-close-info {
      display: flex;
    }
    .live .live-auction.show-mobile .mobileStatus {
      display: flex;
      padding-left: 20px;
      font-family: Lato;
      background-color: #fff;
      font-size: 22px;
    }
    .live .live-auction.show-mobile .mobileStatus .green {
      color: green;
    }
    .live .live-auction.show-mobile .mobileStatus .red {
      color: red;
    }
  }
  @media (max-width: 900px) {
    .live .live-auction .lots-list {
      flex-basis: 100%;
      padding-left: 0;
      width: 100%;
    }
    .live .live-auction .single-lot-container {
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 100%;
      z-index: 400;
    }
  }
  @media (max-width: 900px) {
    .live .live-auction .lots-list {
      top: 205px;
    }
  }
  @media (max-width: 900px) {
    .live .live-auction .lots-list {
      top: 205px;
    }
  }
  @media (max-width: 450px) {
    .live .live-auction .lots-list {
      top: 295px;
    }
  }
  .live .text {
    margin: 0;
    white-space: pre;
    line-height: 20px;
  }
  .live .text.regular {
    font: 400 16px "Lato";
  }
  .live .text.bold {
    font: bold 17px "Lato";
  }
  .live .text.price {
    font: normal 19px "Lato";
    color: #a1b1bc;
  }
  .live .live-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.6);
    z-index: 500;
  }
  .live .live-overlay .text-container h3, .live .live-overlay .text-container a {
    display: block;
    text-align: center;
    margin: 5px 0;
    text-transform: uppercase;
    color: #efefef;
  }
  .live .live-overlay .text-container h3 {
    font: bold 23px "Lato";
  }
  .live .live-overlay .text-container a {
    font: normal 16px "Lato";
    text-decoration: underline;
    cursor: pointer;
  }
  .live .live-overlay .text-container-low-connection {
    background: white;
    padding: 40px;
    flex-direction: row;
    border-radius: 6px;
  }
  .live .live-overlay .auction-notification-text {
    color: #e55;
    font-weight: bold;
    font-size: 30px;
  }
  .live .live-overlay .auction-notification-text p {
    text-align: center;
    margin: 5px 5px;
  }
  .live .live-overlay .auction-notification-text span {
    font-weight: 400;
  }
  .live .live-overlay .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    color: #5d5d5d;
    font-size: 50px;
  }
  .live .live-overlay .icon-wrapper .no-internet-image {
    background: url("../src/assets/img/No-internet.png") no-repeat;
    background-size: contain;
    height: 100px;
    width: 100px;
  }
  .live .live-overlay .icon-wrapper svg {
    height: 50px;
  }
  
  @media (max-width: 550px) {
    .lots-list .lot-item .lot-info .lot-info-bottom {
      align-items: flex-start;
    }
  }
  @media (max-width: 550px) {
    .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  
  .no-internet {
    -webkit-animation: no-internet 0.5s both;
    animation: no-internet 0.5s both;
  }
  
  @-webkit-keyframes no-internet {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(30px);
      transform: translateY(30px);
    }
  }
  @keyframes no-internet {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(30px);
      transform: translateY(30px);
    }
  }
  .display-none {
    -webkit-animation: display-none 0.5s both;
    animation: display-none 0.5s both;
  }
  
  @-webkit-keyframes display-none {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-60px);
      transform: translateY(-60px);
    }
  }
  @keyframes display-none {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-60px);
      transform: translateY(-60px);
    }
  }
  .mask {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
    border-radius: 5px;
    pointer-events: none;
  }
  
  .lot-item {
    position: relative;
  }
  
  .auth-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .auth-checkbox > span {
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
  }
  .auth-checkbox .auth-checkbox-label {
    padding-right: 15px;
  }
  .auth-checkbox-inner {
    cursor: pointer;
  }
  .auth-checkbox-inner input {
    display: none;
  }
  .auth-checkbox-inner span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 2px;
    background-color: #666;
  }
  .auth-checkbox-inner-icon {
    font-size: 26px;
  }
  
  .auth-navigation-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    margin-bottom: 15px;
  }
  .auth-navigation-wrapper .auth-navigation-hr {
    position: relative;
    flex: 1;
    overflow: hidden;
  }
  .auth-navigation-wrapper .auth-navigation-hr hr {
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    margin: 0;
    height: 1px;
    width: 100%;
    background-color: #000;
  }
  .auth-navigation-wrapper .navigation-single {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    color: #666666;
    border: 1px solid #000000;
  }
  .auth-navigation-wrapper .navigation-single.active {
    background-color: #1f9512;
  }
  .auth-navigation-wrapper .navigation-single.active .navigation-single-icon {
    color: #fff;
  }
  .auth-navigation-wrapper .navigation-single-icon {
    font-size: 24px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .live .render-lots-container {
    position: relative;
    width: 100%;
  }
  .live .render-lots-container .auction-notification-wrapper {
    display: flex;
    align-items: center;
    width: calc(100% - 440px);
    height: 80px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #f9891a;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: auto;
  }
  .live .render-lots-container .auction-notification-wrapper.notification-without-internet {
    background-color: white;
  }
  .live .render-lots-container .auction-notification-wrapper.notification-without-internet .auction-notification-text {
    color: red;
    font-weight: bold;
  }
  .live .render-lots-container .auction-notification-wrapper.notification-without-internet .auction-notification-text span {
    font-weight: 400;
  }
  .live .render-lots-container .auction-notification-wrapper.notification-after-no-internet {
    top: 250px;
  }
  @media (max-width: 900px) {
    .live .render-lots-container .auction-notification-wrapper.notification-after-no-internet {
      top: 0;
    }
  }
  .live .render-lots-container .auction-notification-wrapper .icon-wrapper {
    display: flex;
    align-items: center;
    padding-left: 30px;
    color: #5d5d5d;
    font-size: 50px;
  }
  .live .render-lots-container .auction-notification-wrapper .icon-wrapper .no-internet-image {
    background: url("../src/assets/img/No-internet.png") no-repeat;
    background-size: contain;
    height: 50px;
    width: 50px;
  }
  .live .render-lots-container .auction-notification-wrapper .icon-wrapper svg {
    height: 50px;
  }
  .live .render-lots-container .auction-notification-wrapper .auction-notification-text {
    padding-left: 50px;
    padding-right: 10px;
    color: #5d5d5d;
    font: 400 21px Lato;
  }
  .live .render-lots-container .live-lots-list {
    position: fixed;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    width: calc(100% - (430px + 5px));
    height: calc(100% - 180px);
    top: 180px;
    left: 0;
    overflow: auto;
  }
  .live .render-lots-container .live-lots-list-with-notification {
    height: calc(100% - 270px);
    top: 270px;
  }
  .live .render-lots-container .live-lots-list.no-internet {
    height: calc(100% - 350px);
    top: 315px;
  }
  @media (max-width: 900px) {
    .live .render-lots-container .live-lots-list.no-internet {
      height: calc(100% - 350px);
      top: -25px;
    }
  }
  @media (max-width: 900px) {
    .live .render-lots-container .auction-notification-wrapper {
      position: inherit;
      width: calc(100% - 10px);
    }
    .live .render-lots-container .auction-notification-wrapper .icon-wrapper {
      padding-left: 20px;
    }
    .live .render-lots-container .auction-notification-wrapper .auction-notification-text {
      padding-left: 30px;
    }
    .live .render-lots-container .live-lots-list {
      position: inherit;
      width: 100%;
      height: initial;
      top: 0;
      overflow: visible;
    }
  }
  
  @-moz-keyframes blink {
    0% {
      background: #2e2e2e;
    }
    50% {
      background: #f4c241;
    }
    100% {
      background: #2e2e2e;
    }
  }
  /* Firefox */
  @-webkit-keyframes blink {
    0% {
      background: #2e2e2e;
    }
    50% {
      background: #f4c241;
    }
    100% {
      background: #2e2e2e;
    }
  }
  /* Webkit */
  @-ms-keyframes blink {
    0% {
      background: #2e2e2e;
    }
    50% {
      background: #f4c241;
    }
    100% {
      background: #2e2e2e;
    }
  }
  /* IE */
  @keyframes blink {
    0% {
      background: #2e2e2e;
    }
    50% {
      background: #f4c241;
    }
    100% {
      background: #2e2e2e;
    }
  }
  /* Opera and prob css3 final iteration */
  @-moz-keyframes blink-from-transparent-to-yellow {
    50% {
      background: #f4c241;
    }
  }
  /* Firefox */
  @-webkit-keyframes blink-from-transparent-to-yellow {
    50% {
      background: #f4c241;
    }
  }
  /* Webkit */
  @-ms-keyframes blink-from-transparent-to-yellow {
    50% {
      background: #f4c241;
    }
  }
  /* IE */
  @keyframes blink-from-transparent-to-yellow {
    50% {
      background: #f4c241;
    }
  }
  @keyframes blinking-status-in {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  @keyframes blinking-status-out {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  .autobid-active {
    width: 36px;
    min-width: 36px;
    border-radius: 4px;
    background: red;
    display: flex;
    flex-direction: column;
    height: 36px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .autobid-active.list {
    width: 45px;
    min-width: 45px;
    height: 45px;
  }
  @media (min-width: 1450px) {
    .autobid-active.list {
      width: 35px;
      min-width: 35px;
      height: 35px;
    }
  }
  
  .hyperlink {
    color: #007fc1;
    text-decoration: underline;
  }
  .hyperlink:hover {
    cursor: pointer;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  @keyframes fadein {
    from {
      background-color: transparent;
    }
    to {
      background-color: #f4c241;
    }
  }
  @keyframes fadeout {
    from {
      background-color: #f4c241;
    }
    to {
      background-color: transparent;
    }
  }
  .live .ending-help-container {
    z-index: 1;
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0;
  }
  .live .ending-help-container.fade-in {
    background-color: #f4c241;
    animation: fadein 3s;
  }
  .live .ending-help-container.fade-out {
    animation: fadeout 3s;
  }
  .live .closed {
    position: relative;
    overflow: hidden;
    pointer-events: none;
  }
  .live .closed .result {
    height: 87px;
    position: absolute;
    margin-left: -110px;
    z-index: 100;
    background-color: white;
    background: rgba(255, 255, 255, 0.4);
    margin-top: 150px;
    transform: rotate(-38deg);
    width: 450px;
    font-size: 45px;
    font-weight: 700;
    color: #ffffff;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
  .live .closed .result.result-list {
    transform: rotate(0);
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .live .closed .result.result-list.won {
    background: rgba(48, 185, 51, 0.6);
  }
  .live .closed .result.result-list.lost {
    background: rgba(221, 45, 46, 0.6);
  }
  .live .closed .result.won {
    background: rgba(31, 149, 18, 0.4);
  }
  .live .closed .result.lost {
    background: rgba(255, 0, 0, 0.4);
  }
  .live .closed .result.withdrawn {
    font-size: 33px;
  }
  .live .closed .result.no-img {
    margin-top: 80px;
    transform: rotate(-14deg);
  }
  @media (max-width: 550px) {
    .live .closed .result {
      margin-left: -44px;
      margin-top: 94px;
      transform: rotate(-19deg);
      width: calc(100% + 85px);
    }
  }
  .live .closed *:not(.result) {
    filter: grayscale(100%) brightness(75%);
  }
  .live .lot-item {
    display: flex;
    flex-direction: column;
    font-family: Lato;
    min-width: 220px;
    width: 222px;
    max-height: 400px;
    margin: 0px 0px 5px 5px;
    padding: 7px 7px 1px 7px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ffffff;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .live .lot-item.closed {
    padding: 0px;
  }
  .live .lot-item.lotList {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: table;
    background-color: #efefef;
    padding: 0;
    margin: 0;
    margin-top: 5px;
  }
  .live .lot-item.lotList.selected {
    background-color: #ffffff;
    padding: 7px 7px 1px 7px;
  }
  .live .lot-item.lotList.selected .mask {
    background: rgba(0, 0, 0, 0.3);
  }
  .live .lot-item.lotList.open {
    background: #D3D3D3;
  }
  .live .lot-item.lotList.open.selected {
    background-color: #DCDCDC;
  }
  .live .lot-item.bid {
    position: relative;
  }
  .live .lot-item .recent-bid {
    background: #444343;
    display: flex;
    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 4px;
    animation: opacity 10s;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .live .lot-item .recent-bid.list {
    margin-right: 8px;
    width: 45px;
    min-width: 45px;
    height: 45px;
  }
  @media (min-width: 1450px) {
    .live .lot-item .recent-bid.list {
      width: 35px;
      min-width: 35px;
      height: 35px;
    }
  }
  @keyframes opacity {
    0% {
      opacity: 1;
    }
    25% {
      background: #494949;
    }
    45% {
      background: #6B6B6C;
    }
    65% {
      background: #918F8C;
    }
    85% {
      background: #B5B5B5;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes border-opacity {
    0% {
      opacity: 1;
    }
    25% {
      border: solid 2px rgba(73, 73, 73, 0.75);
    }
    45% {
      border: solid 2px rgba(107, 107, 108, 0.5);
    }
    65% {
      border: solid 2px rgba(145, 143, 140, 0.25);
    }
    85% {
      border: solid 2px rgba(181, 181, 181, 0);
    }
    100% {
      border: solid 2px rgba(193, 192, 192, 0);
    }
  }
  @media (max-width: 550px) {
    .live .lot-item {
      width: calc(100% - 10px);
    }
    .live .lot-item .auc-title {
      font-size: 24px !important;
    }
    .live .lot-item .auc-price {
      font-size: 30px !important;
    }
  }
  .live .lot-item.no-img {
    max-height: 250px;
  }
  .live .lot-item.bided {
    background-color: #219028;
  }
  .live .lot-item.overbided {
    background-color: #dd2d2e;
  }
  .live .lot-item.selected {
    padding: 0;
  }
  .live .lot-item.selected.no-img .watching-small-btn {
    top: 24px !important;
  }
  .live .lot-item.selected.bided {
    background-color: #219028;
  }
  .live .lot-item.selected.overbided {
    background-color: #dd2d2e;
  }
  .live .lot-item.selected .lot-header {
    min-height: 16px;
  }
  .live .lot-item.selected .watching-small-btn {
    top: 16px !important;
  }
  .live .lot-item .lot-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 36px !important;
    padding: 0px 5px 0px 5px;
    border-radius: 5px 5px 0px 0px;
    background-color: #2e2e2e;
    color: #ffffff;
    font-size: 10px;
    font-weight: 700;
  }
  .live .lot-item .lot-header .left {
    display: flex;
    min-width: 40px;
  }
  .live .lot-item .lot-header .left .my-listing {
    margin-left: 5px;
  }
  .live .lot-item .lot-header .left .small-watch-btn {
    top: -25px;
    left: 93px;
    color: white;
  }
  .live .lot-item .lot-header .left .small-watch-btn .watching-small-btn {
    position: static;
    top: 26px;
  }
  .live .lot-item .lot-header .left .small-watch-btn .watching-small-btn .watch-flag {
    width: 20px;
    height: 13px;
    color: #007fc1;
  }
  .live .lot-item .lot-header .left .small-watch-btn .watching-small-btn .watch-flag.active {
    color: #fff;
  }
  .live .lot-item .lot-header.watched {
    background-color: #007fc1;
  }
  .live .lot-item .lot-header .watching-small-btn {
    position: absolute;
    right: 8px;
    top: 36px !important;
  }
  .live .lot-item .lot-header .watching-small-btn .watch-flag {
    width: 16px;
    height: 20px;
  }
  .live .lot-item .lot-header .location {
    text-transform: uppercase;
    max-width: 170px;
    display: flex;
    text-align: end;
    align-items: center;
    padding-top: 1px;
    min-height: 36px;
    max-height: 36px;
  }
  .live .lot-item .lot-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .live .lot-item .lot-body.list {
    flex-direction: row;
    align-items: center;
  }
  .live .lot-item .lot-body .lot-image {
    width: 100%;
    height: 128px;
    object-fit: cover;
    flex-grow: 1;
  }
  .live .lot-item .lot-body .lot-image.img-list {
    height: 100%;
    width: 200px;
  }
  @media (min-width: 1450px) {
    .live .lot-item .lot-body .lot-image.img-list {
      width: 90px !important;
      max-height: 50px;
    }
  }
  .live .lot-item .lot-body .lot-image.no-img-list {
    height: 100%;
    width: 200px;
  }
  @media (min-width: 1450px) {
    .live .lot-item .lot-body .lot-image.no-img-list {
      width: 90px !important;
      max-height: 50px;
    }
  }
  .live .lot-item .lot-body .lot-info {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-height: 200px;
    border-radius: 0px 0px 5px 5px;
  }
  @media (max-width: 550px) {
    .live .lot-item .lot-body .lot-info {
      max-height: 250px;
    }
  }
  .live .lot-item .lot-body .lot-info.isSafari {
    max-height: 210px;
  }
  .live .lot-item .lot-body .lot-info.selected {
    min-height: 205px;
    max-height: 205px;
  }
  .live .lot-item .lot-body .lot-info.no-img {
    height: 100%;
    max-height: 100000px;
  }
  .live .lot-item .lot-body .lot-info.no-img.isSafari {
    height: 100vh;
  }
  .live .lot-item .lot-body .lot-info.lot-list-info {
    min-height: 50px;
    max-height: 250px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 1450px) and (min-width: 1025px) {
    .live .lot-item .lot-body .lot-info.lot-list-info {
      height: 140px;
    }
  }
  @media (min-width: 1450px) {
    .live .lot-item .lot-body .lot-info.lot-list-info {
      max-height: 50px;
    }
  }
  @-moz-document url-prefix() {
    @media (max-width: 1400px) {
      .live .lot-item .lot-body .lot-info.lot-list-info {
        flex-direction: column;
      }
    }
  }
  @media (max-width: 1449px) {
    .live .lot-item .lot-body .lot-info.lot-list-info {
      flex-direction: column;
    }
  }
  .live .lot-item .lot-body .lot-info.ending .top {
    transition: all 1s ease-in-out;
    animation: blink-from-transparent-to-yellow normal 3s infinite ease-in-out;
  }
  .live .lot-item .lot-body .lot-info .ending .top,
  .live .lot-item .lot-body .lot-info .ending .top-list {
    transition: all 1s ease-in-out;
    animation: blink-from-transparent-to-yellow normal 3s infinite ease-in-out;
  }
  .live .lot-item .lot-body .lot-info.selected {
    background-color: #ffffff;
  }
  .live .lot-item .lot-body .lot-info.selected.bided {
    background-color: #219028;
  }
  .live .lot-item .lot-body .lot-info .top-list {
    height: 100%;
    padding: 0 !important;
    align-items: center;
  }
  .live .lot-item .lot-body .lot-info .top, .live .lot-item .lot-body .lot-info .top-list {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background-color: #2e2e2e;
    min-height: 30px;
    padding: 0px 5px 0px 5px;
    color: #ffffff;
  }
  .live .lot-item .lot-body .lot-info .top.list-timer, .live .lot-item .lot-body .lot-info .top-list.list-timer {
    margin-left: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  @media (min-width: 1450px) {
    .live .lot-item .lot-body .lot-info .top.list-timer, .live .lot-item .lot-body .lot-info .top-list.list-timer {
      width: 150px;
    }
  }
  .live .lot-item .lot-body .lot-info .top.device-timer, .live .lot-item .lot-body .lot-info .top-list.device-timer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .live .lot-item .lot-body .lot-info .top .status-box, .live .lot-item .lot-body .lot-info .top-list .status-box {
    position: relative;
    display: flex;
  }
  .live .lot-item .lot-body .lot-info .top .left, .live .lot-item .lot-body .lot-info .top-list .left {
    display: flex;
    font-size: 16px;
    height: 20px;
    z-index: 2;
  }
  .live .lot-item .lot-body .lot-info .top .left .lot-current-state, .live .lot-item .lot-body .lot-info .top-list .left .lot-current-state {
    font-size: 11px;
    border-radius: 3px 0 0 3px;
    padding-left: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .live .lot-item .lot-body .lot-info .top .left .lot-current-state.frozen, .live .lot-item .lot-body .lot-info .top-list .left .lot-current-state.frozen {
    background: red;
    min-width: 115px;
    text-align: center;
  }
  .live .lot-item .lot-body .lot-info .top .left .lot-current-state.paused, .live .lot-item .lot-body .lot-info .top-list .left .lot-current-state.paused {
    background: grey;
    min-width: 115px;
    text-align: center;
  }
  .live .lot-item .lot-body .lot-info .top .left .lot-current-state.paused.list, .live .lot-item .lot-body .lot-info .top .left .lot-current-state.paused.grid, .live .lot-item .lot-body .lot-info .top-list .left .lot-current-state.paused.list, .live .lot-item .lot-body .lot-info .top-list .left .lot-current-state.paused.grid {
    background: transparent !important;
  }
  .live .lot-item .lot-body .lot-info .top .timer-text, .live .lot-item .lot-body .lot-info .top-list .timer-text {
    color: #ffffff;
    font: 400 14px "Lato";
  }
  .live .lot-item .lot-body .lot-info .top .timer-text.grid, .live .lot-item .lot-body .lot-info .top-list .timer-text.grid {
    color: #000 !important;
    font: 600 20px "Lato" !important;
    height: 24px;
  }
  .live .lot-item .lot-body .lot-info .top .timer-text.list, .live .lot-item .lot-body .lot-info .top-list .timer-text.list {
    color: #000 !important;
    min-width: 55px;
    text-align: center;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status, .live .lot-item .lot-body .lot-info .top-list .lot-status {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    height: 16px;
    border-radius: 3px;
    font-size: 14px;
    user-select: none;
    min-width: 70px;
    font-size: 11px;
    height: 20px;
    z-index: 2;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.nearing, .live .lot-item .lot-body .lot-info .top-list .lot-status.nearing {
    background-color: #fac918;
  }
  @media (max-width: 1400px) {
    .live .lot-item .lot-body .lot-info .top .lot-status.nearing, .live .lot-item .lot-body .lot-info .top-list .lot-status.nearing {
      font-size: 10px;
    }
  }
  @media (max-width: 1200px) {
    .live .lot-item .lot-body .lot-info .top .lot-status.nearing, .live .lot-item .lot-body .lot-info .top-list .lot-status.nearing {
      font-size: 10px;
    }
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.nearing.info, .live .lot-item .lot-body .lot-info .top-list .lot-status.nearing.info {
    width: 170px;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.nearing.closing, .live .lot-item .lot-body .lot-info .top-list .lot-status.nearing.closing {
    animation: blinking-status-out normal 8s infinite step-end;
    transition: all 1s ease-in-out;
    position: absolute;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.nearing.closing.info, .live .lot-item .lot-body .lot-info .top-list .lot-status.nearing.closing.info {
    right: 10px;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.on-market, .live .lot-item .lot-body .lot-info .top-list .lot-status.on-market {
    background-color: #219028;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.on-market.closing, .live .lot-item .lot-body .lot-info .top-list .lot-status.on-market.closing {
    animation: blinking-status-out normal 8s infinite step-end;
    transition: all 1s ease-in-out;
    position: absolute;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.on-market.closing.info, .live .lot-item .lot-body .lot-info .top-list .lot-status.on-market.closing.info {
    right: 10px;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.open, .live .lot-item .lot-body .lot-info .top-list .lot-status.open {
    background-color: #dd2d2e;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.open.closing, .live .lot-item .lot-body .lot-info .top-list .lot-status.open.closing {
    animation: blinking-status-out normal 8s infinite step-end;
    transition: all 1s ease-in-out;
    position: absolute;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.open.closing.info, .live .lot-item .lot-body .lot-info .top-list .lot-status.open.closing.info {
    right: 10px;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.awaiting, .live .lot-item .lot-body .lot-info .top-list .lot-status.awaiting {
    background-color: #2e2e2e;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.awaiting.closing, .live .lot-item .lot-body .lot-info .top-list .lot-status.awaiting.closing {
    animation: blinking-status-out normal 8s infinite step-end;
    position: absolute;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.awaiting.closing.info, .live .lot-item .lot-body .lot-info .top-list .lot-status.awaiting.closing.info {
    right: 10px;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.closed, .live .lot-item .lot-body .lot-info .top-list .lot-status.closed {
    background-color: #2e2e2e;
    text-transform: uppercase;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.ending, .live .lot-item .lot-body .lot-info .top-list .lot-status.ending {
    background-color: #708090;
    animation: blinking-status-in normal 8s infinite step-end;
  }
  @media (max-width: 410px) {
    .live .lot-item .lot-body .lot-info .top .lot-status, .live .lot-item .lot-body .lot-info .top-list .lot-status {
      font-size: 14px;
      width: 130px;
      height: 19px;
    }
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.list, .live .lot-item .lot-body .lot-info .top-list .lot-status.list {
    margin-top: 5px;
    bottom: 0;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.list.black, .live .lot-item .lot-body .lot-info .top-list .lot-status.list.black {
    color: #000;
  }
  .live .lot-item .lot-body .lot-info .top .lot-status.device, .live .lot-item .lot-body .lot-info .top-list .lot-status.device {
    font-size: 14px !important;
    height: 26px;
    bottom: 0;
  }
  .live .lot-item .lot-body .lot-info .center, .live .lot-item .lot-body .lot-info .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .live .lot-item .lot-body .lot-info .center.selected, .live .lot-item .lot-body .lot-info .bottom.selected {
    background-color: #ffffff;
  }
  .live .lot-item .lot-body .lot-info .center.bided, .live .lot-item .lot-body .lot-info .bottom.bided {
    color: white;
    background-color: #219028;
  }
  .live .lot-item .lot-body .lot-info .center.overbided, .live .lot-item .lot-body .lot-info .bottom.overbided {
    color: white;
    background-color: #dd2d2e;
  }
  .live .lot-item .lot-body .lot-info .center.list-right, .live .lot-item .lot-body .lot-info .bottom.list-right {
    width: 100%;
    flex-direction: row !important;
    justify-content: flex-end !important;
    height: auto;
  }
  .live .lot-item .lot-body .lot-info .center.list-right.open, .live .lot-item .lot-body .lot-info .bottom.list-right.open {
    background: #D3D3D3;
  }
  .live .lot-item .lot-body .lot-info .center.list-right.open.selected, .live .lot-item .lot-body .lot-info .bottom.list-right.open.selected {
    background-color: #DCDCDC;
  }
  .live .lot-item .lot-body .lot-info .center.list-right.device-bottom, .live .lot-item .lot-body .lot-info .bottom.list-right.device-bottom {
    flex-direction: column !important;
  }
  .live .lot-item .lot-body .lot-info .center.list-right.selected, .live .lot-item .lot-body .lot-info .bottom.list-right.selected {
    background-color: #ffffff;
  }
  @media (max-width: 1449px) {
    .live .lot-item .lot-body .lot-info .center.list-right, .live .lot-item .lot-body .lot-info .bottom.list-right {
      justify-content: flex-end !important;
    }
  }
  .live .lot-item .lot-body .lot-info .center.list-right .part-list-left, .live .lot-item .lot-body .lot-info .center.list-right .part-list-right, .live .lot-item .lot-body .lot-info .bottom.list-right .part-list-left, .live .lot-item .lot-body .lot-info .bottom.list-right .part-list-right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 1450px) and (min-width: 1025px) {
    .live .lot-item .lot-body .lot-info .center.list-right .part-list-left, .live .lot-item .lot-body .lot-info .center.list-right .part-list-right, .live .lot-item .lot-body .lot-info .bottom.list-right .part-list-left, .live .lot-item .lot-body .lot-info .bottom.list-right .part-list-right {
      width: 100%;
      justify-content: space-between;
    }
  }
  .live .lot-item .lot-body .lot-info .center.list-right .part-list-left.device-right, .live .lot-item .lot-body .lot-info .center.list-right .part-list-right.device-right, .live .lot-item .lot-body .lot-info .bottom.list-right .part-list-left.device-right, .live .lot-item .lot-body .lot-info .bottom.list-right .part-list-right.device-right {
    width: 100%;
  }
  .live .lot-item .lot-body .lot-info .center.list-right .part-list-left, .live .lot-item .lot-body .lot-info .bottom.list-right .part-list-left {
    height: 100%;
  }
  .live .lot-item .lot-body .lot-info .center .auc-price-box, .live .lot-item .lot-body .lot-info .bottom .auc-price-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .live .lot-item .lot-body .lot-info .center .auc-price-box.bid, .live .lot-item .lot-body .lot-info .bottom .auc-price-box.bid {
    background: #444343;
  }
  @media (min-width: 1450px) {
    .live .lot-item .lot-body .lot-info .center .auc-price-box, .live .lot-item .lot-body .lot-info .bottom .auc-price-box {
      width: 150px;
    }
  }
  @media (max-width: 1024px) {
    .live .lot-item .lot-body .lot-info .center .auc-price-box, .live .lot-item .lot-body .lot-info .bottom .auc-price-box {
      padding: 14px;
    }
  }
  .live .lot-item .lot-body .lot-info .center {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    background-color: #efefef;
  }
  .live .lot-item .lot-body .lot-info .center.selected .auc-title {
    font-weight: bold;
  }
  .live .lot-item .lot-body .lot-info .center.bided {
    color: white;
    background-color: #219028;
  }
  .live .lot-item .lot-body .lot-info .center.overbided {
    color: white;
    background-color: #dd2d2e;
  }
  .live .lot-item .lot-body .lot-info .center .auc-title-weights {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .live .lot-item .lot-body .lot-info .center .auc-title-weights .auc-title-weight {
    line-height: 18px;
    font-family: "Lato";
    border: 1px solid black;
    font-size: 12px;
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .live .lot-item .lot-body .lot-info .center .auc-title-weights .auc-title-weight.curfew {
    color: #DBB52C;
    background-color: #2e2e2e;
  }
  .live .lot-item .lot-body .lot-info .center .auc-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 8px;
    align-items: center;
    font-size: 13px;
    padding-top: 8px;
  }
  .live .lot-item .lot-body .lot-info .center .auc-title .auc-title-main {
    height: 44px;
    text-align: center;
    display: flex;
    align-items: stretch;
    display: -webkit-box;
    justify-content: center;
    width: 80%;
    margin: 0;
  }
  @media (max-width: 550px) {
    .live .lot-item .lot-body .lot-info .center .auc-title .auc-title-main {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
  .live .lot-item .lot-body .lot-info .center .auc-title .auc-title-main-secondary {
    margin: 0;
  }
  .live .lot-item .lot-body .lot-info .watch-btn-list {
    margin-left: 20px;
    margin-right: 10px;
  }
  @media (min-width: 1450px) {
    .live .lot-item .lot-body .lot-info .watch-btn-list {
      width: 35px;
    }
  }
  .live .lot-item .lot-body .lot-info .watch-btn-list.device-btn {
    margin: 5px;
  }
  .live .lot-item .lot-body .lot-info .bottom {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    height: 80px;
    background-color: #efefef;
  }
  .live .lot-item .lot-body .lot-info .bottom.list-right.selected {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media (max-width: 1449px) and (min-width: 1025px) {
    .live .lot-item .lot-body .lot-info .bottom.list-right {
      background-color: transparent;
    }
  }
  @media (max-width: 900px) {
    .live .lot-item .lot-body .lot-info .bottom {
      height: 80px;
    }
  }
  @media (max-width: 550px) {
    .live .lot-item .lot-body .lot-info .bottom {
      height: 72px;
    }
  }
  .live .lot-item .lot-body .lot-info .bottom.selected {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .live .lot-item .lot-body .lot-info .bottom.selected .auc-price {
    font-weight: bold;
  }
  .live .lot-item .lot-body .lot-info .bottom.bid {
    background: #444343;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .live .lot-item .lot-body .lot-info .bottom .price-center {
    flex-direction: column;
    text-align: center;
  }
  .live .lot-item .lot-body .lot-info .bottom .price-left {
    position: absolute;
    left: 0;
    margin-left: 5px;
  }
  .live .lot-item .lot-body .lot-info .bottom .price-right {
    position: absolute;
    right: 0;
    margin-right: 5px;
  }
  .live .lot-item .lot-body .lot-info .bottom .auc-price {
    align-self: center;
    font-size: 18px;
    font-weight: 400;
  }
  .live .lot-item .lot-body .lot-info .bottom .auc-price span {
    font-size: 15px;
  }
  .live .lot-item .lot-body .lot-info .bottom .auc-price.bid-price {
    color: #fff;
  }
  .live .lot-item .lot-body .lot-info .bottom .auc-price-list {
    align-self: center;
    font-size: 22px;
  }
  .live .lot-item .lot-body .lot-info .bottom .auc-price-list span {
    font-size: 15px;
  }
  .live .lot-item .lot-body .lot-info .bottom .auc-price-list.bid-price {
    color: #fff;
  }
  @media (min-width: 1450px) {
    .live .lot-item .lot-body .lot-info .bottom .auc-price-list {
      font-size: 18px;
    }
  }
  .live .lot-item .lot-body .lot-info .bottom .cents-price {
    font-size: 15px;
    margin-top: 2px;
  }
  .live .lot-item .lot-body .lot-info .bottom .cents-price.bid-price {
    color: #fff;
  }
  .live .lot-item .lot-body .lot-info .bottom .cents-price-list {
    font-size: 18px;
    margin-top: 2px;
  }
  .live .lot-item .lot-body .lot-info .bottom .cents-price-list.bid-price {
    color: #fff;
  }
  @media (min-width: 1450px) {
    .live .lot-item .lot-body .lot-info .bottom .cents-price-list {
      font-size: 16px;
    }
  }
  .live .lot-item .center-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px 5px;
    background-color: #efefef;
    flex-direction: row !important;
    justify-content: flex-start !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: center !important;
  }
  @media (max-width: 1024px) {
    .live .lot-item .center-list {
      flex-direction: column !important;
    }
  }
  .live .lot-item .center-list.open {
    background: #D3D3D3;
  }
  .live .lot-item .center-list.open.selected {
    background-color: #DCDCDC;
  }
  .live .lot-item .center-list.selected {
    background-color: transparent;
  }
  .live .lot-item .center-list .row-title {
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 600px;
  }
  @media (max-width: 1620px) {
    .live .lot-item .center-list .row-title {
      width: 500px;
    }
  }
  @media (max-width: 1449px) {
    .live .lot-item .center-list .row-title {
      height: 100%;
      width: auto;
    }
  }
  .live .lot-item .center-list .row-title.device {
    justify-content: space-between;
    width: 100% !important;
    margin-top: 5px;
  }
  .live .lot-item .center-list .row-title .header-text, .live .lot-item .center-list .row-title .header-lot {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    padding: 0;
  }
  .live .lot-item .center-list .row-title .header-text {
    width: 80%;
    text-align: right;
  }
  .live .lot-item .center-list .row-title .header-lot {
    font-size: 24px;
  }
  .live .lot-item .center-list .lotNoBox {
    height: 100%;
    width: 100%;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 1450px) {
    .live .lot-item .center-list .lotNoBox {
      min-height: 50px;
      width: 50px;
    }
  }
  .live .lot-item .center-list .lotNoBox.selected {
    background-color: #ffffff;
  }
  .live .lot-item .center-list .lotNoBox.bided {
    color: white;
    background: linear-gradient(90deg, #219028, #ffffff);
  }
  .live .lot-item .center-list .lotNoBox.overbided {
    color: white;
    background: linear-gradient(90deg, #dd2d2e, #ffffff);
  }
  .live .lot-item .center-list .auc-title-list {
    display: flex;
    margin-top: 0;
    margin-left: 10px;
    margin-bottom: 0;
  }
  .live .lot-item .center-list .device-title {
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 22px;
    text-align: center;
  }
  .live .lot-item .center-list .state-info-list {
    flex-direction: row;
    display: flex;
    margin-left: 30px;
    height: 100%;
    align-items: center;
    text-align: center;
    width: auto;
  }
  @media (max-width: 1449px) {
    .live .lot-item .center-list .state-info-list {
      margin-left: 0;
    }
  }
  .live .lot-item .center-list .state-info-list .state-list {
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    font-weight: bold;
    font-size: 6.7pt;
    height: 100%;
    min-height: 55px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 150px;
  }
  @media (max-width: 1449px) and (min-width: 1025px) {
    .live .lot-item .center-list .state-info-list .state-list {
      margin-top: -16px;
    }
  }
  .live .lot-item .center-list .state-info-list .state-list .info-text {
    margin: 0;
    margin-left: 5px;
    margin-right: 5px;
  }
  .live .lot-item .center-list .state-info-list .age-list {
    border-left: 1px solid grey;
    padding: 10px;
    width: 65px;
    height: 100%;
  }
  @media (max-width: 1450px) and (min-width: 1025px) {
    .live .lot-item .center-list .state-info-list .age-list {
      margin-top: 0;
    }
  }
  .live .lot-item .center-list .state-info-list .state-weight {
    border-left: 1px solid grey;
    padding: 10px;
    width: 90px;
    height: 100%;
    text-transform: lowercase;
  }
  @media (max-width: 1450px) and (min-width: 1025px) {
    .live .lot-item .center-list .state-info-list .state-weight {
      margin-top: 0;
    }
  }
  .live .lot-item .lotNo {
    min-width: 60px;
    color: #000000;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 22px;
  }
  @media (min-width: 1450px) {
    .live .lot-item .lotNo {
      font-size: 16px;
      max-width: 30px;
      min-width: 0px;
      display: inline-block;
      text-align: center;
    }
  }
  .live .lot-item .image-box {
    height: 90%;
    min-height: 80px;
    align-items: center;
    display: grid;
    width: 200px;
  }
  @media (min-width: 1450px) {
    .live .lot-item .image-box {
      min-height: 50px;
      width: 90px;
    }
  }
  .live .main-info-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .live .middle-screen-info-list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .live .recent-bid-plug {
    width: 43px;
    min-width: 43px;
    height: 35px;
  }
  .live .bid-icons {
    width: 150px;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
  }
  .live .lot-number-image-list {
    display: flex;
    flex-direction: row;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  @keyframes fadein {
    from {
      background-color: transparent;
    }
    to {
      background-color: #f4c241;
    }
  }
  @keyframes fadeout {
    from {
      background-color: #f4c241;
    }
    to {
      background-color: transparent;
    }
  }
  .single-lot-container .result {
    height: 120px;
    position: absolute;
    margin-left: -101px;
    z-index: 100;
    background: rgba(255, 255, 255, 0.4);
    margin-top: 116px;
    transform: rotate(-29deg);
    width: 600px;
    font-family: Lato;
    font-size: 65px;
    font-weight: 700;
    color: #ffffff;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
  .single-lot-container .result.won {
    background-color: rgba(31, 149, 18, 0.4);
  }
  .single-lot-container .result.lost {
    background-color: rgba(255, 0, 0, 0.4);
  }
  .single-lot-container .result.no-img {
    margin-top: 45px;
    transform: rotate(-14deg);
  }
  @media (max-width: 900px) {
    .single-lot-container .result {
      width: 100%;
      margin-left: 0;
      transform: rotate(0deg);
      height: 100%;
      margin-top: 0px;
      border-radius: 5px;
    }
  }
  
  @media (max-width: 900px) {
    .bidding-container {
      overflow: hidden;
      position: relative;
    }
  }
  .bidding-container p {
    margin: 10px 0;
    font: 400 30px "Lato";
  }
  .bidding-container .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 360px;
    border-radius: 5px;
    background: #ffffff;
  }
  @media (max-width: 900px) {
    .bidding-container .content-container {
      border-radius: 0;
    }
  }
  .bidding-container .content-container.bidding-closed:not(.result) {
    background: #708090;
    filter: grayscale(100%) brightness(75%);
  }
  @media (max-width: 900px) {
    .bidding-container .content-container.bidding-closed:not(.result) {
      filter: grayscale(100%) brightness(50%);
    }
  }
  .bidding-container .content-container.bidding-closed {
    position: relative;
    pointer-events: none;
  }
  .bidding-container .content-container.bided {
    background-color: #219028;
  }
  .bidding-container .content-container.bided .info {
    color: white !important;
  }
  .bidding-container .content-container.bided .info .cents-price {
    color: white !important;
  }
  .bidding-container .content-container.overbided {
    background-color: #dd2d2e;
  }
  .bidding-container .content-container.overbided .info {
    color: white !important;
  }
  .bidding-container .content-container.overbided .info .cents-price {
    color: white !important;
  }
  .bidding-container .content-container .overlay-container {
    position: relative;
  }
  .bidding-container .content-container .lock-bids {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border-radius: 5px;
    height: 115px;
    background-color: #2e2e2e;
    color: #ffffff;
    cursor: pointer;
  }
  .bidding-container .content-container .lock-bids svg {
    width: 40px;
    height: 40px;
  }
  .bidding-container .content-container .lock-bids p {
    margin: 0;
  }
  .bidding-container .content-container .lock-bids:hover {
    background-color: #353535;
  }
  .bidding-container .content-container .row {
    margin: 5px;
    padding: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .bidding-container .content-container .row.header {
    color: #ffffff;
    font-family: Lato;
    font-size: 22px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    height: 55px;
    border-radius: 5px;
    background-color: #2e2e2e;
  }
  @media (max-width: 900px) {
    .bidding-container .content-container .row.header .header-lot-number {
      min-width: 75px;
    }
  }
  .bidding-container .content-container .row.header .location {
    text-transform: uppercase;
  }
  @media (min-width: 900px) {
    .bidding-container .content-container .row.header .location {
      max-width: 300px;
    }
  }
  .bidding-container .content-container .row.no-lot-header {
    color: #ffffff;
    font-family: Lato;
    font-size: 22px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    height: 40px;
    border-radius: 5px;
    background-color: #2e2e2e;
  }
  .bidding-container .content-container .row.info {
    color: #ffffff;
    font-family: Lato;
    font-size: 22px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    color: #000000;
    align-self: center;
  }
  @media (min-width: 900px) {
    .bidding-container .content-container .row.info {
      width: 375px;
    }
  }
  .bidding-container .content-container .row.info .lot-title-weight {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .bidding-container .content-container .row.info .lot-title {
    margin-bottom: 5px;
    text-align: center;
  }
  .bidding-container .content-container .row.info .price {
    font-size: 34px;
  }
  .bidding-container .content-container .row.info .price .price-measure {
    font-size: 20px;
  }
  .bidding-container .content-container .row.info .price .change-bidding-type {
    position: absolute;
    right: 5px;
    padding: 5px;
    font-size: 12px;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    top: 122px;
    color: black;
    user-select: none;
  }
  .bidding-container .content-container .row.info .cents-price {
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    color: #333333;
  }
  .bidding-container .content-container .row.status {
    color: #ffffff;
    font-family: Lato;
    font-size: 22px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-bottom: 0;
    border-radius: 5px;
    background-color: #2e2e2e;
  }
  .bidding-container .content-container .row.status.ending {
    transition: all 1s ease-in-out;
    animation: blink normal 3s infinite ease-in-out;
  }
  .bidding-container .content-container .row.status .lot-status {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    height: 16px;
    border-radius: 3px;
    font-size: 14px;
    user-select: none;
    width: 100px;
    height: 30px;
    font-size: 14px;
  }
  .bidding-container .content-container .row.status .lot-status.nearing {
    background-color: #fac918;
  }
  @media (max-width: 1400px) {
    .bidding-container .content-container .row.status .lot-status.nearing {
      font-size: 10px;
    }
  }
  @media (max-width: 1200px) {
    .bidding-container .content-container .row.status .lot-status.nearing {
      font-size: 10px;
    }
  }
  .bidding-container .content-container .row.status .lot-status.nearing.info {
    width: 170px;
  }
  .bidding-container .content-container .row.status .lot-status.nearing.closing {
    animation: blinking-status-out normal 8s infinite step-end;
    transition: all 1s ease-in-out;
    position: absolute;
  }
  .bidding-container .content-container .row.status .lot-status.nearing.closing.info {
    right: 10px;
  }
  .bidding-container .content-container .row.status .lot-status.on-market {
    background-color: #219028;
  }
  .bidding-container .content-container .row.status .lot-status.on-market.closing {
    animation: blinking-status-out normal 8s infinite step-end;
    transition: all 1s ease-in-out;
    position: absolute;
  }
  .bidding-container .content-container .row.status .lot-status.on-market.closing.info {
    right: 10px;
  }
  .bidding-container .content-container .row.status .lot-status.open {
    background-color: #dd2d2e;
  }
  .bidding-container .content-container .row.status .lot-status.open.closing {
    animation: blinking-status-out normal 8s infinite step-end;
    transition: all 1s ease-in-out;
    position: absolute;
  }
  .bidding-container .content-container .row.status .lot-status.open.closing.info {
    right: 10px;
  }
  .bidding-container .content-container .row.status .lot-status.awaiting {
    background-color: #2e2e2e;
  }
  .bidding-container .content-container .row.status .lot-status.awaiting.closing {
    animation: blinking-status-out normal 8s infinite step-end;
    position: absolute;
  }
  .bidding-container .content-container .row.status .lot-status.awaiting.closing.info {
    right: 10px;
  }
  .bidding-container .content-container .row.status .lot-status.closed {
    background-color: #2e2e2e;
    text-transform: uppercase;
  }
  .bidding-container .content-container .row.status .lot-status.ending {
    background-color: #708090;
    animation: blinking-status-in normal 8s infinite step-end;
  }
  @media (max-width: 410px) {
    .bidding-container .content-container .row.status .lot-status {
      font-size: 14px;
      width: 130px;
      height: 19px;
    }
  }
  @media (max-width: 550px) {
    .bidding-container .content-container .row.status .lot-status {
      font-size: 14px;
    }
  }
  .bidding-container .content-container .row.status .timer-text {
    color: #ffffff;
    font: 400 20px "Lato";
  }
  .bidding-container .content-container .row.bid {
    user-select: none;
    color: #ffffff;
    font-family: Lato;
    font-size: 22px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    border-radius: 5px;
    background-color: #2e2e2e;
  }
  .bidding-container .content-container .row.bid .left {
    margin: 0px 5px 0px 5px;
    display: flex;
    justify-content: center;
  }
  .bidding-container .content-container .row.bid .bid-buttons {
    user-select: none;
    display: flex;
    color: #000000;
    font-size: 15px;
    width: 90%;
  }
  .bidding-container .content-container .row.bid .bid-buttons .bid-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 45px;
    border-radius: 5px;
    background-color: #ffffff;
    cursor: pointer;
    padding: 4px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    flex: 1;
  }
  .bidding-container .content-container .row.bid .bid-buttons .bid-button:hover {
    background-color: #e2e2e2;
  }
  .bidding-container .content-container .row.bid .bid-buttons .bid-button .step {
    font-size: 16px;
    white-space: nowrap;
  }
  .bidding-container .content-container .row.bid .bid-buttons .bid-button .big-step {
    font-size: 12px;
    white-space: nowrap;
  }
  .bidding-container .content-container .row.bid .bid-buttons .bid-button .sum {
    font-size: 12px;
    white-space: nowrap;
  }
  .bidding-container .content-container .row.bid .bid-buttons .bid-button .big-sum {
    font-size: 11px;
    white-space: nowrap;
  }
  .bidding-container .content-container .row.bid .bid-buttons .bid-button:first-child {
    height: 55px;
  }
  .bidding-container .content-container .row.bid .bid-buttons .bid-button ~ .bid-button {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .bidding-container .content-container .row.auto-bid {
    color: #ffffff;
    font-family: Lato;
    font-size: 22px;
    font-weight: 400;
    user-select: none;
    border-radius: 5px;
    background-color: #2e2e2e;
  }
  .bidding-container .content-container .row.auto-bid.active {
    background-color: #dd2d2e;
  }
  .bidding-container .content-container .row.auto-bid .left {
    margin: 0px 5px 0px 5px;
    display: flex;
    justify-content: center;
    word-break: break-word;
  }
  .bidding-container .content-container .row.auto-bid .bid-buttons {
    display: flex;
    justify-content: flex-end;
    color: #000000;
    font-size: 15px;
    flex: 4 4;
  }
  .bidding-container .content-container .row.auto-bid .bid-buttons .bid-input {
    font-family: Lato;
    width: 63%;
    height: 45px;
    padding-left: 15px;
    border-radius: 5px;
    background-color: #ffffff;
  }
  .bidding-container .content-container .row.auto-bid .bid-buttons .bid-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 45px;
    border-radius: 5px;
    background-color: #ffffff;
    cursor: pointer;
    padding: 4px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    min-width: 76px;
    margin-left: 5px;
  }
  .bidding-container .content-container .row.auto-bid .bid-buttons .bid-button:hover {
    background-color: #e2e2e2;
  }
  .bidding-container .content-container .row.auto-bid .bid-buttons .bid-button ~ .bid-button {
    margin-left: 5px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  @keyframes fadein {
    from {
      background-color: transparent;
    }
    to {
      background-color: #f4c241;
    }
  }
  @keyframes fadeout {
    from {
      background-color: #f4c241;
    }
    to {
      background-color: transparent;
    }
  }
  .live .info-container {
    border-radius: 5px;
  }
  .live .info-container .content-container {
    padding: 5px;
  }
  .live .info-container .content-container .heading {
    padding: 0px 5px 0px 15px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #2e2e2e;
    border-radius: 5px;
  }
  .live .info-container .content-container .heading .popup-btn {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
    font-family: Lato;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
  }
  @media (max-width: 900px) {
    .live .info-container .content-container .heading .popup-btn {
      display: none;
    }
  }
  .live .info-container .content-container .heading .popup-btn:hover {
    background-color: #ffffff;
    color: #000000;
  }
  .live .info-container .content-container .heading .heading-text {
    margin: 0;
    color: #ffffff;
    font: 700 16px "Lato";
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .live .info-container .content-container .heading .fa-times {
    color: #ffffff;
    width: 35px;
    height: 30px;
    cursor: pointer;
    transition: opacity 0.5s ease;
  }
  .live .info-container .content-container .heading .fa-times:hover {
    opacity: 0.8;
  }
  @media (max-width: 900px) {
    .live .info-container .content-container .heading .fa-times {
      display: none;
    }
  }
  .live .info-container .content-container .details-loader-wrap {
    text-align: center;
  }
  .live .info-container .content-container .details-loader-wrap .details-loader {
    display: inline-block;
  }
  .live .info-container .content-container .details .lot-documents .row .documents-container {
    background-color: #ededed;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 0 5px 5px;
    border: 1px solid #c6c6c6;
  }
  .live .info-container .content-container .details .lot-documents .row .documents-container .empty-documents-wrapper {
    font-size: 12px;
  }
  .live .info-container .content-container .details .lot-documents .row .documents-container .option:first-child {
    margin-top: 0;
  }
  .live .info-container .content-container .details .lot-documents .row .documents-container .option:first-child .file-wrap {
    padding-top: 15px;
  }
  .live .info-container .content-container .details .lot-documents .row .documents-container .option:last-child .file-wrap {
    padding-bottom: 15px;
  }
  .live .info-container .content-container .details .lot-documents .row .documents-container .option .file-wrap {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
    padding-top: 0;
  }
  .live .info-container .content-container .details .lot-documents .row .documents-container .option .file-wrap p, .live .info-container .content-container .details .lot-documents .row .documents-container .option .file-wrap a {
    font-size: 12px;
    margin-top: 15px;
  }
  @media (max-width: 550px) {
    .live .info-container .content-container .details .lot-documents .row .documents-container .option .file-wrap p, .live .info-container .content-container .details .lot-documents .row .documents-container .option .file-wrap a {
      font-size: 9px;
    }
  }
  .live .info-container .content-container .details .lot-documents .row .documents-container .option:last-child {
    margin-bottom: 0;
  }
  .live .info-container .content-container .details .option-line {
    justify-content: space-between;
  }
  .live .info-container .content-container .details .option-line .option-title {
    min-width: 80px;
    font-size: 12px;
    flex: 2;
    word-break: initial;
  }
  @media (max-width: 550px) {
    .live .info-container .content-container .details .option-line .option-title {
      font-size: 9px;
      min-width: 100px;
      max-width: 100px;
    }
  }
  .live .info-container .content-container .details .option-line .option-value {
    font-size: 12px;
    flex: auto;
    text-align: left;
    flex-shrink: 1;
  }
  @media (max-width: 550px) {
    .live .info-container .content-container .details .option-line .option-value {
      font-size: 9px;
    }
  }
  .live .info-container .content-container .details .row {
    font-family: Lato;
  }
  .live .info-container .content-container .details .row .row-title-wrap {
    border-radius: 5px 5px 0px 0px;
    background: #2e2e2e;
  }
  .live .info-container .content-container .details .row .row-title-wrap .row-title {
    padding: 8px 10px;
    font: 400 14px "Lato";
  }
  .live .info-container .content-container .details .row .one-column {
    border-radius: 0px 0px 5px 5px;
  }
  .live .info-container .content-container .details .row .left-column {
    border-radius: 0px 0px 0px 5px;
  }
  .live .info-container .content-container .details .row .right-column {
    border-radius: 0px 0px 5px 0px;
  }
  
  .details-popup .info-container .content-container .details .lot-documents .row .documents-container {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 0 5px 5px;
    border: 1px solid #c6c6c6;
  }
  .details-popup .info-container .content-container .details .lot-documents .row .documents-container .empty-documents-wrapper {
    font-size: 12px;
  }
  .details-popup .info-container .content-container .details .lot-documents .row .documents-container .option {
    border-radius: 0;
    border: 0;
    background-color: #ededed;
    margin-bottom: 0;
  }
  .details-popup .info-container .content-container .details .lot-documents .row .documents-container .option .file-wrap {
    flex-direction: row;
    align-items: unset;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .details-popup .info-container .content-container .details .lot-documents .row .documents-container .option .file-wrap p, .details-popup .info-container .content-container .details .lot-documents .row .documents-container .option .file-wrap a {
    margin: 0;
    font-size: 12px;
  }
  .details-popup .info-container .content-container .details .lot-documents .row .documents-container .option:last-child {
    margin-bottom: 0;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .my-profile-container {
    background-color: #ededed;
    /* Create the checkmark/indicator (hidden when not checked) */
    /* Show the checkmark when checked */
    /* Style the checkmark/indicator */
  }
  .my-profile-container .icons-style {
    width: 30px;
    height: 30px;
    color: white;
    margin: auto 0px auto 10px;
  }
  .my-profile-container .header {
    display: flex;
    flex-direction: column;
  }
  .my-profile-container .header .title {
    color: #030404;
    font-family: Lato;
    font-size: 40px;
    font-weight: 400;
    margin: 0 0 20px 0;
  }
  @media (max-width: 550px) {
    .my-profile-container .header .title {
      font-size: 28px;
    }
  }
  .my-profile-container .header .description {
    color: #030404;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
  .my-profile-container .buttons {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin: 26px 0 20px 0;
    border-bottom: 1px solid #c6c6c6;
  }
  @media (max-width: 550px) {
    .my-profile-container .buttons {
      flex-direction: column;
    }
  }
  .my-profile-container .buttons .btn {
    margin: 0 10px 10px 0;
  }
  @media (max-width: 550px) {
    .my-profile-container .buttons .btn {
      width: 100%;
    }
  }
  .my-profile-container .buttons .back-btn {
    flex-shrink: unset;
  }
  .my-profile-container .buttons .back-btn .icon-wrapper {
    margin-right: 10px;
  }
  .my-profile-container .buttons .file-upload input {
    display: none;
  }
  .my-profile-container .buttons .logo-label {
    position: absolute;
  }
  .my-profile-container .logo {
    height: 126px;
    border-radius: 5px;
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  .my-profile-container .logo img {
    width: inherit;
    height: inherit;
  }
  .my-profile-container .user-id {
    text-align: center;
    color: #030404;
    font-family: Lato;
    font-size: 40px;
    font-weight: 500;
    margin: 25px 0px 50px 0px;
  }
  .my-profile-container .info-item {
    display: flex;
    flex-direction: column;
    border-radius: 5px 5px 0 0;
  }
  @media (max-width: 550px) {
    .my-profile-container .info-item {
      margin: unset;
    }
  }
  .my-profile-container .info-item .cd-header {
    display: flex;
    flex-direction: row;
    border-radius: 5px 5px 0 0;
    background-color: #2e2e2e;
    height: 54px;
  }
  .my-profile-container .info-item .cd-header .cd-title {
    color: #ffffff;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    margin: auto 0px auto 20px;
  }
  .my-profile-container .info-item .cd-data {
    border-radius: 0 0 5px 5px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 5px;
    padding: 0px 71px 0px 0px;
  }
  @media (max-width: 570px) {
    .my-profile-container .info-item .cd-data {
      padding: 0px 0px 0px 0px;
    }
  }
  .my-profile-container .inputs {
    border-radius: 5px;
    background-color: #c6c6c6;
    border: 0px;
    height: 44px;
    margin: auto 0px auto 0px;
    flex: 2;
    min-width: 271px;
    padding-left: 15px;
    font-family: Lato;
    font-size: 19px;
    font-weight: 400;
    line-height: 26.66px;
  }
  .my-profile-container .inputs.red {
    border: 1px solid red;
  }
  @media (max-width: 550px) {
    .my-profile-container .inputs {
      min-width: unset;
    }
  }
  .my-profile-container .right-inputs {
    background-color: #c6c6c6;
    border-radius: 5px;
    height: 44px;
    flex: 2;
    font-family: Lato;
    font-size: 19px;
    font-weight: 400;
    line-height: 26.66px;
  }
  .my-profile-container .input-container {
    border-radius: 5px;
    background-color: #c6c6c6;
    border: 0px;
    height: 44px;
    margin: auto 0px auto 0px;
    flex: 2 1;
    font-family: Lato;
    font-size: 19px;
    font-weight: 400;
    line-height: 26.66px;
  }
  .my-profile-container .columns {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 539px;
  }
  @media (max-width: 570px) {
    .my-profile-container .columns {
      min-width: 100%;
    }
  }
  .my-profile-container .action-row {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    min-height: 70px;
    width: 100%;
  }
  .my-profile-container .action-row-multiple {
    justify-content: space-between;
  }
  .my-profile-container .columns, .my-profile-container .action-row {
    padding-left: 10%;
  }
  @media (max-width: 570px) {
    .my-profile-container .columns, .my-profile-container .action-row {
      min-width: 100%;
      padding: 0 1% 0 1%;
    }
  }
  .my-profile-container .rows {
    height: 70px;
    display: flex;
    justify-content: space-between;
  }
  .my-profile-container .rows.no-margin {
    margin: 0;
  }
  .my-profile-container .checkboxes-container ~ .checkboxes-container {
    margin-left: 5px;
  }
  .my-profile-container .checkboxes-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 3px;
  }
  .my-profile-container .checkboxes-container.pic {
    justify-content: space-between;
  }
  .my-profile-container .checkboxes-container .checkboxes-text {
    color: #000000;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    margin-right: 5px;
  }
  @media (max-width: 550px) {
    .my-profile-container .checkboxes-container .checkboxes-text {
      font-size: 12px;
    }
  }
  .my-profile-container .checkboxes-container .input-container {
    min-width: unset;
    padding-left: 0px;
    width: 71px;
  }
  @media (max-width: 550px) {
    .my-profile-container .checkboxes-container .input-container {
      min-width: unset;
      padding-left: 0px;
    }
  }
  .my-profile-container .checkboxes-container .input-container label input {
    max-width: unset;
    width: 71px;
    padding: 0px 0px 0px 13px;
  }
  .my-profile-container .pointer {
    cursor: pointer;
  }
  .my-profile-container .checkboxes {
    width: 44px;
    height: 44px;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
  }
  .my-profile-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 44px;
    width: 44px;
    border-radius: 5px;
    background-color: #c6c6c6;
  }
  .my-profile-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .my-profile-container .checkbox-container input:checked ~ .checkmark:after {
    display: block;
    position: relative;
    background: url("../src/assets/img/black-icon-tick.png") no-repeat;
    background-color: #c6c6c6;
    background-size: cover;
    height: 20px;
    width: 27px;
  }
  .my-profile-container .checkbox-container .checkmark:after {
    left: 9px;
    top: 11px;
  }
  .my-profile-container .checkbox {
    height: 44px;
    width: 44px;
    visibility: hidden;
  }
  .my-profile-container .titles {
    width: 80px;
    color: #000000;
    font-family: Lato;
    font-size: 19px;
    font-weight: 400;
    line-height: 26.66px;
    flex: 1;
    word-wrap: break-word;
    min-width: 142px;
  }
  @media (max-width: 550px) {
    .my-profile-container .titles {
      min-width: unset;
      font-size: 15px;
    }
  }
  .my-profile-container .titles.red {
    color: red;
  }
  .my-profile-container .selectors {
    height: 44px;
    border: 1px solid #5d5d5d;
    width: 90px;
  }
  .my-profile-container .info-items {
    display: flex;
    flex-direction: column;
  }
  .my-profile-container .big-input {
    height: 193px;
    margin: 15px 0px 15px 0px;
  }
  .my-profile-container .documentation-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 2 1;
    margin: 15px 0px 15px 0px;
  }
  .my-profile-container .documentation-data .documentation-boxes {
    width: 100%;
  }
  .my-profile-container .documentation-data .documentation-boxes .small-texts {
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    font-weight: 100;
    line-height: 26.63px;
    margin: 0px 0px 0px 15px;
  }
  .my-profile-container .documentation-data .documentation-boxes .small-texts.red {
    color: red;
  }
  .my-profile-container .documentation-data .documentation-boxes .additional-information-inputs {
    width: 100%;
    border-radius: 5px;
    height: 44px;
    margin: auto 0px auto 0px;
    flex: 2;
  }
  .my-profile-container .documentation-data .documentation-boxes .additional-information-inputs .file-upload {
    width: auto;
    border-radius: 5px;
    background-color: #c6c6c6;
    overflow: auto;
  }
  .my-profile-container .documentation-data .documentation-boxes .additional-information-inputs .auth-file-upload {
    width: unset;
    max-width: unset;
    background-color: #c6c6c6;
    border-radius: 5px;
    overflow: hidden;
  }
  .my-profile-container .documentation-data .documentation-boxes .additional-information-inputs .auth-file-upload.red {
    border: 2px solid red;
  }
  .my-profile-container .documentation-data .documentation-boxes .additional-information-inputs .auth-file-upload .auth-upload-text {
    color: black;
  }
  @media (max-width: 550px) {
    .my-profile-container .documentation-data .documentation-boxes .additional-information-inputs .auth-file-upload .auth-upload-text {
      font-size: 12px;
    }
  }
  .my-profile-container .documentation-data .documentation-boxes .additional-information-inputs .auth-file-upload .auth-upload-text.link-document {
    width: 100%;
    text-decoration: none;
  }
  .my-profile-container .documentation-data .documentation-boxes .additional-information-inputs .auth-file-upload .auth-upload-button {
    height: 45px !important;
  }
  .my-profile-container .documentation-data .documentation-boxes .additional-information-inputs .auth-file-upload .document:hover {
    cursor: pointer;
  }
  .my-profile-container .documentation-data .documentation-boxes ~ .documentation-boxes {
    margin-top: 30px;
  }
  .my-profile-container .agency-wrapper {
    display: flex;
  }
  .my-profile-container .independent-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 2;
    align-items: center;
    min-width: 289px;
  }
  @media (max-width: 550px) {
    .my-profile-container .independent-wrapper {
      min-width: unset;
    }
  }
  .my-profile-container .dropdown-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .my-profile-container .dropdown-wrapper.pic-state {
    height: 44px;
    flex: 2;
    width: 100%;
  }
  .my-profile-container .dropdown-wrapper .fg-select-container {
    width: 130px;
  }
  .my-profile-container .dropdown-wrapper .fg-select-container.pic-state {
    width: 100%;
  }
  .my-profile-container .dropdown-wrapper .fg-select-container .fg-dropdown--is-disabled {
    opacity: 0.6;
  }
  .my-profile-container .dropdown-wrapper .fg-select-container .fg-dropdown__control {
    border: 1px solid #000000;
    background-color: #c6c6c6;
  }
  .my-profile-container .dropdown-wrapper .fg-select-container .fg-dropdown__indicators {
    border-left: 1px solid #000000;
  }
  .my-profile-container .dropdown-wrapper .fg-select-container .fg-dropdown__single-value {
    color: #000000;
  }
  .my-profile-container .dropdown-wrapper .checkboxes-text {
    color: #000000;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    margin-right: 5px;
  }
  .my-profile-container .dropdown-wrapper .input-container {
    min-width: unset;
    padding-left: 0px;
    width: 71px;
  }
  @media (max-width: 550px) {
    .my-profile-container .dropdown-wrapper .input-container {
      min-width: unset;
      padding-left: 0px;
    }
  }
  .my-profile-container .dropdown-wrapper .input-container label input {
    max-width: unset;
    width: 71px;
    padding: 0px 0px 0px 13px;
  }
  .my-profile-container .dropdown-wrapper.selected .fg-select-container .fg-dropdown__indicators {
    background-color: #1f9512;
  }
  .my-profile-container .dropdown-wrapper.selected .fg-select-container .fg-dropdown__arrow {
    color: #ffffff;
  }
  .my-profile-container .additional-information-rows {
    height: auto;
  }
  .my-profile-container .icon-upload {
    display: block;
    position: relative;
    background: url("../src/assets/img/icon-upload.png") no-repeat;
    background-size: cover;
    height: 19px;
    width: 13px;
  }
  .my-profile-container .icon-document {
    display: block;
    position: relative;
    background: url("../src/assets/img/document-icon.png") no-repeat;
    background-size: cover;
    height: 21px;
    width: 20px;
  }
  .my-profile-container .no-file {
    background-color: #c6c6c6;
  }
  .my-profile-container .agency {
    flex: 1;
  }
  .my-profile-container .blocked {
    pointer-events: none;
  }
  .my-profile-container .display-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 44px;
    margin: auto 0px auto 0px;
    flex: 2;
  }
  .my-profile-container .bold-font {
    color: #000000;
    font-family: Lato;
    font-size: 19px;
    font-weight: 700;
    line-height: 26.66px;
  }
  .my-profile-container .same-as-office-address {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-profile-container .helpers {
    width: 10px;
    height: 70px;
  }
  @media (max-width: 950px) {
    .my-profile-container .helpers {
      width: 0px;
      height: 0px;
    }
  }
  .my-profile-container .false {
    color: red !important;
  }
  .my-profile-container .info-item ~ .info-item {
    margin-top: 25px;
  }
  .my-profile-container .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
  }
  .my-profile-container .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .my-profile-container .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .my-profile-container .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .my-profile-container .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .my-profile-container .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .my-profile-container .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .my-profile-container .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .my-profile-container .btn.first-btn {
    margin-right: 8px;
  }
  .my-profile-container .margin-top-6px {
    margin-top: 6px;
  }
  .my-profile-container .button-text {
    color: #030404;
    font-family: "Myriad Pro";
    font-size: 15px;
    font-weight: 400;
  }
  @media (max-width: 950px) {
    .my-profile-container .inputs {
      margin: auto 0px auto 0px;
    }
  }
  
  @media (max-width: 550px) {
    .hide-on-mobile {
      display: none;
    }
  
    .select-component {
      width: 125px;
      margin-right: 4px;
    }
  }
  @media (max-width: 1273px) {
    .hide-on-mobile {
      display: none !important;
    }
  
    .big-input {
      margin-top: 15px !important;
    }
  }
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .main {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: stretch;
    overflow-x: hidden;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .app-route {
    position: relative;
  }
  .app-route .manage-lots .manage-list {
    position: relative;
    border-top: 1px solid #c6c6c6;
  }
  .app-route .manage-lots .manage-list .manage-item {
    display: flex;
    min-height: 8em;
    margin-top: 20px;
    border: 1px solid #c6c6c6;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
  }
  .app-route .manage-lots .manage-list .manage-item:hover {
    border: 1px solid lightgray;
  }
  .app-route .manage-lots .manage-list .manage-item .left {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    border-right: 1px solid #c6c6c6;
    background-color: #79603b;
    width: 292px;
    min-height: 133px;
    border-radius: 5px 0 0 5px;
  }
  .app-route .manage-lots .manage-list .manage-item .left .img-container {
    width: 72px;
    height: 91px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  @media (max-width: 450px) {
    .app-route .manage-lots .manage-list .manage-item .left .img-container {
      width: 45px;
      height: 71px;
    }
  }
  .app-route .manage-lots .manage-list .manage-item .right {
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex: 3;
    margin: 30px 30px;
  }
  .app-route .manage-lots .manage-list .manage-item .right .item-title, .app-route .manage-lots .manage-list .manage-item .right .item-desc {
    margin: 0;
  }
  .app-route .manage-lots .manage-list .manage-item .right .item-title {
    color: #000000;
    font-family: Lato;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #030404;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
  }
  .app-route .manage-lots .manage-list .manage-item .right .item-desc {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    color: #030404;
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
  }
  .app-route .manage-lots .manage-list .manage-item .add-lots-left {
    opacity: 0.44;
  }
  .app-route .manage-lots .manage-list .manage-item .add-lots-left .img-container {
    background-image: url("../src/assets/img/add_lot.png");
  }
  .app-route .manage-lots .manage-list .manage-item .my-submitted-lots-left {
    opacity: 0.52;
  }
  .app-route .manage-lots .manage-list .manage-item .my-submitted-lots-left .img-container {
    background-image: url("../src/assets/img/my-admin-my-submitted-lots.png");
  }
  .app-route .manage-lots .manage-list .manage-item .edit-saved-lots-left {
    opacity: 0.6;
  }
  .app-route .manage-lots .manage-list .manage-item .edit-saved-lots-left .img-container {
    background-image: url("../src/assets/img/my-admin-edit-saved-lots.png");
  }
  .app-route .manage-lots .manage-list .manage-item .my-auction-results-left {
    opacity: 0.68;
  }
  .app-route .manage-lots .manage-list .manage-item .my-auction-results-left .img-container {
    background-image: url("../src/assets/img/my-admin-my-auction-results.png");
  }
  .app-route .manage-lots .manage-list .manage-item .my-reoffers-left {
    opacity: 0.76;
  }
  .app-route .manage-lots .manage-list .manage-item .my-reoffers-left .img-container {
    background-image: url("../src/assets/img/my-admin-reoffers.png");
  }
  .app-route .manage-lots .manage-list .manage-item .my-listings-left {
    opacity: 0.84;
  }
  .app-route .manage-lots .manage-list .manage-item .my-listings-left .img-container {
    background-image: url("../src/assets/img/my-admin-my-listings.png");
  }
  .app-route .manage-lots .manage-list .manage-item .my-profile-left {
    opacity: 0.92;
  }
  .app-route .manage-lots .manage-list .manage-item .my-profile-left .img-container {
    background-image: url("../src/assets/img/my-admin-my-profile.png");
  }
  .app-route .manage-lots .manage-list .manage-item .my-favourite-pics-left {
    opacity: 0.95;
  }
  .app-route .manage-lots .manage-list .manage-item .my-favourite-pics-left .img-container {
    background-image: url("../src/assets/img/my-admin-favourite-pics.png");
  }
  .app-route .manage-lots .manage-list .manage-item .my-sounds-left .img-container {
    background-image: url("../src/assets/img/my-admin-sound.png");
  }
  .app-route .manage-lots .manage-list .manage-item .my-texts-left .img-container {
    background-image: url("../src/assets/img/my-admin-texts.png");
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .tools .tools-list {
    border-top: 1px solid #c6c6c6;
  }
  @media (max-width: 591px) {
    .tools .tools-list .section .body {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  @media (max-width: 591px) {
    .tools .tools-list .section .body .section-left {
      padding-left: 0;
    }
  }
  @media (max-width: 591px) {
    .tools .tools-list .section .body .section-left .icon-wrapper {
      margin-left: 0;
    }
  }
  @media (max-width: 591px) {
    .tools .tools-list .section .body .section-right {
      padding-left: 0;
    }
  }
  @media (max-width: 591px) {
    .tools .tools-list .section .body .section-right .icon-wrapper {
      margin-left: 0;
    }
  }
  .tools .section {
    border-radius: 5px;
    overflow: hidden;
    font-family: Lato;
    display: flex;
    flex-direction: column;
    min-height: 135px;
    margin-top: 20px;
    border: 1px solid #c6c6c6;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    background-color: white;
    font-size: 16px;
    font-weight: 400;
  }
  .tools .section .title {
    background: #b0aeaf;
    display: flex;
    align-items: center;
    padding-left: 20px;
    min-height: 35px;
    font-size: 23px;
  }
  .tools .section .body {
    background: #ffffff;
    padding: 20px;
  }
  .tools .section .title {
    background-color: #79603bb0;
  }
  .tools .section .body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1;
  }
  .tools .section .body span {
    margin-left: 20px;
  }
  .tools .section .body .download {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 215px;
    padding: 5px 0;
    text-decoration: none;
    color: #030404;
  }
  .tools .section .body .download .icon-wrapper {
    background-image: url("../src/assets/img/print-form.png");
    height: 56px;
    width: 56px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .tools .section .body .section-left {
    padding-left: 50px;
    flex: 1 1;
  }
  .tools .section .body .section-right {
    display: flex;
    align-items: center;
    padding-left: 50px;
    flex: 1 1;
  }
  .tools .section .icon {
    height: 70px;
    width: 70px;
    color: grey;
    opacity: 0.4;
  }
  .tools .manage-item {
    display: flex;
    min-height: 8em;
    margin-top: 20px;
    border: 1px solid #c6c6c6;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
  }
  .tools .manage-item:hover {
    border: 1px solid lightgray;
  }
  .tools .manage-item .left {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    border-right: 1px solid #c6c6c6;
    background-color: #79603b;
    width: 292px;
    min-height: 133px;
    border-radius: 5px 0 0 5px;
  }
  .tools .manage-item .left .img-container {
    width: 72px;
    height: 91px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  @media (max-width: 450px) {
    .tools .manage-item .left .img-container {
      width: 45px;
      height: 71px;
    }
  }
  .tools .manage-item .right {
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex: 3;
    margin: 30px 30px;
  }
  .tools .manage-item .right .item-title {
    margin: 0;
  }
  .tools .manage-item .right .item-title {
    color: #000000;
    font-family: Lato;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #030404;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
  }
  .tools .manage-item .forms .img-container {
    background-image: url("../src/assets/img/my-admin-my-submitted-lots.png");
  }
  .tools .manage-item .listing .img-container {
    background-image: url("../src/assets/img/tools-listing.png");
  }
  .tools .manage-item .terms .img-container {
    background-image: url("../src/assets/img/tools-conditions.png");
  }
  .tools .manage-item .getting-started .img-container {
    background-image: url("../src/assets/img/tools-getting-started.png");
  }
  .tools .manage-item .videos-tips .img-container {
    background-image: url("../src/assets/img/tools-videos.png");
  }
  .tools .manage-item .faqs .img-container {
    background-image: url("../src/assets/img/tools-faqs.png");
  }
  .tools .manage-item .search .img-container {
    background-image: url("../src/assets/img/tools-search.png");
  }
  .tools .manage-item .privacy-policy .img-container {
    background-image: url("../src/assets/img/tools-policy.png");
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .manuals-container {
    border-top: 1px solid #c6c6c6;
    padding-top: 20px;
  }
  .manuals-container .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    min-width: 183px;
    width: 183px;
  }
  .manuals-container .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .manuals-container .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .manuals-container .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .manuals-container .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .manuals-container .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .manuals-container .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .manuals-container .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .manuals-container .btn ~ .btn {
    margin-left: 10px;
  }
  @media (max-width: 650px) {
    .manuals-container .btn ~ .btn {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  .manuals-container .section {
    border-radius: 5px;
    overflow: hidden;
    font-family: Lato;
  }
  .manuals-container .section .title {
    background: #b0aeaf;
    display: flex;
    align-items: center;
    padding-left: 20px;
    min-height: 35px;
    font-size: 23px;
  }
  .manuals-container .section .body {
    background: #ffffff;
    padding: 20px;
  }
  .manuals-container .section .body {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 900px) {
    .manuals-container .section .body {
      flex-direction: column;
    }
  }
  .manuals-container .section .body .explanation {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
  .manuals-container .section .body .buttons {
    display: flex;
  }
  @media (max-width: 900px) {
    .manuals-container .section .body .buttons {
      justify-content: center;
      margin-top: 20px;
    }
  }
  @media (max-width: 650px) {
    .manuals-container .section .body .buttons {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  .manuals-container .section ~ .section {
    margin-top: 20px;
  }
  .manuals-container .section .title {
    background-color: #79603bb0;
  }
  
  .assessment-forms {
    margin-bottom: 20px;
  }
  .assessment-forms .section {
    border-radius: 5px;
    overflow: hidden;
    font-family: Lato;
    display: flex;
    flex-direction: column;
    min-height: 135px;
    margin-top: 20px;
    border: 1px solid #c6c6c6;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    background-color: white;
    font-size: 16px;
    font-weight: 400;
  }
  .assessment-forms .section .title {
    background: #b0aeaf;
    display: flex;
    align-items: center;
    padding-left: 20px;
    min-height: 35px;
    font-size: 23px;
  }
  .assessment-forms .section .body {
    background: #ffffff;
    padding: 20px;
  }
  .assessment-forms .section .title {
    background-color: #79603bb0;
  }
  .assessment-forms .section .body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1;
  }
  .assessment-forms .section .body span {
    margin-left: 20px;
  }
  .assessment-forms .section .body .download {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 215px;
    padding: 5px 0;
    text-decoration: none;
    color: #030404;
  }
  .assessment-forms .section .body .download .icon-wrapper {
    background-image: url("../src/assets/img/print-form.png");
    height: 56px;
    width: 56px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .assessment-forms .section .body .section-left {
    padding-left: 50px;
    flex: 1 1;
  }
  @media (max-width: 900px) {
    .assessment-forms .section .body .section-left {
      padding-left: 0;
    }
  }
  .assessment-forms .section .body .section-right {
    display: flex;
    align-items: center;
    padding-left: 50px;
    flex: 1 1;
  }
  @media (max-width: 900px) {
    .assessment-forms .section .body .section-right {
      padding-left: 0;
    }
  }
  @media (max-width: 900px) {
    .assessment-forms .section .body {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .assessment-forms .section .icon {
    height: 70px;
    width: 70px;
    color: grey;
    opacity: 0.4;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .listing-fees-container {
    border-top: 1px solid #c6c6c6;
    padding-top: 20px;
  }
  .listing-fees-container .section {
    border-radius: 5px;
    overflow: hidden;
    font-family: Lato;
    margin-bottom: 20px;
  }
  .listing-fees-container .section .title {
    background: #b0aeaf;
    display: flex;
    align-items: center;
    padding-left: 20px;
    min-height: 35px;
    font-size: 23px;
  }
  .listing-fees-container .section .body {
    background: #ffffff;
    padding: 20px;
  }
  .listing-fees-container .big-section {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    font-family: Lato;
  }
  .listing-fees-container .big-section .title {
    height: 58px;
    max-height: 58px;
    min-height: 58px;
    display: flex;
    align-items: center;
    flex: 1 1;
    background: #b0aeaf;
    border-radius: 5px 5px 0px 0px;
    padding: 10px;
  }
  @media (max-width: 550px) {
    .listing-fees-container .big-section .title {
      border-radius: 0;
    }
  }
  .listing-fees-container .big-section .title .icon-blackened {
    width: 50px;
    margin-right: 15px;
  }
  .listing-fees-container .big-section .title ~ .title {
    margin-top: 20px;
  }
  @media (max-width: 550px) {
    .listing-fees-container .big-section .title ~ .title {
      margin-left: 0;
    }
  }
  .listing-fees-container .big-section .body {
    background: white;
    padding-left: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .listing-fees-container .big-section .body-with-margins {
    margin-top: 20px;
    background: white;
    padding-left: 20px;
    border-radius: 5px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .policy-container {
    border-top: 1px solid #c6c6c6;
    padding-top: 20px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .search .table-wrapper .fg-table-container .ReactTable .rt-table .rt-thead {
    min-width: 910px !important;
  }
  .search .table-wrapper .fg-table-container .ReactTable .rt-table .rt-thead .rt-tr .rt-th {
    width: 9% !important;
    padding-left: 0 !important;
    text-align: center;
  }
  .search .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody {
    min-width: 910px !important;
    overflow: unset;
  }
  .search .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
    width: 9% !important;
  }
  
  .search-container {
    border-top: 1px solid #c6c6c6;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-bottom: 40px;
  }
  .search-container .input {
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    padding: 25px 15px 25px 10px;
    font: 400 14px Lato;
    color: #666666;
    background-color: #ededed;
    height: 44px;
    width: 230px;
    margin-right: 50px;
  }
  .search-container .dropdown {
    font: 400 14px Lato;
    color: #666666;
    background-color: #ededed;
    height: 44px;
    width: 230px;
    margin-right: 50px;
  }
  .search-container .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    min-width: 183px;
    width: 230px;
  }
  .search-container .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .search-container .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .search-container .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .search-container .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .search-container .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .search-container .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .search-container .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  @media (max-width: 1000px) and (min-width: 900px) {
    .search-container .btn {
      width: 183px;
    }
  }
  @media (max-width: 650px) {
    .search-container .btn {
      margin-top: 20px;
    }
  }
  .search-container .search {
    margin-right: 10px;
  }
  .search-container .explanation {
    flex: 1 1;
  }
  .search-container .buttons {
    flex: 2 2;
  }
  .search-container .section {
    border-radius: 5px;
    overflow: hidden;
    font-family: Lato;
    overflow: visible;
  }
  .search-container .section .title {
    background: #b0aeaf;
    display: flex;
    align-items: center;
    padding-left: 20px;
    min-height: 35px;
    font-size: 23px;
  }
  .search-container .section .body {
    background: #ffffff;
    padding: 20px;
  }
  @media (max-width: 900px) {
    .search-container .section .title {
      font-size: 19px;
    }
  }
  .search-container .section .body {
    padding-left: 40px;
    padding-bottom: 0;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 550px) {
    .search-container .section .body {
      flex-direction: column;
    }
  }
  .search-container .section .body.first {
    padding-top: 30px;
  }
  .search-container .section .body.button-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 650px) {
    .search-container .section .body.button-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  .search-container .section .body .explanation {
    display: flex;
    align-items: center;
  }
  @media (max-width: 900px) {
    .search-container .section .body .buttons {
      justify-content: center;
      margin-top: 20px;
    }
  }
  .search-container .section ~ .section {
    margin-top: 20px;
  }
  
  .tips-container div .section-container {
    margin-bottom: 20px;
  }
  
  .faqs-container .redtext {
    color: red;
  }
  .faqs-container .section-container .body .accordion .paragraph:first-child {
    padding-right: 25px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .u-position-absolute {
    position: absolute;
  }
  
  .u-position-relative {
    position: relative;
  }
  
  .accordion__item--has-icon {
    position: relative;
  }
  
  .accordion__title {
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
  }
  
  .accordion__body {
    padding-left: 20px;
    padding-right: 60px;
    display: block;
    animation: fadein 0.35s ease-in;
  }
  
  .accordion__body--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.35s ease-in;
  }
  
  .accordion__title > *:last-child,
  .accordion__body > *:last-child {
    margin-bottom: 0;
  }
  
  .accordion__arrow {
    display: inline-block;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
  }
  
  .accordion__arrow::after,
  .accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: "";
  }
  
  .accordion__arrow::before {
    left: 4px;
    transform: rotate(45deg);
  }
  
  [aria-expanded=true] .accordion__arrow::before, [aria-selected=true] .accordion__arrow::before {
    transform: rotate(-45deg);
  }
  
  .accordion__arrow::after {
    right: 4px;
    transform: rotate(-45deg);
  }
  
  [aria-expanded=true] .accordion__arrow::after, [aria-selected=true] .accordion__arrow::after {
    transform: rotate(45deg);
  }
  
  .accordion__arrow::before,
  .accordion__arrow::after {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  }
  
  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes move-down {
    0% {
      transform: translateY(0);
    }
    10% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(5px);
    }
    30% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes move-up {
    0% {
      transform: translateY(0);
    }
    10% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-5px);
    }
    30% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
  .accordion__title--animated:hover .accordion__arrow {
    animation-name: move-down;
    animation-duration: 1.5s;
  }
  
  .accordion__title--animated[aria-expanded=true]:hover .accordion__arrow {
    animation-name: move-up;
    animation-duration: 1.5s;
  }
  
  .section-container {
    border-radius: 5px;
    overflow: hidden;
    font-family: Lato;
  }
  .section-container .title {
    background: #b0aeaf;
    display: flex;
    align-items: center;
    padding-left: 20px;
    min-height: 35px;
    font-size: 23px;
  }
  .section-container .body {
    background: #ffffff;
    padding: 20px;
  }
  .section-container .text {
    margin-left: 20px;
    line-height: 2;
    text-align: justify;
  }
  .section-container .text p {
    margin: 0;
  }
  @media (max-width: 580px) {
    .section-container .title {
      font-size: 16px;
      min-height: 50px;
      padding-left: 10px;
    }
  }
  .section-container .body .paragraph:first-child {
    margin: 0 0 10px 0;
    font-weight: 700;
  }
  .section-container .body .paragraph {
    margin: 25px 0 10px 0;
    font-weight: 700;
    position: relative;
  }
  .section-container .body .with-number {
    display: flex;
  }
  .section-container .body ul {
    list-style-type: disc;
    margin-left: 25px;
  }
  .section-container .body .accordion .paragraph:first-child {
    margin: 0;
  }
  
  .section-container ~ .section-container {
    margin-top: 20px;
  }
  
  .video-section-container .body {
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .video-section-container .body .nav-next {
    height: 80%;
  }
  .video-section-container .body .nav-prev {
    height: 80%;
  }
  .video-section-container .body .carousel-item .date {
    display: none;
  }
  .video-section-container .body .dots-wrap {
    display: none;
  }
  
  .terms-container .section-container .body .text ~ .paragraph {
    margin-top: 25px;
  }
  .terms-container .section-container .body .with-number {
    display: flex;
  }
  .terms-container .section-container .body ul {
    list-style-type: disc;
    margin-left: 50px;
  }
  .terms-container .section-container .body ol {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .terms-container .section-container .body ol li:first-child {
    margin-right: 5px;
  }
  .terms-container .section-container p {
    margin: 0;
  }
  .terms-container .section-container .text p {
    margin-bottom: 20px;
  }
  .terms-container .section-container .text p:last-child {
    margin-bottom: 0;
  }
  .terms-container .section-container .text p sub {
    font-size: 16px;
    bottom: 0;
    margin-right: 20px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .submitted-wrapper .top-btns {
    margin-bottom: 10px;
  }
  .submitted-wrapper .watch-info .watch-auction .watch-auction-info-top {
    flex-wrap: wrap;
    background-color: #c6c6c6;
    color: #2e2e2e;
  }
  .submitted-wrapper .watch-info .watch-auction .watch-auction-info-top .watch-auction-kind {
    border-right: 0;
  }
  .submitted-wrapper .watch-info .watch-auction .watch-auction-info-top .watch-auction-kind img {
    filter: brightness(0);
  }
  .submitted-wrapper .watch-info .watch-auction .watch-auction-info-top .watch-auction-info h3 {
    color: #2e2e2e;
  }
  @media (max-width: 500px) {
    .submitted-wrapper .watch-info .watch-auction .watch-auction-info-top .watch-auction-info h3 {
      font-size: 20px;
      word-wrap: break-word;
    }
  }
  .submitted-wrapper .watch-info .watch-auction .watch-auction-info-top .watch-auction-info p {
    color: #2e2e2e;
  }
  @media (max-width: 500px) {
    .submitted-wrapper .watch-info .watch-auction .watch-auction-info-top .watch-auction-info p {
      font-size: 15px;
    }
  }
  @media (max-width: 550px) {
    .submitted-wrapper .watch-info .watch-auction .watch-lots .lot-item .lot-info .lot-info-bottom {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  @media (max-width: 550px) {
    .submitted-wrapper .watch-info .watch-auction .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .submitted-wrapper .lot-info .lot-info-top .left {
    display: flex;
  }
  .submitted-wrapper .lot-info .lot-info-top .left .lot-number {
    margin-right: 20px;
  }
  @media (max-width: 550px) {
    .submitted-wrapper .lot-info .lot-info-top .left .lot-number {
      align-self: center;
    }
  }
  .submitted-wrapper .lot-info .lot-info-top .status {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 149px;
    height: 31px;
    padding: 1px;
    border-radius: 5px;
    color: #ffffff;
    font-family: Lato;
    font-size: 14px;
    font-weight: 100;
  }
  .submitted-wrapper .lot-info .lot-info-top .status.submitted {
    border: 1px solid #00981d;
    background-color: #00981d;
    letter-spacing: -0.28px;
  }
  .submitted-wrapper .lot-info .lot-info-top .status.pending {
    border: 1px solid #f59003;
    background-color: #f59003;
  }
  .submitted-wrapper .lot-info .lot-info-top .submitted-at {
    padding-top: 4px;
    margin-left: 20px;
    color: #ffffff;
    font-family: Lato;
    font-size: 18px;
    font-weight: 100;
  }
  @media (max-width: 550px) {
    .submitted-wrapper .lot-info .lot-info-top .submitted-at {
      font-size: 11px;
      align-self: center;
    }
  }
  @media (max-width: 550px) {
    .submitted-wrapper .lot-info .lot-info-top {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 550px) {
    .submitted-wrapper .lot-info .lot-info-top .lot-location {
      margin-top: 5px;
    }
  }
  .submitted-wrapper .lot-info .lot-info-middle {
    font-family: Lato;
    color: #696969;
    font-size: 16px;
    font-weight: 400;
  }
  .submitted-wrapper .lot-info .lot-info-middle .lot-title {
    min-height: 27px;
    margin: 16px 0 16px !important;
  }
  .submitted-wrapper .lot-info .lot-info-middle .lot-desc {
    min-height: 18px;
    margin-bottom: 5px;
  }
  .submitted-wrapper .lot-info .lot-info-middle .vendor-name {
    min-height: 18px;
  }
  .submitted-wrapper .lot-info .lot-info-bottom {
    padding: 10px 10px 10px 20px !important;
  }
  .submitted-wrapper .lot-info .lot-info-bottom .info-btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .submitted-wrapper .lot-info .lot-info-bottom .info-btns .btn {
    margin-top: 0;
  }
  .submitted-wrapper .lot-info .lot-info-bottom .info-btns .btn:not(:last-of-type) {
    margin-right: 10px;
  }
  @media (max-width: 550px) {
    .submitted-wrapper .lot-info .lot-info-bottom .info-btns .btn:not(:first-of-type) {
      margin-top: 10px;
    }
  }
  .submitted-wrapper .lot-info .lot-info-bottom .info-btns .icon-character {
    color: #000;
    font-size: 30px;
    font-weight: bold;
  }
  .submitted-wrapper .lot-info .lot-info-bottom .icon-character {
    color: #000;
    font-size: 30px;
    font-weight: bold;
  }
  .submitted-wrapper .lot-info .lot-info-bottom .status.submitted {
    background-color: #00981d;
  }
  .submitted-wrapper .lot-info .lot-info-bottom .status.pending {
    background-color: #f59003;
  }
  .submitted-wrapper .my-profile-container {
    margin: 0;
  }
  .submitted-wrapper .my-profile-container .titles {
    min-width: 0;
  }
  .submitted-wrapper .my-profile-container .additional-information-inputs {
    height: 70px;
    display: flex;
    flex-direction: row-reverse;
    margin-left: auto;
  }
  .submitted-wrapper .my-profile-container .additional-information-inputs .auth-file-upload {
    align-self: center;
    width: 230px;
    margin: 0;
    background-color: #ffffff;
    color: black;
    border-radius: 5px;
  }
  .submitted-wrapper .my-profile-container .fg-table-container {
    width: 100%;
  }
  .submitted-wrapper .my-profile-container .fg-table-container .fa-times {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    color: #dd2d2e;
    cursor: pointer;
  }
  .submitted-wrapper .my-profile-container .cd-data.table {
    padding: 20px 20px 20px 20px;
    margin-top: 30px;
    border-radius: 5px;
  }
  .submitted-wrapper .line {
    border-width: 0px;
  }
  .submitted-wrapper .edit-btn {
    text-decoration: none;
  }
  .submitted-wrapper .btn {
    width: 184px;
    height: 35px;
  }
  .submitted-wrapper .btn.edit-price {
    margin-bottom: 8px;
  }
  
  .row-filters {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 15vw;
  }
  
  .filter-button {
    margin-top: 120px;
    margin-left: 50px;
    width: 110px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
  }
  
  .filter-button1 {
    margin-top: 30px;
    margin-left: 50px;
    width: 110px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
  }
  
  .filter-buttons {
    display: flex;
    flex-direction: column;
  }
  
  .show-calendars1 {
    margin-right: 50px;
    width: 110px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
  }
  
  /*@media (max-width: 700px) {
    .row-filters {
      flex-direction: column;
      margin-left: 5%
    }
    .filter-buttons {
      flex-direction: row;
    }
    .filter-button {
      margin-left: 20px;
      margin-top: 10px;
    }
    .filter-button1 {
      margin-top: 10px;
      margin-left: 20px;
    }
  }*/
  @media (max-width: 950px) {
    .row-filters {
      flex-direction: column;
      margin-left: 15vw;
    }
  
    .filter-buttons {
      flex-direction: row;
    }
  
    .filter-button {
      margin-left: 20px;
      margin-top: 10px;
    }
  
    .filter-button1 {
      margin-top: 10px;
      margin-left: 20px;
    }
  
    .filters-heading {
      margin-right: 45vw !important;
    }
  }
  @media (max-width: 400px) {
    .row-filters {
      flex-direction: column;
      margin-left: 5vw;
    }
  
    .filter-buttons {
      flex-direction: row;
    }
  
    .filter-button {
      margin-left: 20px;
      margin-top: 10px;
    }
  
    .filter-button1 {
      margin-top: 10px;
      margin-left: 20px;
    }
  
    .filters-heading {
      margin-right: 15vw !important;
    }
  }
  .filters-heading {
    margin: 0;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
  
  @media (max-width: 700px) {
    .row-filters {
      flex-direction: column;
      margin-left: 25vw;
    }
  
    .filter-buttons {
      flex-direction: row;
    }
  
    .filter-button {
      margin-left: 20px;
      margin-top: 10px;
    }
  
    .filter-button1 {
      margin-top: 10px;
      margin-left: 20px;
    }
  
    .filters-heading {
      margin-right: 15vw !important;
    }
  }
  .long-but {
    min-height: 44px;
    max-width: 150px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .my-favpics-container .my-profile-container {
    padding-top: 35px;
    border-top: 1px solid #c6c6c6;
  }
  .my-favpics-container .my-profile-container .info-items .info-item .cd-data {
    max-width: 1180px !important;
  }
  .my-favpics-container .my-profile-container .rows .dropdown-btn {
    margin-top: 13px;
    width: 100% !important;
    min-height: 44px;
  }
  .my-favpics-container .my-profile-container .rows .dropdown-btn-md {
    max-width: 100% !important;
  }
  .my-favpics-container .my-profile-container .rows .fg-select-container {
    flex: 2 2;
    margin-top: 13px;
  }
  .my-favpics-container .my-profile-container .rows .fg-select-container .fg-dropdown--is-disabled {
    opacity: 0.6;
  }
  .my-favpics-container .my-profile-container .rows .fg-select-container .fg-dropdown__control {
    border: 1px solid #000000;
    background-color: #c6c6c6;
  }
  .my-favpics-container .my-profile-container .rows .fg-select-container .fg-dropdown__indicators {
    border-left: 1px solid #000000;
  }
  .my-favpics-container .my-profile-container .rows .fg-select-container .fg-dropdown__single-value {
    color: #000000;
  }
  .my-favpics-container .my-profile-container .rows.selected .fg-select-container .fg-dropdown__indicators {
    background-color: #1f9512;
  }
  .my-favpics-container .my-profile-container .rows.selected .fg-select-container .fg-dropdown__arrow {
    color: #ffffff;
  }
  .my-favpics-container .my-profile-container .rows .additional-information-inputs {
    margin: auto 0px auto 0px !important;
  }
  .my-favpics-container .my-profile-container .auth-file-upload {
    color: black;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
  }
  .my-favpics-container .my-profile-container .auth-file-upload .auth-upload-text {
    width: 150px;
  }
  .my-favpics-container .table {
    border-radius: 5px !important;
    margin-top: 35px !important;
    padding: 30px !important;
    z-index: 0;
  }
  @media (max-width: 900px) {
    .my-favpics-container .table {
      padding: 5px !important;
    }
  }
  .my-favpics-container .table .rt-noData {
    display: none;
  }
  .my-favpics-container .table .fg-table-container {
    width: 100%;
  }
  .my-favpics-container .table .fg-table-container .ReactTable .rt-table .rt-thead {
    min-width: 910px !important;
  }
  .my-favpics-container .table .fg-table-container .ReactTable .rt-table .rt-thead .rt-tr .rt-th {
    width: 9% !important;
    padding-left: 0 !important;
    text-align: center;
  }
  .my-favpics-container .table .fg-table-container .ReactTable .rt-table .rt-tbody {
    min-width: 910px !important;
    overflow: unset;
  }
  .my-favpics-container .table .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
    width: 9% !important;
  }
  .my-favpics-container .table .fg-table-container .action-cell {
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
  .my-favpics-container .table .fg-table-container .action-cell .action-btn {
    width: 22px;
    height: 22px;
    margin-top: -3px;
    cursor: pointer;
  }
  .my-favpics-container .table .fg-table-container .action-cell .action-btn-edit {
    width: 20px;
    height: 20px;
    color: #fac917;
  }
  .my-favpics-container .table .fg-table-container .action-cell .action-btn-delete {
    color: #dd2d2e;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .my-results-wrapper .separator {
    margin: 10px 0 0 0;
  }
  .my-results-wrapper .link {
    color: #007fc1;
    cursor: pointer;
    text-decoration: underline;
  }
  .my-results-wrapper .link.noBid {
    color: #dd2d2e;
  }
  .my-results-wrapper .link.passed {
    color: #f9891a;
  }
  .my-results-wrapper .link.withdrawn {
    color: #676767;
  }
  .my-results-wrapper .link.sold {
    color: #1f9512;
  }
  .my-results-wrapper .table-wrapper {
    background: white;
    padding: 24px 23px;
    margin-top: 24px;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-thead {
    min-width: 910px !important;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-thead .rt-tr .rt-td {
    padding-top: 17px;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-thead .rt-tr .rt-th {
    width: 10% !important;
    padding-left: 0 !important;
    text-align: center;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody {
    min-width: 910px !important;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody p {
    margin: 0;
    margin-bottom: 5px;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group {
    min-height: 69px;
    margin-bottom: 3px;
    border: none;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd, .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even {
    margin-bottom: 0;
    border: none;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-expandable {
    height: 70px;
    padding: 0;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .expander-symbol {
    margin-top: calc(50% - 11px);
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .expander-symbol span {
    display: flex;
    font-size: 36px;
    justify-content: center;
    align-items: flex-start;
    color: grey;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .custom-rt-td {
    background: #ededed;
    border: 1px solid #c6c6c6;
    border-bottom: none;
    border-top: none;
    margin-left: 3px;
    padding-left: 8px;
    height: 150px;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .custom-rt-td:first-child {
    margin-left: 0;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .custom-rt-td.first-connected {
    border-right: 1px solid #c6c6c6;
    border-top: 1px solid #c6c6c6;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .custom-rt-td.connected {
    margin: 0;
    padding-right: 3px;
    border: none;
    border-top: 1px solid #c6c6c6;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .custom-rt-td.last-connected {
    margin: 0;
    padding-right: 3px;
    border-left: none;
    border-top: 1px solid #c6c6c6;
  }
  .my-results-wrapper .table-wrapper .fg-table-container .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
    width: 10% !important;
  }
  .my-results-wrapper .table-wrapper .rt-tfoot {
    display: none;
  }
  .my-results-wrapper .filter-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .my-results-wrapper .top-btns {
    display: flex;
    flex-wrap: wrap;
  }
  .my-results-wrapper .top-btns .download-wrapper {
    margin-right: 10px;
  }
  @media (max-width: 550px) {
    .my-results-wrapper .top-btns .btn {
      margin-right: 0;
      width: 100%;
    }
    .my-results-wrapper .top-btns .download-wrapper {
      margin-right: 0;
    }
  }
  .my-results-wrapper .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin: 0 10px 10px 0;
  }
  .my-results-wrapper .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .my-results-wrapper .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .my-results-wrapper .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .my-results-wrapper .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .my-results-wrapper .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .my-results-wrapper .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .my-results-wrapper .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .my-results-wrapper .filter-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin: 0;
    padding-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }
  .my-results-wrapper .filter-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .my-results-wrapper .filter-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .my-results-wrapper .filter-btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .my-results-wrapper .filter-btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .my-results-wrapper .filter-btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .my-results-wrapper .filter-btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .my-results-wrapper .filter-btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .my-results-wrapper .filter-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 550px) {
    .my-results-wrapper .filter-btn {
      width: 100%;
    }
  }
  @media (min-width: 551px) {
    .my-results-wrapper .filter-btn:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  .my-results-wrapper .filter-btn:hover:not(.btn-opened) {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .my-results-wrapper .filter-btn:hover:not(.btn-opened) .icon-wrapper {
    background-color: #fac917;
  }
  .my-results-wrapper .filter-btn .icon-wrapper {
    transition: 200ms;
    color: #5d5d5d;
  }
  @media (min-width: 550px) and (max-width: 900px) {
    .my-results-wrapper .filter-btn .icon-wrapper {
      margin-left: 10px;
    }
  }
  @media (min-width: 550px) {
    .my-results-wrapper .filter-btn-sm {
      max-width: 120px;
    }
    .my-results-wrapper .filter-btn-md {
      max-width: 155px;
    }
    .my-results-wrapper .filter-btn-lg {
      max-width: 230px;
    }
  }
  .my-results-wrapper .filter-btn-active:not(.btn-opened) {
    border-color: #1f9512;
  }
  .my-results-wrapper .filter-btn-active .icon-wrapper, .my-results-wrapper .filter-btn-active:hover .icon-wrapper {
    background-color: #1f9512;
    border-color: #1f9512;
  }
  .my-results-wrapper .filter-btn-active .icon-wrapper > svg, .my-results-wrapper .filter-btn-active .icon-wrapper > img, .my-results-wrapper .filter-btn-active:hover .icon-wrapper > svg, .my-results-wrapper .filter-btn-active:hover .icon-wrapper > img {
    filter: invert(100) brightness(200);
  }
  @media (max-width: 550px) {
    .my-results-wrapper .filter-btn {
      margin-right: 0;
    }
  }
  .my-results-wrapper .filter-btn.withdrawn {
    padding-left: 3px;
  }
  .my-results-wrapper .status-btn:hover .icon-wrapper, .my-results-wrapper .status-btn.active .icon-wrapper {
    color: white;
    border-left: none;
  }
  .my-results-wrapper .status-btn:hover.bought, .my-results-wrapper .status-btn.active.bought {
    border-color: #007fc1;
  }
  .my-results-wrapper .status-btn:hover.bought .icon-wrapper, .my-results-wrapper .status-btn.active.bought .icon-wrapper {
    background-color: #007fc1;
  }
  .my-results-wrapper .status-btn:hover.sold, .my-results-wrapper .status-btn.active.sold {
    border-color: #1f9512;
  }
  .my-results-wrapper .status-btn:hover.sold .icon-wrapper, .my-results-wrapper .status-btn.active.sold .icon-wrapper {
    background-color: #1f9512;
  }
  .my-results-wrapper .status-btn:hover.noBid, .my-results-wrapper .status-btn.active.noBid {
    border-color: #dd2d2e;
  }
  .my-results-wrapper .status-btn:hover.noBid .icon-wrapper, .my-results-wrapper .status-btn.active.noBid .icon-wrapper {
    background-color: #dd2d2e;
  }
  .my-results-wrapper .status-btn:hover.passed, .my-results-wrapper .status-btn.active.passed {
    border-color: #f9891a;
  }
  .my-results-wrapper .status-btn:hover.passed .icon-wrapper, .my-results-wrapper .status-btn.active.passed .icon-wrapper {
    background-color: #f9891a;
  }
  .my-results-wrapper .status-btn:hover.withdrawn, .my-results-wrapper .status-btn.active.withdrawn {
    border-color: #676767;
  }
  .my-results-wrapper .status-btn:hover.withdrawn .icon-wrapper, .my-results-wrapper .status-btn.active.withdrawn .icon-wrapper {
    background-color: #676767;
    position: relative;
  }
  .my-results-wrapper .status-btn:hover.withdrawn .icon-wrapper:after, .my-results-wrapper .status-btn.active.withdrawn .icon-wrapper:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 150%;
    background: #ffffff;
    transform: rotate(-45deg);
  }
  .my-results-wrapper .rt-table .withdrawn {
    text-decoration: line-through;
  }
  .my-results-wrapper .agent-buyer-losing-name .sold, .my-results-wrapper .agent-buyer-losing-phone .sold, .my-results-wrapper .agent-number-name .sold {
    color: #1f9512;
  }
  .my-results-wrapper .agent-buyer-losing-name .passed, .my-results-wrapper .agent-buyer-losing-phone .passed, .my-results-wrapper .agent-number-name .passed {
    color: #f9891a;
  }
  .my-results-wrapper .agent-buyer-losing-name .bought, .my-results-wrapper .agent-buyer-losing-phone .bought, .my-results-wrapper .agent-number-name .bought {
    color: #007fc1;
  }
  .my-results-wrapper .lot-status {
    text-transform: uppercase;
  }
  .my-results-wrapper .lot-status .sold {
    color: #1f9512;
  }
  .my-results-wrapper .lot-status .soldAfterAuction {
    color: #1f9512;
  }
  .my-results-wrapper .lot-status .passed {
    color: #f9891a;
  }
  .my-results-wrapper .lot-status .withdrawn {
    color: #676767;
    text-decoration: line-through;
  }
  .my-results-wrapper .lot-status .noBid {
    color: #dd2d2e;
  }
  .my-results-wrapper .lot-status .bought {
    color: #007fc1;
  }
  .my-results-wrapper .lot-filters .title {
    font-family: "Lato";
    text-align: center;
    font-size: 24px;
  }
  
  .lot-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
  }
  
  @media (max-width: 950px) {
    .filters-heading {
      margin-right: 45vw !important;
    }
  }
  @media (max-width: 400px) {
    .filters-heading {
      margin-right: 15vw !important;
    }
  }
  @media (max-width: 700px) {
    .filters-heading {
      margin-right: 15vw !important;
    }
  }
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .listings-wrapper {
    font-family: Lato;
  }
  .listings-wrapper .separator {
    margin: 24px 0;
  }
  .listings-wrapper .table-wrapper {
    background: white;
    padding: 24px 47px;
  }
  .listings-wrapper .table-wrapper .rt-tfoot {
    display: none;
  }
  .listings-wrapper .table-wrapper .rt-thead {
    position: sticky;
    z-index: 100;
  }
  .listings-wrapper .table-wrapper .rt-thead:first-child {
    top: 0;
  }
  .listings-wrapper .table-wrapper .rt-thead:first-child .rt-tr {
    display: flex;
    justify-content: center;
  }
  .listings-wrapper .table-wrapper .rt-thead:first-child .rt-tr .rt-th {
    max-width: 200px !important;
    min-width: 200px !important;
    width: unset !important;
  }
  .listings-wrapper .table-wrapper .rt-thead.-header {
    top: 45px;
  }
  .listings-wrapper .table-wrapper .rt-thead .-headerGroups {
    background: red;
  }
  @media (max-width: 840px) {
    .listings-wrapper .table-wrapper .rt-thead {
      min-width: 720px !important;
    }
    .listings-wrapper .table-wrapper .rt-thead .rt-tr .rt-th {
      max-width: 100px !important;
      min-width: 100px !important;
      width: unset !important;
    }
  }
  .listings-wrapper .table-wrapper .rt-tbody {
    overflow: initial;
  }
  @media (max-width: 840px) {
    .listings-wrapper .table-wrapper .rt-tbody {
      min-width: 720px !important;
    }
    .listings-wrapper .table-wrapper .rt-tbody .rt-tr-group .rt-tr .rt-td {
      max-width: 100px !important;
      min-width: 100px !important;
      width: unset !important;
    }
  }
  
  .broken {
    text-decoration: line-through;
  }
  
  .sound-wrapper {
    background: white;
    margin-top: 40px;
    padding: 24px 47px;
    min-height: 800px;
    font-family: "Lato";
  }
  @media screen and (max-width: 550px) {
    .sound-wrapper {
      padding-left: 10px;
    }
  }
  .sound-wrapper .toggle-header {
    margin-top: 40px;
    font-size: 24px;
  }
  .sound-wrapper .sound-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .sound-wrapper .sound-item .sound-item-title {
    margin-right: 100px;
    width: 300px;
  }
  @media screen and (max-width: 550px) {
    .sound-wrapper .sound-item .sound-item-title {
      margin-right: 20px;
      min-width: 100px;
    }
  }
  .sound-wrapper .sound-item .sound-item-title .sound-item-title-main {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .sound-wrapper .sound-item-toggle {
    display: flex;
    align-items: center;
  }
  .sound-wrapper .sound-item-toggle span {
    margin: 0 20px;
  }
  @media screen and (max-width: 550px) {
    .sound-wrapper .sound-item-toggle span {
      margin: 0 10px;
    }
  }
  .sound-wrapper p {
    margin: 0;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .change-forgotten .background-wrapper {
    align-items: center;
  }
  .change-forgotten .background-wrapper .content {
    margin: 15px 15px 15px 15px;
  }
  .change-forgotten .background-wrapper .content .form-wrapper {
    top: 40%;
  }
  .change-forgotten .background-wrapper .content .form-wrapper h2, .change-forgotten .background-wrapper .content .form-wrapper h3 {
    margin: 0;
  }
  .change-forgotten .background-wrapper .content .form-wrapper p {
    margin-bottom: 0;
  }
  .change-forgotten .background-wrapper .content .form-wrapper form .title {
    text-align: center;
  }
  .change-forgotten .background-wrapper .content .form-wrapper form a {
    color: #1f9512;
    font-size: 18px;
    font-weight: 700;
  }
  .change-forgotten .background-wrapper .content .form-wrapper form .input-container {
    margin-bottom: 30px;
  }
  .change-forgotten .background-wrapper .content .form-wrapper form .change-pass {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: 12px 15px;
    border-radius: 2px;
    background-color: #1f9512;
    border: none;
    color: #ffffff;
    font: 400 18px Lato;
    text-transform: uppercase;
    transition: background-color 0.2s linear;
    text-decoration: none;
    text-align: center;
    margin: 60px 0 25px 0;
  }
  .change-forgotten .background-wrapper .content .form-wrapper form .change-pass:hover {
    background-color: #25b215;
  }
  .change-forgotten .background-wrapper .content .form-wrapper form .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .change-forgotten .background-wrapper .content .form-wrapper form .navigation .continue {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: 12px 15px;
    border-radius: 2px;
    background-color: #1f9512;
    border: none;
    color: #ffffff;
    font: 400 18px Lato;
    text-transform: uppercase;
    transition: background-color 0.2s linear;
    text-decoration: none;
    text-align: center;
    margin: 0;
    width: 130px;
  }
  .change-forgotten .background-wrapper .content .form-wrapper form .navigation .continue:hover {
    background-color: #25b215;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .watchlist-filters-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .watchlist-filters-wrapper > div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 550px) {
    .watchlist-filters-wrapper > div {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  @media (min-width: 551px) {
    .watchlist-filters-wrapper .filter-range-wrapper-md {
      max-width: 166px;
    }
    .watchlist-filters-wrapper .filter-range-wrapper-md .filter-btn-md {
      max-width: 166px;
    }
    .watchlist-filters-wrapper .filter-multi-wrapper-md {
      max-width: 166px;
    }
    .watchlist-filters-wrapper .filter-multi-wrapper-md .filter-btn-md {
      max-width: 166px;
    }
    .watchlist-filters-wrapper .filter-btn:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  
  .watch-auction-info-top {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    padding: 10px;
    border-radius: 5px;
    background-color: #007fc1;
    margin: 25px 0 13px;
  }
  @media (min-width: 550px) {
    .watch-auction-info-top {
      flex-direction: row;
      align-items: center;
      padding: 3px 0;
    }
  }
  .watch-auction-info-top > div:not(.info-btns) {
    display: flex;
    flex: 1;
    align-self: stretch;
    margin-bottom: 15px;
  }
  @media (min-width: 550px) {
    .watch-auction-info-top > div:not(.info-btns) {
      margin-bottom: 0;
    }
  }
  .watch-auction-info-top .watch-auction-kind {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
  }
  @media (min-width: 550px) {
    .watch-auction-info-top .watch-auction-kind {
      min-width: 115px;
      padding-left: 0;
      border-right: 1px solid #fff;
    }
  }
  .watch-auction-info-top .watch-auction-kind .cattle-icon {
    filter: invert(100%) brightness(200%);
  }
  .watch-auction-info-top .watch-auction-kind .goat-icon {
    width: 85px;
  }
  .watch-auction-info-top .watch-auction-kind .goat-icon.inverted {
    filter: invert(100%) brightness(200%);
  }
  .watch-auction-info-top .watch-auction-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
  }
  .watch-auction-info-top .watch-auction-info h3 {
    color: #ffffff;
    font-family: Lato;
    font-size: 30px;
    font-weight: 400;
    margin: 0 0 12px;
  }
  .watch-auction-info-top .watch-auction-info p {
    color: #efefef;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }
  .watch-auction-info-top .info-btns {
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 0;
  }
  @media (min-width: 1300px) {
    .watch-auction-info-top .info-btns {
      flex-direction: row;
    }
  }
  .watch-auction-info-top .info-btns a {
    text-decoration: none;
  }
  .watch-auction-info-top .info-btns .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    border-color: #000;
  }
  .watch-auction-info-top .info-btns .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .watch-auction-info-top .info-btns .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .watch-auction-info-top .info-btns .btn.filter-btn-md {
    max-width: 166px;
  }
  .watch-auction-info-top .info-btns .btn .icon-wrapper {
    border-color: #000;
  }
  .watch-auction-info-top .info-btns .btn.watch-enter-btn {
    margin-bottom: 10px;
    background-color: #fac917;
    color: #fff;
  }
  @media (min-width: 1300px) {
    .watch-auction-info-top .info-btns .btn.watch-enter-btn {
      margin-bottom: 0;
      margin-right: 7px;
    }
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .not-found {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: stretch;
  }
  .not-found .header-wrap .right, .not-found .footer-wrap .right {
    display: none;
  }
  .not-found .error-content {
    display: flex;
    flex: 1 0;
    align-items: center;
    justify-content: center;
    background-image: url("../src/assets/img/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-size: 50px;
    color: white;
  }
  .not-found .error-content .error-desc {
    margin: 0;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .lot-documents {
    margin: 20px 0;
  }
  .lot-documents .option-value {
    opacity: 0.5;
    cursor: pointer;
  }
  .lot-documents .option-value:hover {
    opacity: 1;
  }
  
  .empty-documents-wrapper {
    padding-left: 10px;
  }
  .empty-documents-wrapper span {
    width: 50%;
    display: inline-block;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .contact-us {
    flex-direction: column;
  }
  .contact-us .map-wrapper h4, .contact-us .map-wrapper p {
    margin: 10px 0;
  }
  .contact-us .inner-container {
    display: flex;
    flex-direction: column;
  }
  .contact-us .inner-container .fg-paragraph {
    margin-top: 0;
  }
  .contact-us .inner-container .header-wrapper {
    height: 90px;
    margin: 39px 0px 20px 49px;
  }
  .contact-us .inner-container .header-wrapper .item-title {
    color: #030404;
    font-family: Lato;
    font-size: 40px;
    font-weight: 400;
    margin: 0 0 20px 0;
    margin: auto;
  }
  @media (max-width: 550px) {
    .contact-us .inner-container .header-wrapper .item-title {
      font-size: 28px;
    }
  }
  .contact-us .inner-container .header-wrapper .item-desc {
    color: #030404;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-top: 25px;
  }
  @media (min-width: 950px) {
    .contact-us .inner-container .locations-wrapper {
      height: 716px;
      padding-top: 50px;
      background-color: #5d5d5d;
    }
    .contact-us .inner-container .locations-wrapper .locations-title {
      font-family: Lato;
      font-size: 40px;
      font-weight: 700;
      color: #ffffff;
      margin: 0 0px 46px 52px;
    }
    .contact-us .inner-container .locations-wrapper .office-info {
      position: relative;
      margin-top: 475px;
      display: flex;
      justify-content: space-around;
      z-index: 1;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info {
      display: flex;
      flex-direction: column;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .main-office-title {
      font-family: Lato;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 10px;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table {
      display: flex;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-titles div {
      font-family: Lato;
      font-weight: 500;
      color: #ffffff;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-titles .office-address {
      margin-bottom: 17px;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-data {
      margin-left: 20px;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-data div {
      font-family: Lato;
      font-weight: 100;
      color: #ffffff;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-data .phone-and-email {
      color: #ffffff;
      text-decoration: none;
      transition: all 0.5s;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-data .phone-and-email:hover {
      color: #aeaeae;
      text-decoration: none;
      transition: all 0.5s;
    }
  }
  @media (max-width: 950px) {
    .contact-us .inner-container .locations-wrapper {
      padding-top: 1px;
      height: 893px;
      background-color: #5d5d5d;
    }
    .contact-us .inner-container .locations-wrapper .locations-title {
      font-family: Lato;
      font-size: 40px;
      font-weight: 700;
      color: #ffffff;
      margin: 15px 0 20px 0;
      text-align: center;
    }
    .contact-us .inner-container .locations-wrapper .office-info {
      position: relative;
      margin-top: 475px;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      z-index: 1;
      align-items: center;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info {
      display: flex;
      flex-direction: column;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .main-office-title {
      font-family: Lato;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 950px) and (max-width: 550px) {
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .main-office-title {
      font-size: 17px;
    }
  }
  @media (max-width: 950px) {
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table {
      display: flex;
    }
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-titles div {
      font-family: Lato;
      font-weight: 500;
      color: #ffffff;
    }
  }
  @media (max-width: 950px) and (max-width: 550px) {
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-titles div {
      font-size: 14px;
    }
  }
  @media (max-width: 950px) {
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-titles .office-address {
      margin-bottom: 17px;
    }
  }
  @media (max-width: 950px) {
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-data {
      margin-left: 20px;
    }
  }
  @media (max-width: 950px) and (max-width: 550px) {
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-data {
      font-size: 14px;
    }
  }
  @media (max-width: 950px) {
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-data div {
      font-family: Lato;
      font-weight: 100;
      color: #ffffff;
    }
  }
  @media (max-width: 950px) {
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-data .phone-and-email {
      color: #ffffff;
      text-decoration: none;
      transition: all 0.5s;
    }
  }
  @media (max-width: 950px) {
    .contact-us .inner-container .locations-wrapper .office-info .main-office-info .office-info-table .office-info-table-data .phone-and-email:hover {
      color: #aeaeae;
      text-decoration: none;
      transition: all 0.5s;
    }
  }
  @media (max-width: 950px) {
    .contact-us .inner-container .locations-wrapper .office-info .regional-office-info {
      margin-top: 255px;
    }
  }
  @media (max-width: 950px) and (max-width: 550px) {
    .contact-us .inner-container .locations-wrapper .office-info .regional-office-info {
      margin-top: 273px;
    }
  }
  .contact-us .social-wrapper {
    z-index: 1;
    position: initial;
  }
  .contact-us .social-wrapper .social-title {
    color: #030404;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    margin: 26px 0px 0px 123px;
  }
  .contact-us .social-wrapper .social-icons {
    display: flex;
    margin: 21px 0px 50px 123px;
  }
  .contact-us .social-wrapper .social-icons .social-facebook {
    background-image: url("../src/assets/img/facebook.png");
    height: 48px;
    width: 48px;
    transition: all 0.5s;
  }
  .contact-us .social-wrapper .social-icons .social-facebook:hover {
    transform: scale(1.05);
    transition: all 0.5s;
  }
  .contact-us .social-wrapper .social-icons .social-linkedin {
    background-image: url("../src/assets/img/icon-linked-in.png");
    height: 48px;
    width: 48px;
    margin-left: 7px;
    transition: all 0.5s;
  }
  .contact-us .social-wrapper .social-icons .social-linkedin:hover {
    transform: scale(1.05);
    transition: all 0.5s;
  }
  .contact-us .info-wrapper {
    display: flex;
    margin-bottom: 25px;
  }
  .contact-us .info-wrapper .address {
    max-width: 200px;
  }
  .contact-us .info-wrapper .details {
    margin-left: 100px;
  }
  .contact-us .info-wrapper h2 {
    font-family: Lato;
    color: #333;
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 20px 0;
  }
  .contact-us .info-wrapper p {
    font-family: Lato;
    font-size: 16px;
    line-height: 19px;
    color: #666;
    margin: 0;
  }
  .contact-us .info-wrapper p span {
    color: #333;
    font-weight: bold;
  }
  .contact-us .info-wrapper p a {
    text-decoration: none;
    color: #aeaeae;
    transition: all 0.5s;
  }
  .contact-us .info-wrapper p a:hover {
    color: #484848;
  }
  .contact-us .map-wrapper {
    width: 100%;
    height: 50vh;
    position: relative;
  }
  @media (max-width: 950px) {
    .contact-us .locations-title {
      font-family: Lato;
      font-size: 35px !important;
      font-weight: 700;
      color: #ffffff;
      margin: 49px 0px 20px 52px;
    }
    .contact-us .header-wrapper {
      margin: 20px 0px 20px 49px !important;
    }
  }
  @media (max-width: 950px) {
    .contact-us .social-title {
      margin: 26px 0px 0px 0px !important;
      text-align: center !important;
    }
    .contact-us .social-icons {
      margin: 10px 0px 30px 0px !important;
      justify-content: center;
      transform: scale(0.8);
    }
  }
  @media (max-width: 730px) {
    .contact-us {
      padding: 5px;
    }
  }
  .contact-us .maps div {
    max-height: 500px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .download-wrapper {
    position: relative;
    width: 231px;
  }
  @media (max-width: 550px) {
    .download-wrapper {
      width: 100%;
    }
  }
  .download-wrapper .btn.download-btn {
    margin: 0 10px 10px 0;
  }
  .download-wrapper .inner-wrapper {
    font-family: Lato;
    position: absolute;
    top: 44px;
    background: white;
    padding: 15px 10px 5px 20px;
    width: 100%;
    border: 1px solid black;
    border-top: none;
    display: none;
    z-index: 1;
  }
  .download-wrapper .inner-wrapper.active {
    display: block;
  }
  .download-wrapper .inner-wrapper .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #030404;
    text-decoration: none;
  }
  .download-wrapper .inner-wrapper .item .icon-wrapper {
    font-size: 20px;
    color: #adb5bd;
  }
  
  .custom-select-wrapper {
    position: relative;
  }
  .custom-select-wrapper .custom-select {
    display: flex;
    height: 44px;
    background-color: #ededed;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    box-shadow: none;
    width: 270px;
    padding-left: 10px;
    cursor: pointer;
    overflow: hidden;
  }
  .custom-select-wrapper .custom-select .custom-select-container {
    display: flex;
    align-items: center;
    width: 214px;
    padding-right: 10px;
  }
  .custom-select-wrapper .custom-select .custom-select-container .custom-select-input {
    background-color: transparent;
    cursor: inherit;
    border: none;
    color: #666666;
    font: 400 14px Lato;
  }
  .custom-select-wrapper .custom-select .custom-select-container .custom-select-value {
    display: flex;
    color: #666666;
    align-items: center;
    font: 400 14px Lato;
  }
  .custom-select-wrapper .custom-select .custom-select-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #c6c6c6;
    flex: 0 0 44px;
    border-left: 1px solid #c6c6c6;
    border-radius: 5px 0 0 5px;
    font-size: 29px;
    color: #5d5d5d;
  }
  .custom-select-wrapper .custom-select-options {
    width: 270px;
    height: 290px;
    position: absolute;
    z-index: 100;
    overflow-y: scroll;
    border-left: 1px solid #c6c6c6;
    border-bottom: 1px solid #c6c6c6;
    border-right: 1px solid #c6c6c6;
  }
  .custom-select-wrapper .custom-select-options .custom-select-option {
    display: flex;
    align-items: center;
    height: 32.5px;
    background-color: #ffffff;
    color: #666666;
    padding-left: 10px;
    font: 400 14px Lato;
    cursor: pointer;
  }
  .custom-select-wrapper .custom-select-options .custom-select-option:hover {
    background-color: #e9e9e9;
  }
  .custom-select-wrapper .custom-select-options .custom-select-option.active {
    background-color: #acabab;
    font-weight: 600;
  }
  .custom-select-wrapper .custom-select-options .custom-select-option.empty {
    display: flex;
    justify-content: center;
    color: #999999;
    cursor: text;
  }
  .custom-select-wrapper .custom-select-options .custom-select-option.empty:hover {
    background-color: #ffffff;
  }
  .custom-select-wrapper .custom-select-options .custom-select-option p {
    margin: 0;
  }
  
  .adjust-dropdown .custom-select {
    width: 180px;
  }
  .adjust-dropdown .custom-select-options {
    width: 180px;
  }
  
  /* this mixin creates a container capable of holding header, content and footer
    make sure to give content flex-grow: 1 */
  /* header at the top that is used in a number of screens
   used to display basic Auction data */
  .filter-kind-component {
    display: flex;
    user-select: none;
  }
  .filter-kind-component .filter-kind-dropdown {
    position: relative;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin: 0;
    padding-left: 10px;
    min-width: 240px;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-green {
    color: #1f9512 !important;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-success {
    background-color: #1f9512 !important;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-warning {
    background-color: #fac917 !important;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-danger {
    color: #dd2d2e !important;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-red {
    background-color: #dd2d2e !important;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 550px) {
    .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn {
      width: 100%;
    }
  }
  @media (min-width: 551px) {
    .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover:not(.btn-opened) {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover:not(.btn-opened) .icon-wrapper {
    background-color: #fac917;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
    transition: 200ms;
    color: #5d5d5d;
  }
  @media (min-width: 550px) and (max-width: 900px) {
    .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
      margin-left: 10px;
    }
  }
  @media (min-width: 550px) {
    .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-sm {
      max-width: 120px;
    }
    .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-md {
      max-width: 155px;
    }
    .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-lg {
      max-width: 230px;
    }
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:not(.btn-opened) {
    border-color: #1f9512;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active .icon-wrapper, .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:hover .icon-wrapper {
    background-color: #1f9512;
    border-color: #1f9512;
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active .icon-wrapper > svg, .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active .icon-wrapper > img, .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:hover .icon-wrapper > svg, .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:hover .icon-wrapper > img {
    filter: invert(100) brightness(200);
  }
  .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 30px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
  }
  .filter-kind-component .dropdown-choices {
    display: none;
    position: absolute;
    min-width: 240px;
    z-index: 100;
  }
  .filter-kind-component .dropdown-choices .dropdown-choice {
    display: flex;
    background-color: white;
    padding: 15px 10px;
    justify-content: space-between;
    border: 1px solid #c6c6c6;
    font-family: Lato;
    font-size: 16px;
    cursor: pointer;
  }
  .filter-kind-component .dropdown-choices .dropdown-choice:hover {
    background-color: #e1e1e1;
  }
  .filter-kind-component .dropdown-choices .dropdown-choice:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .filter-kind-component .dropdown-choices .dropdown-choice:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .filter-kind-component .dropdown-choices .dropdown-choice.selected {
    background-color: #cccccc;
  }
  .filter-kind-component.opened .dropdown-choices {
    display: block;
  }
  
  /*# sourceMappingURL=App.css.map */
  