@import '../../themes/general/variables.scss';

.filter-multi-wrapper,
.filter-range-wrapper {
  @media (min-width: $mobile-width-min) {
    &-sm {
      max-width: 120px;
    }
    &-md {
      max-width: 155px;
    }
    &-lg {
      max-width: 230px;
    }
  }
}

.filter-multi-inner {
  min-width: 100%;
  flex-direction: column !important;

  &.overflow {
    max-width: 500px;
    max-height: 500px;
    overflow: scroll;
    display: block;
    @media screen and(max-width: 1300px) {
      min-width: 900px;
    }
    @media screen and(max-width: 1010px) {
      min-width: 700px;
    }
    @media screen and(max-width: 750px) {
      min-width: 400px;
    }
    @media screen and(max-width: 420px) {
      min-width: 100%;
    }
  }

  @media (min-width: $mobile-width) {
    border-top-left-radius: 0;
  }

  &-right {
    left: auto;
    right: 0;

    @media (min-width: $mobile-width) {
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
    }
  }

  @media (min-width: $large-width) {
    min-width: auto;
  }

  .filter-multi-options {
    display: flex;
    flex-direction: column;

    @media (min-width: $large-width) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .filter-multi-buttons {
    display: flex;
    justify-content: center;
  }

  ul {
    display: flex;
    flex: 1;
    min-width: 200px;
    flex-direction: column;

    @media (min-width: $large-width) {

      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }
}
