@import "./variables.scss";

html {
  height: 100%;
}
body {
  min-height: 100%;
}

.inner-container {
  position: relative;
  background-color: white;
  max-width: 1280px;
  width: 100%;
  min-height: 1020px;
  margin: auto;
  background-color: #ededed;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);

  padding: 40px 50px;
  @media (max-width: $medium-width) {
    padding: 25px 10px;
    max-width: 800px;
  }
  @media (max-width: 900px) {
    padding: 25px 10px;
    max-width: 800px;
  }
  @media (max-width: 800px) {
    padding: 25px 10px;
    max-width: 700px;
  }
  @media (max-width: 700px) {
    padding: 25px 10px;
    max-width: 600px;
  }
  @media (max-width: 600px) {
    padding: 25px 10px;
    max-width: 500px;
  }
  @media (max-width: $mobile-width) {
    padding: 25px 10px;
    max-width: 400px;
  }
  @media (max-width: 400px) {
    max-width: 350px;
  }
  @media (max-width: 350px) {
    max-width: 300px;
  }
  @media (max-width: 300px) {
    max-width: 250px;
  }
}

.body-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 40px 5px;
  background-color: #cccccc;
  clear: both;
  .inner-container {
    position: relative;
    background-color: white;
    max-width: 1280px;
    width: 100%;
    min-height: 1020px;
    margin: auto;
    background-color: #ededed;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);

    padding: 40px 50px;
    @media (max-width: $medium-width) {
      padding: 25px 10px;
    }
  }
  @media (max-width: $medium-width) {
    padding: 20px 0;
  }
}

.no-image {
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: grey;
  width: 100%;
  height: 100%;
  font: normal 16px 'Lato';
  color: #fff;
  &:before {
    content: '';
    background: url("./assets/img/icon_photo.png") no-repeat;
    background-size: cover;
    display: flex;
    width: 53px;
    height: 40px;
    margin-bottom: 10px;
  }
  &.cattle:before {
    background: url("./assets/img/cow.png") no-repeat;
  }
  &.sheep:before {
    background: url("./assets/img/sheep.png") no-repeat;
  }
  &.goat:before {
    background: url("./assets/img/goat-image-middle.png") no-repeat;
  }
}

.separator {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #c6c6c6;
  margin: 0;
}

.app-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  &.area {
    position: absolute;
  }
  background-color: rgba(255,255,255,0.7);
  .app-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.area {
      position: absolute;
    }
  }
}

.remove {
  width: 20px;
  height: 20px;
  background: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  &:after {
    width: 20px;
    height: 20px;
    content: '';
    background: url("./assets/img/closebutton.png") no-repeat;
    background-size: cover;
    display: block;
  }
}

button, label {
  &:focus {
    box-shadow: 0 0 2px 2px #a3a3b1;
  }
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.fg-header {
  @include auction-title-header;
  &.watch-header {
    margin-bottom: 10px;
  }
}
.fg-secondary-header {
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 300;
  color: #545757;
  font-family: 'Lato'
}

.fg-sub-header {
  @include auction-title-sub-header;
}
.register-interest-text {
  @include auction-title-sub-header;
  text-align: center;
  margin-top: 40px;
}
.register-interest-div { 
  display:table;
  margin:0 auto;
}
.register-interest-button {
  font: 400 16px Lato;
  padding: 10px 10px;
  border-radius: 5px;
  color: white;
  background-color: #5D5D5D;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon-wrapper {
    padding-left: 12px;
  }
}
.register-interest-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 380px;
    margin-bottom: 10px;
  }
  
  input {
    width: 350px;
    height: 36px;
  }
  .error {
    color: #ff0000;
  }
  button {
    align-self: center;
  }
  .auth-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 197px;
    height: 45px;
    border-radius: 3px;
    border: 1px solid #1f9512;
    transition: 400ms;
    cursor: pointer;

    &-success {
      background-color: #1f9512;

      &:hover {
        background-color: #2daf1f;
      }

      &:disabled {
        background-color: #666;
      }
    }

    &-alert {
      background-color: #fac917;

      &:hover {
        background-color: #ebb111;
      }
    }

    &.btn-text-centered span {
      padding: 0;
      text-align: center;
    }

    span {
      flex: 1;
      color: #fff;
      text-align: left;
      padding-left: 12px;
      font-family: "Myriad Pro";
      font-size: 18px;
      font-weight: 400;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 43px;
      width: 43px;
      border-radius: 3px;

      &.btn-right {
        border-left: 1px solid #000;
      }

      &.btn-left {
        border-right: 1px solid #000;
      }

      svg {
        font-size: 38px;
        color: #fff;
      }
    }
  }
  .btn-text-centered span {
    padding: 0;
    text-align: center;
  }
}


.fg-paragraph {
  color: #030404;
  font: 400 16px Lato;
}
