@import "../../themes/general/variables.scss";

.download-wrapper {
  position: relative;
  margin-right: 10px;
  width: 231px;
  @media (max-width: $mobile-width) {
    width: 100%;
  }
  .btn.download-btn {
    margin: 0 10px 10px 0;
  }
  .inner-wrapper {
    font-family: Lato;
    position: absolute;
    top: 44px;
    background: white;
    padding: 15px 10px 5px 20px;
    width: 100%;
    border: 1px solid black;
    border-top: none;
    display: none;
    z-index: 1;
    &.active {
      display: block;
    }

    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      color: #030404;
      text-decoration: none;
      .icon-wrapper {
        font-size: 20px;
        color: rgb(173, 181, 189);
      }
    }
  }
}
